import api from "./api"; // Import the Axios instance with interceptor

export default class UserApi {
  // Fetch user information
  static async getUserInfo() {
    try {
      const response = await api.get("/user/detail");
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Login user
  static async login(credentials: { email: string; password: string }) {
    try {
      const response = await api.post("/user/login", credentials);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  // Register email for password reset
  static async registerEmailForResetPassword(credentials: { email: string }) {
    try {
      const response = await api.post(
        "/user/reset-password-email",
        credentials
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Reset password with token
  static async resetPassword(credentials: {
    token: string;
    newPassword: string;
  }) {
    try {
      const response = await api.patch(
        `/user/reset-password/?token=${credentials.token}`,
        {
          newPassword: credentials.newPassword,
        }
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // // Change password
  // static async changePassword(credentials: {
  //   oldPassword: string;
  //   newPassword: string;
  // }) {
  //   try {
  //     const response = await api.patch("/user/change-password", credentials);
  //     return response.data;
  //   } catch (error: any) {
  //     const errorMessage =
  //       error?.response?.data?.error ||
  //       error.message ||
  //       "An unknown error occurred";
  //     throw new Error(errorMessage);
  //   }
  // }
  // Change password with additional fields isActive and backupPassword
  static async changePassword(credentials: {
    oldPassword: string;
    newPassword: string;
  }) {
    try {
      // Prepare the data to send in the API request
      const data = {
        ...credentials,
        isActive: true, // Set isActive to true
        backupPassword: credentials.newPassword, // Store the new password as backupPassword in plain text
      };

      // Make the API request to change the password
      const response = await api.patch("/user/change-password", data);

      // Return the response data
      return response.data;
    } catch (error: any) {
      // Handle errors
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // delete admin users
  static async deleteAdminUser(
    credentials: { isDeleted: boolean },
    id: string
  ) {
    try {
      const response = await api.patch(
        `/user/deleteAdminUser/${id}`,
        credentials
      );
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // update user
  static async updateUserProfile(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/user/updateAdmin/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // update user
  static async checkPassword(credentials: any) {
    console.log("credentials: ", credentials);
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.post(`/user/getPin/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // EMPLOYEE
  static async createUser(credentials: any) {
    try {
      const response = await api.post(`/user/signup`, credentials);
      return response.data;
    } catch (error: any) {
      console.log("error-inside: ", error);
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      return errorMessage;
    }
  }

  static async updateEmployee(credentials: any) {
    console.log("credentials: ", credentials);
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/user/updateEmployee/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getAllEmployees(params: any) {
    params.status = params.status === "all-status" ? "" : params.status;
    params.jobTitleId =
      params.jobTitleId === "all-job" ? "" : params.jobTitleId;
    try {
      const response = await api.get(`/user/allEmployees`, { params });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getEmployeeById(id: any) {
    try {
      const response = await api.get(`/user/getEmpById/${id}`);

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getEmployeeShiftDetail(id: any) {
    try {
      const response = await api.get(`/user/empShift/${id}`);

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateStatus(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/user/updateUserStatus/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateEmployeeShiftDetail(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/user/empShift/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateAvatar(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/user/updateUserAvatar/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createLeave(credentials: any) {
    try {
      const response = await api.post(`/employeeLeave`, credentials);
      return response.data;
    } catch (error: any) {
      console.log("error-inside: ", error);
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      return errorMessage;
    }
  }

  static async getLeaves(params: any) {
    try {
      const response = await api.get(`/employeeLeave/all`, { params });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async sendRespond(credentials: any) {
    const { id, ...data } = credentials;
    const body = { ...data };
    try {
      const response = await api.patch(`/employeeLeave/respond/${id}`, body);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
