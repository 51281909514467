import api from "./api"; // Use the Axios instance with interceptor

export default class AttendanceAPi {
  static async addClockIn(data: any) {
    try {
      const response = await api.post("/attendance/clockIn", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
  static async getAllEmployeeAttendance(params: any) {
    try {
      const response = await api.get("/attendance/empAllAttendance", {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async addClockOut(data: any) {
    try {
      const response = await api.post("/attendance/clockOut", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async checkUserClockIn(params: any) {
    try {
      const response = await api.get("/attendance/checkUserClockIn", {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getEmpMonthlyAttendance(params: any) {
    try {
      const response = await api.get("/attendance", {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
