import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, LI, UL } from "../../../../../AbstractElements";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  formatDateToYYYYMMDD,
  getLeaveFormattedDataInNumbers,
  getUserActivityFormattedData,
} from "../../../../../Utils";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { leaveTypesOption, statusColors } from "../../../../../Utils/Constants";
import FilterComponent from "../../../../../Components/Tables/DataTables/Common/FilterComponent";
import DataTable, { TableColumn } from "react-data-table-component";
import Status from "../../../../../Components/Common/Status";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const Leave = (props: any) => {
  const { id } = useParams();
  const [action, setAction] = useState({
    id: "",
    action: "List",
  }); // * action: list, add, edit

  return (
    <>
      {action.action === "List" && (
        <LeaveList
          setAction={setAction}
          nurseryId={props.nurseryId}
          employeeId={id}
          tabId={props.tabId}
        />
      )}

      {action.action === "Add" && (
        <LeaveForm
          setAction={setAction}
          id={action.id}
          action={action.action}
          nurseryId={props.nurseryId}
          employeeId={id}
        />
      )}

      {action.action === "Edit" && (
        <LeaveForm
          setAction={setAction}
          action={action.action}
          id={action.id}
          nurseryId={props.nurseryId}
          employeeId={id}
        />
      )}
    </>
  );
};

const LeaveList = (props: any) => {
  const [leave, setLeave] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredItems = leave.filter((leave) =>
    leave.employee.firstName.toLowerCase().includes(filterText.toLowerCase())
  );

  // Fetch Leave from the API
  const fetchLeave = async () => {
    try {
      const response = await UserApi.getLeaves({
        nurseryId: props.nurseryId,
        employeeId: props.employeeId,
      });
      if (response.success && Array.isArray(response.data.list)) {
        setLeave(response.data.list);
      } else {
        throw new Error("Failed to fetch Leave");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Leave",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    fetchLeave();
  }, [props.employeeId]);

  const initialState: any = {
    id: "",
    response: "",
    reason: "",
    leaveEmployeesId: "",
  };

  const [form, setForm] = useState<any>(initialState);

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<any>();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      // setSelectedQualificationAndCertification({}); // Clear selected Qualification And Certification data
    } else {
      // If opening modal, reset with selected Qualification And Certification data for edit, otherwise with empty data for add
      reset(initialState);
      setForm(initialState);
    }
  };

  const handleEdit = (row: any) => {
    initialState.id = row.id;
    initialState.leaveEmployeesId = row.leaveEmployeesId;
    reset(initialState);
    setForm(initialState);
    setIsModalOpen(!isModalOpen);
  };

  const STATUS: any = {
    PENDING: "Pending",
    APPROVED: "Approved",
    DECLINED: "Declined",
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Employee Name" />,
      selector: (row) => `${row.employee.firstName} ${row.employee.lastName}`,
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
    },
    {
      name: <CustomHeader title="Leave Type" />,
      selector: (row) => row.leaveType,
      sortable: true,
      style: {
        minWidth: "15%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "15%", // Set consistent width
      maxWidth: "15%",
    },

    {
      name: <CustomHeader title="Applied Leave" />,
      selector: (row) => row.totalDays,
      sortable: true,
      style: {
        minWidth: "16%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "16%", // Set consistent width
      maxWidth: "16%",
    },
    {
      name: <CustomHeader title="Leave Balance" />,
      selector: (row) => row.balanceOfLeave,
      sortable: true,
      style: {
        minWidth: "16%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "16%", // Set consistent width
      maxWidth: "16%",
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => STATUS[row.status],
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
      cell: (row) => {
        return (
          <Status
            position={STATUS[row.status]}
            color={
              row?.status === "PENDING"
                ? {
                    text: statusColors.inactive.text,
                    background: statusColors.inactive.background,
                  }
                : row?.status === "APPROVED"
                ? {
                    text: statusColors.active.text,
                    background: statusColors.active.background,
                  }
                : {
                    text: statusColors.suspended.text,
                    background: statusColors.suspended.background,
                  }
            }
            id={row.id}
            row={row}
            handleStatusChange={() => {}}
          />
        );
      },
    },
    {
      name: <CustomHeader title="Action" />,
      center: true, // Center-aligns content in both header and cells
      cell: (row) =>
        row.status === "PENDING" && (
          <td>
            {/* Edit Button */}
            <a
              className="icon"
              href="javascript:void(0);"
              onClick={() => handleEdit(row)}
            >
              <Btn color="primary" size="sm">
                <i className="fa-solid fa-pencil me-1"></i>
                {"Edit"}
              </Btn>
            </a>
          </td>
        ),
    },
  ];

  // Handle form submission for both create and update
  const onSubmit = async () => {
    try {
      const addedResponse = await UserApi.sendRespond({
        ...form,
      });
      if (addedResponse.success) {
        Swal.fire({
          title: "Success!",
          text: "Employee Leave Status is changed successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Employee Leave Status is changed successfully`
            )
          );
          toggleModal();
          await fetchLeave();
        });
      }
    } catch (error: any) {
      toast.error(error || "Facing some issues.");
    }
  };

  return (
    <Col xl={12}>
      <Col md={12} xl={12}>
        <Card className="card-absolute">
          <CommonCardHeader
            headClass="bg-primary"
            titleClass="text-light"
            title={"Leave Management"}
          />
          <CardBody>
            <Card>
              <div
                className="d-flex justify-content-end align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <Button
                  color="primary"
                  className="btn-md mb-4"
                  onClick={() => props.setAction({ id: "", action: "Add" })}
                >
                  <i className="fa fa-plus me-2" /> Add Leave
                </Button>
              </div>
              <CardBody className="p-0">
                <Row className="justify-content-end">
                  <Col md={5}>
                    <FilterComponent
                      onFilter={(e) => setFilterText(e.target.value)}
                      filterText={filterText}
                    />
                  </Col>
                </Row>
                <div className="table-responsive p-0">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>

        {/* Modal */}
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {form.id ? "Edit Leave Status" : "Add Leave Status"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="">
                <Col md={12} className="position-relative mb-3">
                  <Label>
                    {"Status"} <span className="text-danger">*</span>
                  </Label>
                  <Controller
                    name="response"
                    control={control}
                    rules={{
                      required: "Please select a status",
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="response"
                        className={``}
                        options={[
                          { value: "APPROVED", label: "Approved" },
                          { value: "DECLINED", label: "Declined" },
                        ]} // Status options
                        value={
                          [
                            { value: "APPROVED", label: "Approved" },
                            { value: "DECLINED", label: "Declined" },
                          ].find((option) => option.value === form.response) ||
                          null
                        } // Set current value, handle null
                        onChange={(selectedOption) => {
                          // Check if selectedOption is not null
                          if (selectedOption) {
                            field.onChange(selectedOption.value); // Update the field value
                            saveDataForm("response", selectedOption.value); // Save the data
                          }
                        }} // Handle value change
                      />
                    )}
                  />
                  {errors.response && (
                    <FormFeedback tooltip style={{ display: "block" }}>
                      <>{errors?.response?.message}</>
                    </FormFeedback>
                  )}
                </Col>

                {form.response == "DECLINED" && (
                  <Col md={12} className="position-relative mb-4">
                    <Label>
                      {"Reason"} <span className="text-danger">*</span>
                    </Label>
                    <textarea
                      className="form-control"
                      placeholder="Add reason about decline"
                      value={form.reason || ""}
                      {...register("reason", {
                        required: "Please enter your reason",
                      })}
                      onChange={(e) => saveDataForm("reason", e.target.value)}
                    ></textarea>

                    {errors.reason && (
                      <FormFeedback tooltip style={{ display: "block" }}>
                        <>{errors?.reason?.message}</>
                      </FormFeedback>
                    )}
                  </Col>
                )}
              </Row>

              <ModalFooter>
                <Button color="primary" type="submit">
                  {form.id ? "Submit" : "Save & Update"}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </Col>
    </Col>
  );
};

const LeaveForm = (props: any) => {
  const initialState: any = {
    id: "",
    leaveType: "",
    startDate: "",
    endDate: "",
    balanceOfLeave: "",
    noOfLeave: "",
  };

  const [form, setForm] = useState<any>(initialState);
  const [saveData, setSaveData] = useState<any>({});

  const {
    register,
    control,
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await UserApi.getEmployeeById(props.employeeId);
        if (response.success && Object.keys(response.data).length > 0) {
          setSaveData({
            id: response.data.id,
            leaveData: getLeaveFormattedDataInNumbers(
              response.data?.leaveEmployees?.[0],
              true
            ),
            leaveId: response.data?.leaveEmployees?.[0]?.leave?.id || "",
            leaveTitle: response.data?.leaveEmployees?.[0]?.leave?.title || "",
            leaveStartDate:
              response.data?.leaveEmployees?.[0]?.leave?.startDate ||
              new Date().getTime(),
            leaveEndDate:
              response.data?.leaveEmployees?.[0]?.leave?.endDate ||
              new Date().getTime(),
          });
        } else {
          setSaveData({});
        }
      } catch (error: any) {
        toast.error(error);
      }
    };
    fetchEmployees();
  }, [props.employeeId, props.tabId]);

  const onEditSubmit = async () => {
    const createEmpObj = JSON.parse(JSON.stringify(form));

    try {
      const addedResponse = await UserApi.createLeave({
        ...createEmpObj,
        employeeId: props.employeeId,
        nurseryId: props.nurseryId,
        leaveEmployeesId: saveData?.leaveData?.id,
        startDate: formatDateToYYYYMMDD(form.startDate),
        endDate: formatDateToYYYYMMDD(form.endDate),
        balanceOfLeave: `${
          Number(form.balanceOfLeave) - Number(form.noOfLeave)
        }`,
      });
      if (addedResponse.success) {
        Swal.fire({
          title: "Success!",
          text: "Employee Leave submitted successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Employee Leave submitted successfully`
            )
          );

          props.setAction({ id: "", action: "List" });
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  // Function to calculate the number of leave days
  const calculateLeaveDays = (startDate: any, endDate: any) => {
    if (!startDate || !endDate) return 0;

    // Convert both dates to Date objects
    const start: any = new Date(startDate);
    const end: any = new Date(endDate);

    // Calculate the difference in time
    const timeDifference = end - start;

    // Calculate the number of days
    const numberOfDays = timeDifference / (1000 * 3600 * 24) + 1; // Add 1 because the end date is inclusive

    return numberOfDays;
  };

  useEffect(() => {
    let leave = saveData?.leaveData?.[form?.leaveType] || 0;
    let leaveBalance = saveData?.leaveData?.[form?.leaveType + "Balance"] || 0;

    let balanceOfLeave: string = `${Number(leave) - Number(leaveBalance)}`;
    setValue("balanceOfLeave", balanceOfLeave);
    saveDataForm("balanceOfLeave", balanceOfLeave);
  }, [form.leaveType]);

  useEffect(() => {
    if (form.balanceOfLeave === "0") {
      setValue("endDate", "");
      saveDataForm("endDate", "");
    }
  }, [form.balanceOfLeave]);

  useEffect(() => {
    const startDate = watch("startDate"); // Start date selected by the user
    const endDate = watch("endDate"); // End date selected by the user

    const leaveDays = calculateLeaveDays(startDate, endDate);

    setValue("noOfLeave", leaveDays);
    saveDataForm("noOfLeave", leaveDays);
  }, [form.endDate]);

  return (
    <Col xl={12}>
      <Form onSubmit={handleSubmit(onEditSubmit)}>
        <Col md={12} xl={12}>
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Leave Management"}
            />
            <CardBody>
              <Row className="mt-3">
                {/* Job Date */}
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"LeaveType"} <span className="text-danger">*</span>
                    </Label>
                    <Controller
                      name="leaveType"
                      control={control}
                      rules={{
                        required: true,
                      }} // Validation rules
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="leaveType"
                          options={leaveTypesOption} // LeaveType options
                          value={
                            leaveTypesOption.find(
                              (option) => option.value === form.leaveType
                            ) || null
                          } // Set current value, handle null
                          onChange={(selectedOption) => {
                            // Check if selectedOption is not null
                            if (selectedOption) {
                              field.onChange(selectedOption.value); // Update the field value
                              saveDataForm("leaveType", selectedOption.value); // Save the data
                            }
                          }} // Handle value change
                        />
                      )}
                    />

                    {errors.leaveType && (
                      <span style={{ color: "red" }}>
                        {"Please select a leave type"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label for="balanceOfLeave">Balance of Leave</Label>
                    <Input
                      type="text"
                      id="balanceOfLeave"
                      {...register("balanceOfLeave")}
                      value={form.balanceOfLeave}
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label for="noOfLeave">No of Leave</Label>
                    <Input
                      type="text"
                      id="noOfLeave"
                      {...register("noOfLeave")}
                      value={form.noOfLeave}
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"Start Date"} <span className="text-danger">*</span>
                    </Label>
                    <Controller
                      control={control}
                      name="startDate"
                      rules={{
                        required: true,
                      }} // Validation rules
                      render={({ field }) => (
                        <DatePicker
                          className="form-control"
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            saveDataForm("startDate", date);
                            setValue("endDate", "");
                          }}
                          placeholderText="Select Start Date"
                          dateFormat="MMMM dd, yyyy"
                          minDate={new Date(saveData?.leaveStartDate || "")} // 2nd October 2024
                          maxDate={new Date(saveData?.leaveEndDate || "")} // 31st October 2024
                          startDate={new Date(saveData?.leaveStartDate || "")} // 2nd October 2024
                          endDate={new Date(saveData?.leaveEndDate || "")} // 31st October 2024
                        />
                      )}
                    />

                    {errors.startDate && (
                      <span style={{ color: "red" }}>
                        {"Please select a start date"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>
                      {"End Date"} <span className="text-danger">*</span>
                    </Label>
                    <Controller
                      control={control}
                      name="endDate"
                      rules={{
                        required: true,
                      }} // Validation rules
                      render={({ field }) => {
                        const balanceOfLeave = watch("balanceOfLeave");
                        const startDate = watch("startDate");
                        const maxEndDate =
                          balanceOfLeave === "1"
                            ? startDate // If balanceOfLeave is 1, restrict to startDate
                            : balanceOfLeave
                            ? new Date(
                                new Date(startDate).setDate(
                                  new Date(startDate).getDate() +
                                    parseInt(balanceOfLeave, 10) -
                                    1
                                )
                              )
                            : new Date(saveData?.leaveEndDate);

                        return (
                          <DatePicker
                            className="form-control"
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("endDate", date);
                            }}
                            placeholderText="Select End Date"
                            dateFormat="MMMM dd,yyyy"
                            minDate={startDate} // Minimum selectable date is the start date
                            maxDate={maxEndDate} // Restrict to startDate if balanceOfLeave is 1
                            startDate={startDate} // Highlight the start date
                            endDate={maxEndDate} // Highlight the calculated max end date
                            disabled={!balanceOfLeave || balanceOfLeave === "0"} // Disable the DatePicker if balanceOfLeave is 0
                          />
                        );
                      }}
                    />

                    {errors.endDate && (
                      <span style={{ color: "red" }}>
                        {"Please select a end date"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Btn color="primary" type="submit">
                {props.id == "" ? `Save & Submit` : "Update & Save"}
              </Btn>
              <Btn
                color="dark"
                type="button"
                style={{ marginLeft: "1rem" }}
                onClick={() => props.setAction({ id: "", action: "List" })}
              >
                {"Back"}
              </Btn>
            </CardFooter>
          </Card>
        </Col>
      </Form>
    </Col>
  );
};

export default Leave;
