import { useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { manageRoutes } from "./Helper";
import AttendanceAPi from "../services/AttendanceApi";
import { current } from "@reduxjs/toolkit";
import { formatDateToYYYYMMDD } from "../Utils";

const CustomRoutes = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search, hash } = location;
  const [ip, setIP] = useState("");

  useEffect(() => {
    // Replace the URL below with any public IP API
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIP(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);

  const checkAttendance = async () => {
    try {
      const response: any = await AttendanceAPi.checkUserClockIn({
        nurseryId: props.auth.selectedNursery,
        employeeId: props.auth?.user?.id,
        currentDate: formatDateToYYYYMMDD(new Date()),
      });

      props.setClockIn({
        clockIn: response.success,
        clockInDate: formatDateToYYYYMMDD(new Date()),
        attendanceId: response?.attendanceId,
      });
    } catch (error) {}
  };

  useEffect(() => {
    // Check user clock in
    checkAttendance();
  });

  useEffect(() => {
    props.setUserIP(ip);
  }, [ip]);

  return <MyRoutes auth={props.auth} />;
};

const MyRoutes = (props: any) => {
  return useRoutes([...manageRoutes(props.auth)]);
};

export default CustomRoutes;
