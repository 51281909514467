import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, H4, LI, UL } from "../../../../../AbstractElements";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { countryCode } from "../../../../../Utils/CountryCode";
import { useNavigate, useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  calculateJoinDateToCurrentDateDuration,
  commonKeysObject,
  getUserActivityFormattedData,
} from "../../../../../Utils";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import Swal from "sweetalert2";
import CommonTouchspin from "../../../../../Components/Forms/FormWidgets/Touchspin/Common/CommonTouchspin";
import { defaultTouchspinData } from "../../../../../Data/Forms/FormWidgets";
import DataTable, { TableColumn } from "react-data-table-component";
import FilterComponent from "../../../../../Components/Tables/DataTables/Common/FilterComponent";
import { baseUrl } from "../../../../../Utils/Constants/config";
import { toast } from "react-toastify";
import UploadAPi from "../../../../../services/UploadApi";
import DocumentAPi from "../../../../../services/DocumentApi";
import moment from "moment";
interface QualificationAndCertification {
  id: string;
  name: string;
  certificateStatus: string;
  joiningDate?: string;
  completedDate?: string;
  reason?: string;
  mediaUrl: string;
  mediaType: string;
  employeeId: string;
  nurseryId: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const QualificationAndCertification = (props: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [saveData, setSaveData] = useState([]);

  const initialState: any = {
    id: "",
    name: "",
    certificateStatus: "",
    joiningDate: new Date(),
    completedDate: new Date(),
    reason: "",
    mediaUrl: "",
    mediaType: "",
    employeeId: id,
    nurseryId: props.nurseryId,
  };

  const [form, setForm] = useState<any>(initialState);

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<QualificationAndCertification>();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      // setSelectedQualificationAndCertification({}); // Clear selected Qualification And Certification data
    } else {
      // If opening modal, reset with selected Qualification And Certification data for edit, otherwise with empty data for add
      reset(initialState);
      setForm(initialState);
    }
  };

  const fetchCertificates = async () => {
    try {
      const response = await DocumentAPi.getAllCertificates({
        employeeId: id,
      });
      if (response.success && response.data.list.length > 0) {
        setSaveData(response.data.list);
      } else {
        setSaveData([]);
      }
    } catch (error: any) {
      toast.error("error: ", error);
    }
  };

  // Handle form submission for both create and update
  const onSubmit = async () => {
    try {
      if (form.id == "") {
        if (form?.files && form?.files?.length > 0) {
          const { mediaUrl, mediaType } = await uploadImage(form?.files[0]);
          const createObj = JSON.parse(JSON.stringify(form));
          createObj.mediaUrl = mediaUrl;
          createObj.mediaType = mediaType;
          delete createObj.files;
          delete createObj.id;
          const addedResponse = await DocumentAPi.addCertificate({
            ...createObj,
            joiningDate: form.joiningDate.getTime(),
            completedDate: form.completedDate.getTime(),
          });

          if (addedResponse.success) {
            Swal.fire({
              title: "Success!",
              text: "Certificate added successfully",
              icon: "success",
            }).then(async () => {
              await UserActivityAPi.addUserActivity(
                getUserActivityFormattedData(
                  "Employees",
                  `Certificate added successfully`
                )
              );

              toggleModal();

              await fetchCertificates();
            });
          }
        } else {
          toast.error("Please upload at least one file ");
        }
      } else {
        if (form?.files && form?.files?.length > 0) {
          const { mediaUrl, mediaType } = await uploadImage(form?.files[0]);
          const createObj = JSON.parse(JSON.stringify(form));
          createObj.mediaUrl = mediaUrl;
          createObj.mediaType = mediaType;
          delete createObj.files;
          const addedResponse = await DocumentAPi.updateCertificate({
            ...createObj,
            joiningDate: form.joiningDate.getTime(),
            completedDate: form.completedDate.getTime(),
          });

          if (addedResponse.success) {
            Swal.fire({
              title: "Success!",
              text: "Certificate updated successfully",
              icon: "success",
            }).then(async () => {
              await UserActivityAPi.addUserActivity(
                getUserActivityFormattedData(
                  "Employees",
                  `Certificate updated successfully`
                )
              );

              toggleModal();

              await fetchCertificates();
            });
          }
        } else {
          const createObj = JSON.parse(JSON.stringify(form));
          delete createObj.files;
          const addedResponse = await DocumentAPi.updateCertificate({
            ...createObj,
            joiningDate: form.joiningDate.getTime(),
            completedDate: form.completedDate.getTime(),
          });

          if (addedResponse.success) {
            Swal.fire({
              title: "Success!",
              text: "Certificate updated successfully",
              icon: "success",
            }).then(async () => {
              await UserActivityAPi.addUserActivity(
                getUserActivityFormattedData(
                  "Employees",
                  `Certificate updated successfully`
                )
              );

              toggleModal();
              await fetchCertificates();
            });
          }
        }
      }
    } catch (error: any) {
      toast.error(error || "Facing some issues.");
    }
  };

  useEffect(() => {
    fetchCertificates();
  }, [id, props.tabId]);

  // Filter the custom status list based on title
  const filteredItems = saveData.filter((row: any) =>
    row.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const customStyles = {
    table: {
      style: {
        tableLayout: "fixed", // Fixes the table layout to enforce column widths
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f4f4f4",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        width: "150px", // Adjust width for all cells
        whiteSpace: "nowrap", // Prevent text wrapping
        overflow: "hidden",
        textOverflow: "ellipsis", // Show ellipsis for overflow
      },
    },
  };

  // Columns for the DataTable
  const columns: TableColumn<QualificationAndCertification>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => row.certificateStatus,
      sortable: true,
    },
    {
      name: <CustomHeader title="Joining Date" />,
      selector: (row: any) =>
        row.certificateStatus == "running"
          ? moment(row.joiningDate).format("DD MMM YYYY")
          : "N/A",
      sortable: true,
    },
    {
      name: <CustomHeader title="Completed Date" />,
      selector: (row: any) =>
        row.certificateStatus == "completed"
          ? moment(row.completedDate).format("DD MMM YYYY")
          : "N/A",
      sortable: true,
    },

    // {
    //   name: <CustomHeader title="Icon" />,
    //   cell: (row: any) => (
    //     <div>
    //       {row.mediaUrl ? (
    //         <a
    //           href={baseUrl + row.mediaUrl}
    //           target="_blank"
    //           rel="noopener noreferrer"
    //         >
    //           View Media
    //         </a>
    //       ) : (
    //         "No icon"
    //       )}
    //     </div>
    //   ),
    //   sortable: false,
    // },
    {
      name: <CustomHeader title="Action" />, 
      cell: (row) => (
        <td 
      style={{ width: "210px", minWidth: "160px", maxWidth:"210px" }}>
          {/* Edit Button */}
          <a
            className="icon"
            href="javascript:void(0);"
            onClick={() => handleEdit(row)}
          >
            <Btn color="primary" size="sm">
              <i className="fa-solid fa-pencil me-1"></i>
              {"Edit"}
            </Btn>
          </a>

          {/* View Button with Media Link */}
          {row.mediaUrl ? (
            <a
              className="icon"
              href={baseUrl + row.mediaUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Btn color="transparent" size="sm">
                <i className="fa-solid fa-link me-1"></i>
                {"Attachment"}
              </Btn>
            </a>
          ) : (
            <span>No icon</span>
          )}
        </td>
      ),
    },
    // {
    //   name: <CustomHeader title="Action" />,
    //   cell: (row) => (
    //     <td className="text-end">
    //       <a
    //         className="icon"
    //         href={`/dashboard/users/employees/edit/${row.id}`}
    //       >
    //         <Btn color="primary" size="sm">
    //           <i className="fa-solid fa-pencil me-1"></i>
    //           {"Edit"}
    //         </Btn>
    //       </a>
    //       <a
    //         className="icon"
    //         href={`/dashboard/users/employees/view/${row.id}`}
    //       >
    //         <Btn color="transparent" size="sm">
    //           <i className="fa-solid fa-eye me-1"></i>
    //           {"View"}
    //         </Btn>
    //       </a>
    //     </td>
    //   ),
    // },
  ];

  const uploadImage = async (file: any) => {
    const formData: any = new FormData();
    formData.append("file", file);
    formData.append("folder", "document");
    try {
      const response = await UploadAPi.uploadFile(formData, "upload-document");

      return { mediaUrl: response.filePath, mediaType: "IMAGE" };
    } catch (err) {
      return { mediaUrl: "", mediaType: "" };
    }
  };

  const handleEdit = (data: any) => {
    const updated: any = {
      id: data.id,
      name: data.name,
      certificateStatus: data.certificateStatus,
      joiningDate: new Date(data.joiningDate),
      completedDate: new Date(data.completedDate),
      reason: data.reason,
      mediaUrl: data.mediaUrl,
      mediaType: data.mediaType,
      employeeId: id,
      nurseryId: props.nurseryId,
    };

    reset(updated);
    setForm(updated);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Col xl={12}>
      <Row className="pt-0">
        <Col md={12} xl={12}>
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              title="Qualification & Certification"
              titleClass="text-light"
            />
            <CardBody>
              <Card>
                <div
                  className="d-flex justify-content-end align-items-center"
                  style={{ borderBottom: "5px solid #eee" }}
                >
                  <Button
                    color="primary"
                    className="btn-md mb-4"
                    onClick={toggleModal}
                  >
                    <i className="fa fa-plus me-2" /> Add Certificate
                  </Button>
                </div>
                <CardBody className="p-0">
                  <Row className="justify-content-end">
                    <Col md={5}>
                      <FilterComponent
                        onFilter={(e) => setFilterText(e.target.value)}
                        filterText={filterText}
                      />
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <DataTable
                      data={filteredItems}
                      columns={columns}
                      pagination
                      className="display"
                      conditionalRowStyles={[
                        {
                          when: (row) => true,
                          style: {
                            "&:hover": {
                              backgroundColor: "rgba(48, 142, 135, 0.2)",
                              cursor: "pointer",
                            },
                          },
                        },
                      ]}
                    />
                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Modal */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {form.id ? "Edit Certificate" : "Add Certificate"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <Col md={12} className="position-relative">
                <Label>
                  {`Name Of qualification`}{" "}
                  <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter qualification name"
                  {...register("name", {
                    required: "Please enter qualification name",
                  })}
                  value={form.name}
                  onChange={(e) => saveDataForm("name", e.target.value)}
                />
                {errors.name && (
                  <FormFeedback tooltip style={{ display: "block" }}>
                    {errors.name.message}
                  </FormFeedback>
                )}
              </Col>

              <Col md={12} className="position-relative mt-3">
                <Label>
                  {"Status"} <span className="text-danger">*</span>
                </Label>
                <Controller
                  name="certificateStatus"
                  control={control}
                  rules={{
                    required: "Please select a status",
                  }} // Validation rules
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="certificateStatus"
                      className={``}
                      options={[
                        { value: "running", label: "Running" },
                        { value: "completed", label: "Completed" },
                        { value: "failed", label: "Failed" },
                      ]} // Status options
                      value={
                        [
                          { value: "running", label: "Running" },
                          { value: "completed", label: "Completed" },
                          { value: "failed", label: "Failed" },
                        ].find(
                          (option) => option.value === form.certificateStatus
                        ) || null
                      } // Set current value, handle null
                      onChange={(selectedOption) => {
                        // Check if selectedOption is not null
                        if (selectedOption) {
                          field.onChange(selectedOption.value); // Update the field value
                          saveDataForm(
                            "certificateStatus",
                            selectedOption.value
                          ); // Save the data
                        }
                      }} // Handle value change
                    />
                  )}
                />
                {errors.certificateStatus && (
                  <FormFeedback tooltip style={{ display: "block" }}>
                    <>{errors?.certificateStatus?.message}</>
                  </FormFeedback>
                )}
              </Col>

              {form.certificateStatus == "running" && (
                <Col md={12} className="position-relative mt-3">
                  <Label>{"Joining Date"}</Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="joiningDate"
                      rules={{
                        required: true,
                      }}
                      render={({ field }: any) => {
                        return (
                          <DatePicker
                            className={`form-control flatpickr-input`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("joiningDate", date);
                            }}
                            placeholderText="Select a date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />

                    {errors.joiningDate && (
                      <FormFeedback tooltip style={{ display: "block" }}>
                        <>{"Please select joining date."}</>
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>
              )}

              {form.certificateStatus == "completed" && (
                <Col md={12} className="position-relative mt-3">
                  <Label>{"Completed Date"}</Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="completedDate"
                      rules={{
                        required: true,
                      }}
                      render={({ field }: any) => {
                        return (
                          <DatePicker
                            className={`form-control flatpickr-input`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("completedDate", date);
                            }}
                            placeholderText="Select a date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />

                    {errors.completedDate && (
                      <FormFeedback tooltip style={{ display: "block" }}>
                        <>{"Please select completed date."}</>
                      </FormFeedback>
                    )}
                  </InputGroup>
                </Col>
              )}

              {form.certificateStatus == "failed" && (
                <Col md={12} className="position-relative mt-3">
                  <Label>{"Notes (Optional)"}</Label>
                  <textarea
                    className="form-control"
                    placeholder="Add notes about failure"
                    value={form.reason || ""}
                    onChange={(e) => saveDataForm("reason", e.target.value)}
                  ></textarea>
                </Col>
              )}

              <Col md={12} className="position-relative mt-3 mb-5">
                <Label>
                  {"Upload Certificate Images"}{" "}
                  <span className="text-danger">*</span>
                </Label>
                <div style={{ display: "flex" }}>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    {...(register("mediaUrl"),
                    { required: form.id === "" ? true : false })}
                    onChange={(e) => {
                      saveDataForm("files", e.target.files);
                    }}
                  />
                  {errors.mediaUrl && (
                    <FormFeedback tooltip>
                      {"Please upload a file"}
                    </FormFeedback>
                  )}
                </div>
                {form.mediaUrl && (
                  <a
                    href={baseUrl + form.mediaUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-solid fa-eye mt-3 me-1"></i>
                    View Media
                  </a>
                )}
              </Col>
            </Row>

            <ModalFooter>
              <Button color="primary" type="submit">
                {form.id ? "Update & Save" : "Save & Update"}
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </Col>
  );
};

export default QualificationAndCertification;
