import { combineReducers, configureStore } from "@reduxjs/toolkit";
import LayoutReducer from "./Reducers/Layout/LayoutReducer";
import ThemeCustomizerReducer from "./Reducers/Layout/ThemeCustomizerReducer";

import AuthReducer from "./Slices/Auth/AuthReducer";
import { FcmApiSlice } from "./Slices/FCM/FcmApiSlice";
import { PermissionApiSlice } from "./Slices/Permission/PermissionApiSlice";
import PermissionReducer from "./Slices/Permission/PermissionReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage
import AdminReducer from "./Slices/Admin/AdminReducer";
import { AdminUserApiSlice } from "./Slices/Admin/AdminApiSlice";
import { AdminUserListener } from "./Slices/Admin/AdminListener";
import PackageReducer from "./Slices/Package/PackageReducer";
import { PackagePlanApiSlice } from "./Slices/Package/PackageApiSlice";
import { PackagePlansListener } from "./Slices/Package/PackageListener";
import EyfsReducer from "./Slices/Eyfs/EyfsReducer";
import { EyfsApiSlice } from "./Slices/Eyfs/EyfsApiSlice";
import { EyfsListener } from "./Slices/Eyfs/EyfsListener";
import ReportReducer from "./Slices/Report/ReportReducer";
import { ReportsApiSlice } from "./Slices/Report/ReportApiSlice";
import { ReportListener } from "./Slices/Report/ReportListener";
import UserReducer from "./Slices/User/UserReducer";
import { userApi } from "./Slices/User/UserApiSlice";

const persistConfig = {
  key: "root",
  storage,
  // Optionally, you can use a blacklist or whitelist to choose which reducers to persist
  // blacklist: ['api'], // This would exclude the API slice from being persisted
  // whitelist: ['user'], // This would only persist the user slice
};

const rootReducer = combineReducers({
  // * API Reducers
  [FcmApiSlice.reducerPath]: FcmApiSlice.reducer,
  [PermissionApiSlice.reducerPath]: PermissionApiSlice.reducer,
  [AdminUserApiSlice.reducerPath]: AdminUserApiSlice.reducer,
  [PackagePlanApiSlice.reducerPath]: PackagePlanApiSlice.reducer,
  [EyfsApiSlice.reducerPath]: EyfsApiSlice.reducer,
  [ReportsApiSlice.reducerPath]: ReportsApiSlice.reducer,
  [userApi.reducerPath]: userApi.reducer,

  layout: LayoutReducer,
  themeCustomizer: ThemeCustomizerReducer,
  auth: AuthReducer,
  permission: PermissionReducer,
  adminUser: AdminReducer,
  plans: PackageReducer,
  eyfs: EyfsReducer,
  reports: ReportReducer,
  user: UserReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["register"], // Ignore 'register' action for serializability
      },
    })
      .concat(AdminUserListener.middleware)
      .concat(PackagePlansListener.middleware)
      .concat(EyfsListener.middleware)
      .concat(ReportListener.middleware)

      .concat(FcmApiSlice.middleware)
      .concat(AdminUserApiSlice.middleware)
      .concat(PackagePlanApiSlice.middleware)
      .concat(EyfsApiSlice.middleware)
      .concat(userApi.middleware)
      .concat(ReportsApiSlice.middleware)
      .concat(PermissionApiSlice.middleware),
});

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
