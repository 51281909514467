import React, { useState } from "react";
import { LI, UL } from "../../../../AbstractElements";
import LanguageSelect from "./LanguageSelect";
import MoonLight from "./MoonLight";
import CartHeader from "./CartHeader";
import Notification from "./Notification";
import FullScreen from "./FullScreen";
import CloudDesign from "./CloudDesign";
import { Href } from "../../../../Utils/Constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../../../ReduxToolkit/Store";
import { setIsSearchBarOpen } from "../../../../ReduxToolkit/Reducers/Layout/LayoutReducer";
import UserWrap from "./UserWrap";
import SvgIcon from "../../../../Utils/CommonComponents/CommonSvgIcons";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  convertTimeToDateTime,
  formatDateToYYYYMMDD,
  getUserActivityFormattedData,
} from "../../../../Utils";
import { toast } from "react-toastify";
import AttendanceAPi from "../../../../services/AttendanceApi";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";

export default function HeaderRight(props: any) {
  const dispatch = useDispatch();
  const { isSearchBarOpen } = useSelector((state: RootState) => state.layout);
  const handleSearch = () => {
    dispatch(setIsSearchBarOpen(!isSearchBarOpen));
  };
  const [modal, setModal] = useState(false);
  const [time, setTime] = useState(""); // Time selection state

  const toggle = () => setModal(!modal);

  const handleEmployeeAttendanceClockOut = async () => {
    if (time === "") return toast.error("Please select time");
    try {
      const response: any = await AttendanceAPi.addClockOut({
        nurseryId: props.selectedNursery,
        employeesId: [
          { empId: props?.user?.id, attendanceId: props.attendanceId },
        ],
        clockOutTime: convertTimeToDateTime(new Date(), time),
        attendanceDate: formatDateToYYYYMMDD(new Date()),
      });

      console.log("response: ", response);
      if (response?.success) {
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          // Add user activity log
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Attendance", `${response.message}`)
          );
          setTime("");
          toggle();
        });
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className="nav-right">
      <UL className="header-right simple-list flex-row">
        {/* <LanguageSelect /> */}
        {/* <LI className="search d-lg-none d-flex">
          <a href={Href} onClick={handleSearch}>
            <SvgIcon iconId="Search" />
          </a>
        </LI> */}
        {/* <MoonLight /> */}
        {/* <CartHeader /> */}
        <div>
          <Button color="primary" onClick={toggle}>
            Clock Out
          </Button>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>
              <div className="d-flex flex-column gap-1 justify-content-center">
                <h2 className="h2">Clock Out </h2>
                <h6 className="h6">Date: {formatDateToYYYYMMDD(new Date())}</h6>
              </div>
            </ModalHeader>
            <ModalBody>
              <Input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type="button"
                onClick={handleEmployeeAttendanceClockOut}
              >
                Submit
              </Button>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <Notification />
        {/* <FullScreen /> */}
        {/* <CloudDesign /> */}
        <UserWrap
          userName={`${props.user.firstName} ${props.user.lastName}`}
          logout={props.logout}
          user={props.user}
          setNursery={props.setNursery}
          selectedNursery={props.selectedNursery}
        />
      </UL>
    </div>
  );
}
