import { Col, Container, Row } from "reactstrap";
import { dynamicImage } from "../Utils";
import { H1, H2, H6, Image } from "../AbstractElements";
import NewSignInForm from "./NewSignInForm";

export function SignIn(props: any) {
  return (
    <Container fluid>
      <Row>
        <Col xl={12}>
          <div style={{ background: "#FFF" }}>
            <NewSignInForm
              setUserData={props.setUserData}
              setNursery={props.setNursery}
              success={props.success}
              error={props.error}
              isLoadingUser={props.isLoadingUser}
              setSuccess={props.setSuccess}
              setError={props.setError}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
