import React from "react";
import { Col, TabContent, TabPane } from "reactstrap";
import General from "./General";
import Job from "./Job";
import Payroll from "./Payroll";
import Document from "./Document";
import Shift from "./Shift";
import QualificationAndCertification from "./QualificationAndCertification";
import Leave from "./Leave";

const Details = (props: any) => {
  return (
    <Col xxl={9} xl={9} md={12} className="box-col-12">
      <div className="email-right-aside bookmark-tabcontent">
        <div className="ps-0">
          <TabContent activeTab={props.activeTab}>
            <TabPane key={1} tabId={1}>
              <General tabId={props.activeTab} />
            </TabPane>

            <TabPane key={2} tabId={2}>
              <Job tabId={props.activeTab} />
              {/* <Payroll /> */}
            </TabPane>

            <TabPane key={3} tabId={3}>
              <QualificationAndCertification
                nurseryId={props.nurseryId}
                tabId={props.activeTab}
              />
            </TabPane>

            <TabPane key={4} tabId={4}>
              <Document tabId={props.activeTab} />
            </TabPane>

            <TabPane key={5} tabId={5}>
              <Leave nurseryId={props.nurseryId} tabId={props.activeTab} />
            </TabPane>

            <TabPane key={6} tabId={6}>
              <Shift tabId={props.activeTab} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Col>
  );
};

export default Details;
