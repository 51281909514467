import React from "react";
import { connect } from "react-redux";
import { SignIn } from ".";
import {
  SET_ERROR,
  SET_NURSERY,
  SET_SUCCESS,
  SET_USER_DATA,
} from "../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  return {
    error: state.auth.error,
    success: state.auth.success,
    isLoadingUser: state.auth.isLoadingUser,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  setSuccess: (value: string) => dispatch(SET_SUCCESS(value)),
  setError: (value: string) => dispatch(SET_ERROR(value)),
  setNursery: (value: string) => dispatch(SET_NURSERY(value)),
  setUserData: (value: string) => dispatch(SET_USER_DATA(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
