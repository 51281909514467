import api from "./api"; // Use the Axios instance with interceptor

export default class NurseryAPi {
  static async addUpdateGeneralInformation(data: any) {
    try {
      const response = await api.post("/nursery/create-update-general", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
  static async getGeneralInformation(params: any) {
    try {
      const response = await api.get("/nursery/get-general", {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async addRolesPermissions(data: any) {
    try {
      const response = await api.post("/nursery/add-roles-permissions", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
  static async getRolesPermissions(params: any) {
    try {
      const response = await api.get("/nursery/get-roles-permissions", {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async getLeave(params: any) {
    try {
      const response = await api.get("/nursery/get-leave", {
        params,
      });

      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }

  static async addUpdateLeave(data: any) {
    try {
      const response = await api.post("/nursery/add-update-leave", data); // Changed to /faq/
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(JSON.stringify(errorMessage));
    }
  }
}
