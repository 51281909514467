import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Information = (props: any) => {
  const navigate = useNavigate();
  return (
    <Row className="pt-0">
      <Col sm={12}>
        <Card>
          <CardBody>
            <Row className="g-3">
              {/* Name Field */}
              <Col md={12}>
                <Label for="name">
                  Name <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter Name"
                  value={props.form.name}
                  onChange={(e) => props.saveDataForm("name", e.target.value)}
                />
                {props.error.name && (
                  <span style={{ color: "red" }}>{"Name is required"} </span>
                )}
              </Col>
              <Col md={12}>
                <Label for="description">
                  Description <span className="text-danger">*</span>
                </Label>
                <textarea
                  className={`form-control`}
                  placeholder="Enter Description"
                  value={props.form.description}
                  onChange={(e) =>
                    props.saveDataForm("description", e.target.value)
                  }
                />
                {props.error.description && (
                  <span style={{ color: "red" }}>
                    {"Description is required"}{" "}
                  </span>
                )}
              </Col>
              <Col md={12}>
                <Label for="address">
                  Address <span className="text-danger">*</span>
                </Label>
                <textarea
                  className={`form-control`}
                  placeholder="Enter Address"
                  value={props.form.address}
                  onChange={(e) =>
                    props.saveDataForm("address", e.target.value)
                  }
                />
                {props.error.address && (
                  <span style={{ color: "red" }}>{"Address is required"} </span>
                )}
              </Col>
              <Col md={12} className="position-relative mt-3">
                <Label for="phone">
                  Phone <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter your phone number"
                  value={props.form.phoneNo}
                  onInput={(e: any) => {
                    const input = e.target.value;
                    return (e.target.value = input
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*?)\..*/g, "$1"));
                  }}
                  onChange={(e) =>
                    props.saveDataForm("phoneNo", e.target.value)
                  }
                />
                {props.error.phoneNo && (
                  <span style={{ color: "red" }}>{"PhoneNo is required"} </span>
                )}
              </Col>
              <Col md={12} className="position-relative">
                <Label for="email">
                  Email <span className="text-danger">*</span>
                </Label>
                <input
                  type="email"
                  className={`form-control`}
                  placeholder="Enter your email"
                  value={props.form.email}
                  onChange={(e) => props.saveDataForm("email", e.target.value)}
                />
                {props.error.email && (
                  <span style={{ color: "red" }}>{"Email is required"} </span>
                )}
              </Col>
              <Col md={12}>
                <Label for="contactPersonName">Contact Person Name</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter Contact Person Name"
                  value={props.form.contactPersonName}
                  onChange={(e) =>
                    props.saveDataForm("contactPersonName", e.target.value)
                  }
                />
              </Col>
              <Col md={12}>
                <Label for="ofstedNo">
                  Ofsted Number <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter Ofsted Number"
                  value={props.form.ofstedNo}
                  onChange={(e) =>
                    props.saveDataForm("ofstedNo", e.target.value)
                  }
                />
                {props.error.ofstedNo && (
                  <span style={{ color: "red" }}>
                    {"OfstedNo is required"}{" "}
                  </span>
                )}
              </Col>
              <Col md={12}>
                <Label for="vatNo">VAT Number</Label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder="Enter VAT No."
                  value={props.form.vatNo}
                  onChange={(e) => props.saveDataForm("vatNo", e.target.value)}
                />
              </Col>
              <Col md={12}>
                <Label for="link">Link To Privacy Policy</Label>
                <input
                  type="text"
                  className={`form-control `}
                  placeholder="https://"
                  value={props.form.link}
                  onChange={(e) => props.saveDataForm("link", e.target.value)}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Information;
