import React, { useState } from "react";
import RotaManagementForm from "./form.";
import RotaManagementList from "./list";

const RotaManagement = (props: any) => {
  const [action, setAction] = useState({
    id: "",
    action: "List",
  }); // * action: list, add, edit

  return (
    <>
      {action.action === "List" && (
        <RotaManagementList setAction={setAction} nurseryId={props.nurseryId} />
      )}

      {action.action === "Add" && (
        <RotaManagementForm
          setAction={setAction}
          id={action.id}
          nurseryId={props.nurseryId}
        />
      )}

      {action.action === "Edit" && (
        <RotaManagementForm
          setAction={setAction}
          id={action.id}
          nurseryId={props.nurseryId}
        />
      )}
    </>
  );
};

export default RotaManagement;
