import { connect } from "react-redux";
import Layout from "./Layout";
import {
  LOGOUT,
  SET_ERROR,
  SET_NURSERY,
  SET_SUCCESS,
  SET_USER_CLOCK_IN,
} from "../../ReduxToolkit/Slices/Auth/AuthReducer";

const mapStateToProps = (state: any) => {
  console.log("state: ", state.auth);
  return {
    auth: state.auth,
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(LOGOUT()),
    setNursery: (value: string) => dispatch(SET_NURSERY(value)),
    setSuccess: (value: string) => dispatch(SET_SUCCESS(value)),
    setError: (value: string) => dispatch(SET_ERROR(value)),
    setClockIn: (value: any) => dispatch(SET_USER_CLOCK_IN(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
