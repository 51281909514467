import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import General from "./General/General";
import AdminProfile from "../Profile/connector";
import ClosureDay from "./General/ClosureDay"; // ClosureDay Component
import RoomOverview from "./Room/RoomOverview"; // Room Overview Component
import RoomCustomStatus from "./Room/RoomCustomStatus"; // Room Custom Status Component
import RoomMoves from "./Room/RoomMoves";
import JobTitle from "./Staff/JobTitle";
import StaffHours from "./Staff/StaffHours";

import {
  FaCalendar,
  FaChevronDown,
  FaChevronRight,
  FaFileAlt,
  FaUsers,
  FaUserCircle,
  FaBook,
  FaSchool,
  FaChild,
  FaRegCalendarAlt,
} from "react-icons/fa";
import Children from "./Children/Children";
import RolesPermission from "./Staff/RolesPermission";
import OpeningHours from "./General/OpeningHours";
import { useLocation } from "react-router-dom";
import StaffLeave from "./Staff/StaffLeave";
import RotaManagement from "./RotaManagement/RotaManagement";

const Settings = (props: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab_no = Boolean(searchParams.get("role")) || false; // Get the value of 'id'

  const [selectedSection, setSelectedSection] = useState<string>(
    tab_no === true ? "Roles & Permissions" : "General"
  );
  const [openSection, setOpenSection] = useState<string>(
    tab_no === true ? "Staff" : "General"
  );
  const [hoveredButton, setHoveredButton] = useState<string | null>(null); // Track hovered button

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? "" : section);
  };

  const handleClick = (section: string) => {
    setSelectedSection(section); // Update the selected section to render the corresponding component

    console.log(`Navigating to: ${section}`);
    // Here, you would call the component or function that handles the navigation or rendering
  };

  // Render the corresponding component based on selectedSection
  const renderComponent = () => {
    switch (selectedSection) {
      case "Profile":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Profile Settings"
                titleClass="mb-0"
              />
            </div>
            <AdminProfile nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Children":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Children Settings"
                titleClass="mb-0"
              />
            </div>
            <Children />
          </>
        );
      case "Rota Management":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Rota Management Settings"
                titleClass="mb-0"
              />
            </div> */}
            <RotaManagement nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "General":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="General Settings"
                titleClass="mb-0"
              />
            </div>
            <General nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Closure Day":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Closure Day Settings"
                titleClass="mb-0"
              />
            </div> */}
            <ClosureDay nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Opening Hours":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Opening Hours Settings"
                titleClass="mb-0"
              />
            </div>
            <OpeningHours nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Overview":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Overview Settings"
                titleClass="mb-0"
              />
            </div> */}
            <RoomOverview nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Custom Status":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Custom Status Settings"
                titleClass="mb-0"
              />
            </div> */}
            <RoomCustomStatus nurseryId={props.auth.selectedNursery} />
          </>
        );
      case "Room Moves":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Room Moves Settings"
                titleClass="mb-0"
              />
            </div> */}
            <RoomMoves />
          </>
        );
      case "Job Title":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Job Title Settings"
                titleClass="mb-0"
              />
            </div> */}
            <JobTitle />
          </>
        );
      case "Staff Hours":
        return (
          <>
            <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Staff Hours Settings"
                titleClass="mb-0"
              />
            </div>
            <StaffHours />
          </>
        );
      case "Roles & Permissions":
        return (
          <>
            {/* <div className="d-flex justify-content-between align-items-center mb-0">
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Roles & Permission Settings"
                titleClass="mb-0"
              />
            </div> */}
            <RolesPermission
              nurseryId={props.auth.selectedNursery}
              openAddForm={tab_no === true}
            />
          </>
        );
      case "Staff Leave":
        return (
          <>
            <StaffLeave nurseryId={props.auth.selectedNursery} />
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          {/* Left Sidebar with Tabs */}
          <Col md={3}>
            <Card className="shadow-sm rounded border-0">
              <CardBody>
                <ul className="list-unstyled">
                  {/* Profile Section */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Profile");
                        toggleSection("Profile");
                      }}
                      onMouseEnter={() => setHoveredButton("Profile")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background:
                          selectedSection === "Profile" // Check if this section is active
                            ? "#f0f8ff" // Light active background color
                            : hoveredButton === "Profile"
                            ? "#e0f7fa"
                            : "none", // Light hover background color
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className="text-dark"
                    >
                      <FaUserCircle style={{ marginRight: "10px" }} />
                      Profile
                    </button>
                  </li>

                  {/* General Section - Accordion */}
                  <li className="mb-2">
                    <button
                      onClick={() => toggleSection("General")}
                      onMouseEnter={() => setHoveredButton("General")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%", // Ensures the button fills the available space
                        background:
                          openSection === "General" || // If General is open
                          selectedSection === "General" || // If General is active
                          selectedSection === "Closure Day" // If Closure Day (child) is active
                            ? "#f0f8ff" // Light active background color
                            : hoveredButton === "General" // If hovered
                            ? "#e0f7fa" // Light hover background color
                            : "none", // No background otherwise
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px", // Apply padding to the entire button
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className="text-dark"
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <FaBook style={{ marginRight: "10px" }} />
                        General
                      </span>
                      <span style={{ transition: "transform 0.3s" }}>
                        {openSection === "General" ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    </button>

                    {openSection === "General" && (
                      <ul className="list-custom">
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("General")}
                            onMouseEnter={() =>
                              setHoveredButton("General-Child")
                            }
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%", // Ensure full width button
                              background:
                                selectedSection === "General" // If General (child) is active
                                  ? "#f0f8ff" // Light active background color
                                  : hoveredButton === "General-Child" // If hovered
                                  ? "#e0f7fa" // Light hover background color
                                  : "none", // No background otherwise
                              border: "none",
                              fontWeight: "600",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0", // Padding for spacing
                              textAlign: "left",
                              transition: "background 0.3s ease", // Smooth background transition
                            }}
                            className="text-dark"
                          >
                            <span className="general">General</span>
                          </button>
                        </li>

                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Closure Day")}
                            onMouseEnter={() =>
                              setHoveredButton("Closure Day-Child")
                            }
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%", // Ensure full width button
                              background:
                                selectedSection === "Closure Day" // If Closure Day (child) is active
                                  ? "#f0f8ff" // Light active background color
                                  : hoveredButton === "Closure Day-Child" // If hovered
                                  ? "#e0f7fa" // Light hover background color
                                  : "none", // No background otherwise
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0", // Padding for spacing
                              textAlign: "left",
                              transition: "background 0.3s ease", // Smooth background transition
                            }}
                            className="text-dark"
                          >
                            <span className="general">Closure Day</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Opening Hours")}
                            onMouseEnter={() =>
                              setHoveredButton("Opening Hours-Child")
                            }
                            onMouseLeave={() => setHoveredButton(null)}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%", // Ensure full width button
                              background:
                                selectedSection === "Opening Hours" // If Opening Hours (child) is active
                                  ? "#f0f8ff" // Light active background color
                                  : hoveredButton === "Opening Hours-Child" // If hovered
                                  ? "#e0f7fa" // Light hover background color
                                  : "none", // No background otherwise
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0", // Padding for spacing
                              textAlign: "left",
                              transition: "background 0.3s ease", // Smooth background transition
                            }}
                            className="text-dark"
                          >
                            <span className="general">Opening Hours</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>

                  {/* Rooms Section - Accordion */}
                  <li className="mb-2">
                    <button
                      onClick={() => toggleSection("Rooms")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        background:
                          openSection === "Rooms" || // If Rooms is open
                          ["Overview", "Custom Status", "Room Moves"].includes(
                            selectedSection
                          ) // If any child under Rooms is active
                            ? "#f0f8ff" // Light active background color
                            : "none", // No background otherwise
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      className="text-dark"
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <FaSchool style={{ marginRight: "10px" }} />
                        Rooms
                      </span>
                      <span style={{ transition: "transform 0.3s" }}>
                        {openSection === "Rooms" ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    </button>
                    {openSection === "Rooms" && (
                      <ul className="list-custom">
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Overview")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Overview"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Overview</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Custom Status")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Custom Status"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Custom Status</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Room Moves")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Room Moves"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Room Moves</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                  {/* Chidren Section */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Children");
                        toggleSection("Children");
                      }}
                      onMouseEnter={() => setHoveredButton("Children")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background:
                          selectedSection === "Children" // Check if this section is active
                            ? "#f0f8ff" // Light active background color
                            : hoveredButton === "Children"
                            ? "#e0f7fa"
                            : "none", // Light hover background color
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className="text-dark"
                    >
                      <FaChild style={{ marginRight: "10px" }} />
                      Children
                    </button>
                  </li>
                  {/* Rota Management */}
                  <li className="mb-2">
                    <button
                      onClick={() => {
                        handleClick("Rota Management");
                        toggleSection("Rota Management");
                      }}
                      onMouseEnter={() => setHoveredButton("Rota Management")}
                      onMouseLeave={() => setHoveredButton(null)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        background:
                          selectedSection === "Rota Management" // Check if this section is active
                            ? "#f0f8ff" // Light active background color
                            : hoveredButton === "Rota Management"
                            ? "#e0f7fa"
                            : "none", // Light hover background color
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease", // Smooth background transition
                      }}
                      className="text-dark"
                    >
                      <FaRegCalendarAlt style={{ marginRight: "10px" }} />
                      Rota Management
                    </button>
                  </li>
                  {/* Staff Section - Accordion */}
                  <li className="mb-2">
                    <button
                      onClick={() => toggleSection("Staff")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        background:
                          openSection === "Staff" || // If Rooms is open
                          [
                            "Job Title",
                            "Staff Houres",
                            "Roles & Permissions",
                            "Staff Leave",
                          ].includes(selectedSection) // If any child under Rooms is active
                            ? "#f0f8ff" // Light active background color
                            : "none", // No background otherwise
                        border: "none",
                        color: "#000",
                        fontSize: "16px",
                        padding: "10px 15px",
                        cursor: "pointer",
                        transition: "background 0.3s ease",
                      }}
                      className="text-dark"
                    >
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <FaUsers style={{ marginRight: "10px" }} />
                        Staff
                      </span>
                      <span style={{ transition: "transform 0.3s" }}>
                        {openSection === "Staff" ? (
                          <FaChevronDown />
                        ) : (
                          <FaChevronRight />
                        )}
                      </span>
                    </button>
                    {openSection === "Staff" && (
                      <ul className="list-custom">
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Job Title")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Job Title"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Job Title</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Staff Hours")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Staff Hours"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Staff Hours</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Roles & Permissions")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Roles & Permissions"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Role & Permissions</span>
                          </button>
                        </li>
                        <li className="mb-2">
                          <button
                            onClick={() => handleClick("Staff Leave")}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                              background:
                                selectedSection === "Staff Leave"
                                  ? "#f0f8ff"
                                  : "none",
                              border: "none",
                              color: "#555",
                              fontSize: "14px",
                              cursor: "pointer",
                              padding: "5px 0",
                              textAlign: "left",
                              transition: "background 0.3s ease",
                            }}
                            className="text-dark"
                          >
                            <span className="general">Staff Leave</span>
                          </button>
                        </li>
                      </ul>
                    )}
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>

          {/* Right Content for each tab */}
          <Col md={9}>
            <Card className="shadow-sm rounded border-0">
              <CardBody>
                {/* Render the selected section component */}
                {renderComponent()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Settings;
