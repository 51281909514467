import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Tooltip,
} from "reactstrap";
import { LI, UL } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import JobTitleApi from "../../../../services/JobTitleApi";
import { useForm } from "react-hook-form";

interface JobTitle {
  id: string;
  jobTitle: string;
  employeeCount: number;
}

interface CustomHeaderProps {
  title: string;         // Required title prop
  className?: string;    // Optional className prop
}

const CustomHeader = ({ title, className }: CustomHeaderProps) => (
  <span
    className={className}  // Apply className if provided
    style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}
  >
    {title}
  </span>
);

const JobTitle: React.FC = () => {
  const navigate = useNavigate();
  const [tooltipOpen, setTooltipOpen] = useState<{ [key: string]: boolean }>(
    {}
  );
  const toggleTooltip = (id: string) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const [jobTitle, setJobTitle] = useState<JobTitle[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const handleEditJobTitle = (jobTitle: JobTitle) => {
    setSelectedJobTitle(jobTitle);
    toggleModal();
  };

  const [selectedJobTitle, setSelectedJobTitle] = useState<
    Partial<JobTitle>
  >({}); // For editing Job Title

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<JobTitle>();

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setSelectedJobTitle({}); // Clear selected Job Title
    } else {
      // If opening modal, reset with selected Job Title data for edit, otherwise with empty data for add
      reset(selectedJobTitle);
    }
  };

  // Handle form submission for both create and update
  const onSubmit = async (data: JobTitle) => {
    try {
      if (selectedJobTitle.id) {
        // Update job Title
        await JobTitleApi.updateJobTitle(selectedJobTitle.id, data);
        Swal.fire({
          title: "Success!",
          text: "Job Title updated successfully",
          icon: "success",
        });
      } else {
        // Create new job Title
        await JobTitleApi.createJobTitle(data);
        Swal.fire({
          title: "Success!",
          text: "Job Title created successfully",
          icon: "success",
        });
      }
      // Reload the data after successful operation
      fetchJobTitles();
      toggleModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  // Fetch Job Title from the API
  const fetchJobTitles = async () => {
    try {
      const response = await JobTitleApi.getJobTitle();
      if (response.success && Array.isArray(response.data)) {
        setJobTitle(response.data);
      } else {
        throw new Error("Failed to fetch Job Title");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Job Title",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  const handleDeleteJobTitle = async (jobTitleId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await JobTitleApi.deleteJobTitle(jobTitleId); // API call to delete the comment
          setJobTitle((prev) =>
            prev.filter((jobTitle) => jobTitle.id !== jobTitleId)
          ); // Update state
          fetchJobTitles();
          Swal.fire("Deleted!", "Job title has been deleted.", "success");
        } catch (error: any) {
          Swal.fire(
            "Error!",
            error.message || "Failed to delete the comment.",
            "error"
          );
        }
      }
    });
  };

  const filteredItems = jobTitle.filter((jobTitle) =>
    jobTitle.jobTitle.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns: TableColumn<JobTitle>[] = [
    {
      name: <CustomHeader title="Job Title" />,
      selector: (row) => row.jobTitle,
      sortable: true,
      cell: (row) => (
        <div
          id={`descriptionTooltip-${row.id}`}
          style={{
            maxWidth: "300px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
        >
          {row.jobTitle}

          <Tooltip
            placement="top"
            isOpen={tooltipOpen[row.id]}
            target={`descriptionTooltip-${row.id}`}
            toggle={() => toggleTooltip(row.id)}
            style={{
              // backgroundColor: "white",
              // color: "black",
              border: "1px solid #ccc",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            {row.jobTitle}
          </Tooltip>
        </div>
      ),
    },
    // {
    //   name: <CustomHeader title="Number of Employess" />,
    //   selector: (row) => row.employeeCount,
    //   sortable: true,
    // },
    {
      name: <CustomHeader title="Action"  className="action-column"/>,
      cell: (row) => (
        <UL className="action simple-list row w-100 flex-row justify-content-end">
          <LI className="edit col-md-1 w-40px pr-1">
            <a
              href={"javascript:void(0);"}
              onClick={() => handleEditJobTitle(row)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
          <LI className="delete col-md-1 w-40px">
            <a
              href={"javascript:void(0);"}
              onClick={(e) => {
                e.preventDefault();  // Prevent page refresh
                handleDeleteJobTitle(row?.id);  // Call your delete handler
              }}
              className="btn btn-danger d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-trash text-white" />
            </a>
          </LI> 
        </UL>
      ),
    },
  ];

  return (
    <div className="page-body mt-0">
      <Container fluid>
        <Row className="pt-0">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="flex-grow-1 text-left"
                  title="Job Title"
                  subTitle={[]}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={toggleModal}
                >
                  <i className="fa fa-plus me-2" /> Add Job Title
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Modal for Adding Job Title */}
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {selectedJobTitle.id ? "Edit Job Title" : "Add Job Title"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="jobTitle">
                Job Title <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control ${errors.jobTitle ? "is-invalid" : ""}`}
                  id="jobTitle"
                  {...register("jobTitle", { required: "Job Title is required" })}
                  defaultValue={selectedJobTitle.jobTitle}
                />
                {errors.jobTitle && (
                  <FormFeedback>{errors.jobTitle.message}</FormFeedback>
                )}
              </FormGroup>
              <ModalFooter>
                <Button color="primary" type="submit">
                  {selectedJobTitle.id
                    ? "Update Job Title"
                    : "Add Job Title"}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default JobTitle;
