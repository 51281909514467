import React, { useState } from "react";
import StaffLeaveList from "./list";
import StaffLeaveForm from "./form";

const StaffLeave = (props: any) => {
  const [action, setAction] = useState({
    id: "",
    action: "List",
  }); // * action: list, add, edit

  return (
    <>
      {action.action === "List" && (
        <StaffLeaveList setAction={setAction} nurseryId={props.nurseryId} />
      )}

      {action.action === "Add" && (
        <StaffLeaveForm
          setAction={setAction}
          id={action.id}
          action={action.action}
          nurseryId={props.nurseryId}
        />
      )}

      {action.action === "Edit" && (
        <StaffLeaveForm
          setAction={setAction}
          action={action.action}
          id={action.id}
          nurseryId={props.nurseryId}
        />
      )}
    </>
  );
};

export default StaffLeave;
