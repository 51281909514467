import React, { useEffect, useState } from "react";
import { Card, CardBody, Table, Input, Button } from "reactstrap";
import NurseryAPi from "../../../../services/NurseryApi";
import UserActivityAPi from "../../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../../Utils";
import Swal from "sweetalert2";

// Type definition for the opening hours state
type OpeningHoursState = {
  Monday: { open: string; close: string };
  Tuesday: { open: string; close: string };
  Wednesday: { open: string; close: string };
  Thursday: { open: string; close: string };
  Friday: { open: string; close: string };
  Saturday: { open: string; close: string };
  Sunday: { open: string; close: string };
};

const OpeningHours = (props: any) => {
  const initialState = {
    Monday: { open: "08:00", close: "17:00" },
    Tuesday: { open: "08:00", close: "17:00" },
    Wednesday: { open: "08:00", close: "17:00" },
    Thursday: { open: "08:00", close: "17:00" },
    Friday: { open: "08:00", close: "17:00" },
    Saturday: { open: "08:00", close: "17:00" },
    Sunday: { open: "08:00", close: "17:00" },
  };

  // State to manage open and close times for each day (formatted as 'HH:MM')
  const [openingHours, setOpeningHours] =
    useState<OpeningHoursState>(initialState);
  const [id, setId] = useState<any>("");

  // Function to handle time input changes
  const handleTimeChange = (
    day: keyof OpeningHoursState,
    type: "open" | "close",
    value: string
  ) => {
    setOpeningHours((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        [type]: value,
      },
    }));
  };

  const getOpeningHours = async () => {
    try {
      const response = await NurseryAPi.getGeneralInformation({
        nurseryId: props.nurseryId,
      });

      if (response.success && Object.keys(response?.data ?? {})?.length > 0) {
        const filteredData = response?.data;
        if (Object.keys(filteredData)?.length > 0) {
          const formData = {
            id: filteredData.id,
            openingHours: JSON.parse(filteredData.openingHours),
          };

          setId(formData.id);
          setOpeningHours(formData.openingHours);
        }
      } else {
        setId("");
        setOpeningHours(initialState);
      }
    } catch (error: any) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getOpeningHours();
  }, [props.nurseryId]);

  useEffect(() => {
    getOpeningHours();
  }, []);

  const handleHoursSubmit = async () => {
    try {
      // Format the data before sending it to the API
      let formattedData: any = {};
      formattedData["id"] = id;
      formattedData["openingHours"] = `${JSON.stringify(openingHours)}`;
      formattedData["nurseryId"] = props.nurseryId;
      const response = await NurseryAPi.addUpdateGeneralInformation(
        formattedData
      );
      Swal.fire({
        title: "Success!",
        text: `Opening hours is updated successfully.`,
        icon: "success",
      }).then(async () => {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData(
            "Settings",
            `Opening hours is updated successfully.`
          )
        );
        await getOpeningHours();
      });
    } catch (error: any) {
      // Display an error message if something goes wrong
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <Card className="rounded">
      <CardBody>
        <p className="text-muted mb-4">
          Set the opening and closing times for each day of the week.
        </p>

        <Table responsive bordered hover>
          <thead>
            <tr>
              <th className="text-left text-primary">Day</th>
              <th className="text-left text-primary">Open</th>
              <th className="text-left text-primary">Close</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(openingHours).map((day) => {
              return (
                <tr key={day} className="hover-bg-light">
                  <td className="text-left">{day}</td>
                  <td className="text-center">
                    <Input
                      type="time"
                      value={`${
                        openingHours[day as keyof OpeningHoursState].open
                      }`}
                      onChange={(e) =>
                        handleTimeChange(
                          day as keyof OpeningHoursState,
                          "open",
                          e.target.value
                        )
                      }
                      className="form-control custom-input"
                    />
                  </td>
                  <td className="text-center">
                    <Input
                      type="time"
                      value={openingHours[day as keyof OpeningHoursState].close}
                      onChange={(e) =>
                        handleTimeChange(
                          day as keyof OpeningHoursState,
                          "close",
                          e.target.value
                        )
                      }
                      className="form-control custom-input"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Button color="primary" className="mt-3" onClick={handleHoursSubmit}>
          Save & Update
        </Button>
      </CardBody>
    </Card>
  );
};

export default OpeningHours;
