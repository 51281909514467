import React, { useEffect, useState } from "react";
import { Btn } from "../../../../../AbstractElements";
import { TouchspinProp } from "../../../../../Types/Forms.type";
import { Input } from "reactstrap";

export default function CommonTouchspin({
  color,
  arrowIcon,
  outline,
  btnClass,
  setValue,
  value,
  maxLength,
}: TouchspinProp) {
  const [touchSpinValue, setTouchSpinValue] = useState(
    value ?? Math.floor(Math.random() * 10)
  );

  const decrementHandle = () => {
    if (touchSpinValue > 0) {
      setTouchSpinValue(touchSpinValue - 1);
    }
  };
  const incrementHandle = () => {
    if (touchSpinValue < (maxLength || 100)) {
      setTouchSpinValue(touchSpinValue + 1);
    }
  };

  useEffect(() => {
    setValue && setValue(touchSpinValue);
  }, [setValue, touchSpinValue]);

  return (
    <div className="touchspin-wrapper">
      <Btn
        onClick={decrementHandle}
        color="transparent"
        outline={outline}
        className={`decrement-touchspin btn-touchspin ${
          btnClass ? btnClass : ""
        }`}
      >
        {arrowIcon ? (
          <i className="fa-solid fa-angle-left" />
        ) : (
          <i className="fa-solid fa-minus" />
        )}
      </Btn>
      <Input
        className={`mx-1 input-touchspin spin-outline-${color}`}
        type="number"
        value={touchSpinValue}
        readOnly
      />
      <Btn
        onClick={incrementHandle}
        color="transparent"
        outline={outline}
        className={`increment-touchspin btn-touchspin ${
          btnClass ? btnClass : ""
        }`}
      >
        {arrowIcon ? (
          <i className="fa-solid fa-angle-right" />
        ) : (
          <i className="fa-solid fa-plus" />
        )}
      </Btn>
    </div>
  );
}
