import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  InputGroup,
} from "reactstrap";

import CommonCardHeader from "../../../Utils/CommonComponents/CommonCardHeader";
import Select from "react-select";
import {
  FaEdit,
  FaImage,
  FaPencilRuler,
  FaSmile,
  FaTimes,
  FaVideo,
} from "react-icons/fa";
import EmojiPicker from "emoji-picker-react";
import UploadAPi from "../../../services/UploadApi";
import PostAPi from "../../../services/PostApi";
import { baseUrl } from "../../../Utils/Constants/config";
import { dynamicImage, fetchImage } from "../../../Utils";
import { H3, H6 } from "../../../AbstractElements";
import moment from "moment";
import { LI, UL, Btn, H4, Image, P } from "../../../AbstractElements";
import CountUp from "react-countup";
import { Href, postVisibilityOptions } from "../../../Utils/Constants";
import CommentApi from "../../../services/CommentApi";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

interface Comment {
  id: string;
  newsFeedId: string;
  comment: string;
}

function EmployeeNewsFeed(props: any) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentContent, setCommentContent] = useState<string>("");
  const [selectedPostId, setSelectedPostId] = useState<string>("");
  const [likesCount, setLikesCount] = useState<number>(0); // Change to number
  const [isLiked, setIsLiked] = useState<boolean>(false); // Change to boolean

  const [postContent, setPostContent] = useState<string>("");
  const [postImage, setPostImage] = useState<string | null>(null);
  const [media, setMedia] = useState({ mediaUrl: "", mediaType: "" });
  const [postVideo, setPostVideo] = useState<string | null>(null);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState<boolean>(false);
  const [postVisibility, setPostVisibility] = useState<string>("Everyone");
  const [post, setPost] = useState<any[]>([]);
  const [mediaUrls, setMediaUrls] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState<Partial<Comment>>({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Comment>();

  // Toggle function for modal
  const [modal, setModal] = useState<boolean>(false);
  const toggle = async () => {
    setModal(!modal); // Toggle modal visibility
  };
  // Handle comment click to open the modal and fetch comments
  const handleCommentClick = async (postId: string) => {
    await fetchComments(postId); // Fetch comments when opening the modal
    setSelectedPostId(postId); // Set selected post ID for comments
    toggle(); // Open the modal
  };

  const handleDeleteComment = async (commentId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this action!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await CommentApi.deleteComment(commentId); // API call to delete the comment
          setComments((prev) =>
            prev.filter((comment) => comment.id !== commentId)
          ); // Update state
          fetchPosts();
          Swal.fire("Deleted!", "Your comment has been deleted.", "success");
        } catch (error: any) {
          Swal.fire(
            "Error!",
            error.message || "Failed to delete the comment.",
            "error"
          );
        }
      }
    });
  };

  // Handle submission
  const onSubmit = async () => {
    console.log(456);
    try {
      // Create a new comment
      await CommentApi.createComment({
        newsFeedId: selectedPostId,
        comment: commentContent,
      });
      setCommentContent("");
      Swal.fire("Success", "Comment created successfully", "success");

      fetchComments(selectedPostId); // Refresh comments
      fetchPosts();
    } catch (error: any) {
      Swal.fire("Error", error.message || "An error occurred", "error");
    }
  };

  // Open modal for editing a specific comment
  const handleEditComment = (comment: Comment) => {
    setSelectedComment(comment);
    toggle();
  };

  const fetchPosts = async () => {
    try {
      const responseData = await PostAPi.fetchPost({
        page: 1,
        pageSize: 1000,
        nurseryId: props.auth.selectedNursery,
      });

      if (responseData && responseData.data.list) {
        const posts = responseData.data.list; // Assuming the posts are in responseData.data.list
        setPost(posts);

        if (posts.length > 0) {
          // Automatically select the first post ID or any other logic to select a post
          setSelectedPostId(posts[0].id);
        }
      }
    } catch (err) {
      console.error("Error fetching posts:", err);
    }
  };
  useEffect(() => {
    fetchPosts();
  }, [props.auth.selectedNursery]);

  // Handle Like Click
  const handleLike = async (postId: string) => {
    try {
      const filterData = post.filter((row) => row.id == postId);
      // If post is already liked, remove the like
      const reaction = filterData?.[0]?.isLiked ? "DISLIKE" : "LIKE";
      const response = await PostAPi.reactPost({
        newsFeedId: postId,
        reaction: reaction, // Here, we send the reaction as "like" or "unlike"
      });

      if (response.success && Object.keys(response.data).length > 0) {
        // Update the like state and count
        setIsLiked(!filterData); // Toggle the like status
        const likeData = response.data;
        const updatedLikeData = post.map((row) => {
          if (row.id == likeData.id) {
            row.likesCount = likeData.likesCount;
            row.commentsCount = likeData.commentsCount;
            row.isLiked = likeData.isLiked;
          }
          return row;
        });
        setPost(updatedLikeData);
        Swal.fire(
          "Success",
          `You have ${!likeData.isLiked ? "DISLIKE" : "LIKE"} this post!`,
          "success"
        );
      } else {
        Swal.fire(
          "Error",
          "Something went wrong, please try again later.",
          "error"
        );
      }
    } catch (error: any) {
      Swal.fire("Error", error.message || "An unknown error occurred", "error");
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData: any = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder", "newsFeed");
      try {
        const response = await UploadAPi.uploadFile(
          formData,
          "upload-news-feed"
        );
        setMedia({ mediaUrl: response.filePath, mediaType: "image" });
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setPostImage(imageUrl);
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  const handleVideoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const formData: any = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("folder", "newsFeed");
      try {
        const response = await UploadAPi.uploadFile(
          formData,
          "upload-news-feed"
        );
        setMedia({ mediaUrl: response.filePath, mediaType: "video" });
        const videoUrl = URL.createObjectURL(e.target.files[0]);
        setPostVideo(videoUrl);
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    setPostContent((prevContent) => prevContent + emoji.emoji);
    setEmojiPickerOpen(false);
  };

  const handleRemove = async (type: string) => {
    try {
      await UploadAPi.removeUploadedFile(
        { filePath: media.mediaUrl },
        "remove-news-feed"
      );
      setMedia({ mediaUrl: "", mediaType: "" });
      if (type == "image") {
        setPostImage(null);
      } else {
        setPostVideo(null);
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const handlePostSubmit = async () => {
    // Post submission logic
    if (postContent != "") {
      const body = {
        content: postContent,
        mediaUrl: media.mediaUrl, // will replaced by image url which comes in response of upload api
        mediaType: media.mediaType.toUpperCase(),
        visibility: postVisibility.toUpperCase(),
        authorId: props.auth.user?.id,
        nurseryId: props.auth.selectedNursery,
      };
      try {
        const response = await PostAPi.createPost(body);
        Swal.fire("Success", `Post Created Successfully!`, "success");
        // const updated = [...post, response.data];
        // setPost(updated);
        setPost((prevPosts) => [response.data, ...prevPosts]);

        setPostContent("");
        setPostImage(null);
        setPostVideo(null);
        setMedia({ mediaUrl: "", mediaType: "" });
        setPostVisibility("Everyone");
      } catch (err) {
        console.log("err: ", err);
      }
    }
  };

  // Fetch comments from the API for a selected post ID
  const fetchComments = async (postId: string) => {
    if (!postId) return;

    setLoading(true);
    try {
      const response = await CommentApi.getComments(postId, {
        page: 1,
        pageSize: 10,
      });
      if (response.success && Array.isArray(response.data.list)) {
        setComments(response.data.list); // Set the comments specific to the post
      } else {
        throw new Error("Failed to fetch comments");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch comments",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {

  //   fetchComments();
  // }, [selectedPostId]);

  useEffect(() => {
    const fetchAllMedia = async () => {
      const urls = await Promise.all(
        post.map(async (row: any) => {
          const mediaUrl = `${baseUrl}${row.mediaUrl}`;
          const urlData = await fetchImage(mediaUrl);
          return { ...row, mediaUrl: urlData ? urlData : "" }; // Return the row data with the resolved urlData
        })
      );
      setMediaUrls(urls); // Update state with fetched media URLs
    };

    fetchAllMedia();
  }, [post, baseUrl]);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: "1px solid #308e87", // Matches SCSS
      borderRadius: "8px",
      boxShadow: state.isFocused ? "0 0 5px #308e87" : "none", // Matches .dropdown-selected.isFocused
      padding: "5px 15px",
      backgroundColor: "#fff",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#308e87", // Matches hover effect in SCSS
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? "#308e87" : "#308e87", // Matches isActive color
      backgroundColor: state.isFocused ? "#f8f9fa" : "#fff", // Matches hover effect
      padding: "10px 15px", // Matches padding in SCSS
      cursor: "pointer",
      fontWeight: state.isSelected ? "bold" : "normal", // Matches isActive font-weight
      position: "relative",
      "&::after": state.isSelected
        ? {
            content: '"✓"',
            position: "absolute",
            right: "10px",
            color: "#308e87", // Green checkmark
            fontSize: "14px",
          }
        : null,
    }),
    menu: (provided: any) => ({
      ...provided,
      border: "1px solid #000",
      borderRadius: "8px",
      backgroundColor: "#fff",
      zIndex: 1000,
      maxHeight: "150px",
      overflowY: "auto",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#000", // Matches .dropdown-selected color
      fontWeight: "bold",
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: state.isFocused ? "#308e87" : "#000", // Matches dropdown indicator hover effect
      "&:hover": {
        color: "#308e87",
      },
    }),
  };

  return (
    <div className="page-body">
      <Container fluid>
        <Row className="pt-4">
          <Col sm={12}>
            <Card>
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Create Post"
                titleClass="mb-3"
              />
              <CardBody>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row className="align-items-center mb-3">
                    <Col md={12}>
                      <div className="d-flex align-items-center mb-3">
                        <div className="d-flex align-items-center me-3">
                          <div
                            className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center"
                            style={{
                              width: "40px",
                              height: "40px",
                              fontSize: "16px",
                            }}
                          >
                            DE
                          </div>
                          <span className="ms-2">
                            {props.auth.user.firstName}{" "}
                            {props.auth.user.lastName}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Row className="mb-4 mt-3 align-items-center">
                        <Col md={3} lg={2} sm={6}>
                          <div className="d-flex">
                            <H3>
                              <Label
                                for="postContent"
                                className="form-label mb-0"
                              >
                                Write a post
                              </Label>
                            </H3>
                            <FaEdit
                              className="text-primary"
                              size={20}
                              style={{
                                cursor: "pointer",
                                marginRight: "0px",
                                marginLeft: "10px",
                              }}
                              onClick={() =>
                                document
                                  .getElementById("postImageInput")
                                  ?.click()
                              }
                            />
                          </div>
                        </Col>
                        <Col md={4} lg={3} sm={6}>
                          <Select
                            id="visibility"
                            className={"custom-select"}
                            styles={customStyles}
                            options={postVisibilityOptions}
                            value={postVisibilityOptions.find(
                              (option: any) => option.value === postVisibility
                            )} // Set current value
                            onChange={(selectedOption: any) => {
                              setPostVisibility(selectedOption?.value);
                            }} // Update the value using Controller's onChange
                          />
                        </Col>
                        <Col md={5} lg={7} sm={6} className="text-end pr-0">
                          {postImage == null && postVideo == null && (
                            <>
                              <FaImage
                                className="text-primary"
                                size={24}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                                onClick={() =>
                                  document
                                    .getElementById("postImageInput")
                                    ?.click()
                                }
                              />
                              <Input
                                type="file"
                                id="postImageInput"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                              />
                            </>
                          )}

                          {postImage == null && postVideo == null && (
                            <>
                              <FaVideo
                                className="text-primary"
                                size={24}
                                style={{
                                  cursor: "pointer",
                                  marginRight: "10px",
                                }}
                                onClick={() =>
                                  document
                                    .getElementById("postVideoInput")
                                    ?.click()
                                }
                              />
                              <Input
                                type="file"
                                id="postVideoInput"
                                accept="video/*"
                                style={{ display: "none" }}
                                onChange={handleVideoChange}
                              />
                            </>
                          )}

                          <FaSmile
                            className="text-primary"
                            size={24}
                            style={{ cursor: "pointer" }}
                            onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
                          />
                          {emojiPickerOpen && (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                left: "6.5rem",
                              }}
                            >
                              <FaTimes
                                size={24}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "22px",
                                  right: "6px",
                                  zIndex: 1,
                                }}
                                onClick={() =>
                                  setEmojiPickerOpen(!emojiPickerOpen)
                                }
                              />
                              <EmojiPicker
                                className="mt-3 pt-3"
                                style={{ zIndex: 1 }}
                                onEmojiClick={handleEmojiSelect}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col md={12}>
                      <Input
                        type="textarea"
                        id="postContent"
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                        rows={4}
                        placeholder="What's on your mind?"
                      />
                    </Col>
                  </Row>

                  <div className="preview-section mb-4">
                    {postImage && (
                      <>
                        <FaTimes
                          size={24}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove("image")}
                        />
                        <img
                          src={postImage}
                          alt="Preview"
                          className="img-thumbnail"
                          style={{ width: "100px", marginBottom: "10px" }}
                        />
                      </>
                    )}
                    {postVideo && (
                      <>
                        <FaTimes
                          size={24}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove("video")}
                        />
                        <video
                          controls
                          src={postVideo}
                          style={{ width: "100px", marginBottom: "10px" }}
                        />
                      </>
                    )}
                  </div>

                  <Button color="primary" onClick={handlePostSubmit}>
                    Post
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="pt-4">
          <Col sm={12}>
            {mediaUrls.map((row: any) => {
              return (
                <Card>
                  <CardBody>
                    <div className="new-users-social">
                      <div className="d-flex gap-3">
                        <img
                          src={dynamicImage(`default-profile.jpg`)}
                          alt=""
                          className="rounded-circle image-radius m-r-15 media"
                        />
                        <div className="flex-grow-1">
                          <h6 className="mb-0">{row?.author?.firstName}</h6>
                          <p>
                            {moment(row?.createdAt).format("MMMM, DD,YYYY")}
                          </p>
                        </div>
                        <span className="pull-right mt-0"></span>
                      </div>
                      <hr />
                    </div>
                    <div className="timeline-content mb-3">
                      <p>{row.content}</p>
                    </div>
                    <Row>
                      <Col md={8}>
                        {row.mediaType === "IMAGE" ? (
                          <img src={row.mediaUrl} className="img-fluid media" />
                        ) : row.mediaType === "VIDEO" ? (
                          <video
                            controls
                            src={row.mediaUrl}
                            className="img-fluid media"
                            style={{ marginBottom: "10px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    {/* <div className="timeline-content">
                      <p>{row.content}</p>
                      <div className="like-content">
                        <span>
                          <i className="fa-solid fa-heart font-danger"></i>
                        </span>
                        <span className="pull-right comment-number">
                          <span>20 </span>
                          <span>
                            <i className="fa-solid fa-share-nodes me-0"></i>
                          </span>
                        </span>
                        <span className="pull-right comment-number">
                          <span>10 </span>
                          <span>
                            <i className="fa-regular fa-comment"></i>
                          </span>
                        </span>
                      </div> 
                    </div> */}
                    {/* <div className={`like-comment mt-3`}>
                      <UL className="simple-list list-inline flex-row">
                        <LI className="list-inline-item border-right pe-3">
                          <Label className="m-0">
                            <a href={Href}>
                              <i className="fa-solid fa-heart me-2" />
                            </a>
                            {"Like"}
                          </Label>
                          <CountUp end={2659} className="ms-2 counter" />
                        </LI>
                        <LI className="list-inline-item ms-2">
                          <Label className="m-0">
                            <a
                              href={Href}
                              onClick={(e) => {
                                e.preventDefault();
                                toggle();
                              }}
                            >
                              <i className="fa-solid fa-comment me-2" />
                              {"Comment"}
                            </a>
                          </Label>
                          <CountUp end={569} className="ms-2 counter" />
                        </LI>
                      </UL>
                    </div> */}
                    {/* Like and Comment Section */}
                    <div className="like-comment mt-3">
                      <ul className="simple-list list-inline flex-row">
                        {/* Like Section */}

                        {/* <LI className="list-inline-item border-right pe-3">
                          <Label className="m-0">
                            <a
                              href={Href}
                              onClick={(e) => {
                                e.preventDefault();
                                handleLike(row.id); // Call the handleLike function on click
                              }}
                            >
                              <i className="fa-solid fa-heart me-2" />
                            </a>
                            {"Like"}
                          </Label>
                          <CountUp
                            end={row.likesCount}
                            className="ms-2 counter"
                          />
                        </LI> */}
                        <LI className="list-inline-item border-right pe-3">
                          <Label className="m-0">
                            <a
                              href="javascript:void(0);"
                              onClick={(e) => {
                                e.preventDefault();
                                handleLike(row.id); // Trigger like/dislike on click
                              }}
                              className="like-button"
                            >
                              <i
                                className={`fa-heart me-2 ${
                                  row.isLiked ? "fa-solid liked" : "fa-regular"
                                }`}
                              />
                            </a>
                            <CountUp
                              end={row.likesCount}
                              className="ms-2 counter"
                            />{" "}
                            <span className="text-primary">
                              {row.likesCount === 1 ? "Like" : "Likes"}{" "}
                            </span>
                            {/* Conditional display */}
                          </Label>
                        </LI>

                        {/* Comment Section */}
                        <LI className="list-inline-item ms-0">
                          <Label className="m-0">
                            <a
                              href="javascript:void(0);"
                              onClick={(e) => {
                                handleCommentClick(row.id); // Set the post ID for fetching comments
                              }}
                              className="comment-button"
                            >
                              <i className="fa-solid fa-comment me-2" />
                              <CountUp
                                end={row.commentsCount}
                                className="ms-2 counter text-dark"
                              />{" "}
                              {row.commentsCount === 1 ? "Comment" : "Comments"}{" "}
                              {/* Conditional text */}
                            </a>
                          </Label>
                        </LI>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </Col>
        </Row>

        <div>
          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Comments</ModalHeader>
            <ModalBody>
              <div className="modal-toggle-wrapper p-4">
                {loading ? (
                  <p>Loading comments...</p>
                ) : comments.length > 0 ? (
                  <UL className="main-lists-contents">
                    {comments.map((comment: any) => (
                      <LI
                        key={comment?.id}
                        className="list-group-item-action static-list-item"
                      >
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <div className="list-wrapper">
                            <Image
                              className="list-img"
                              // src={
                              //   comment?.author?.avatarUrl ||
                              //   "/static/media/default-profile.jpg"
                              // }
                              src="/static/media/default-profile.e14f67ddd40c5a68b2dd.jpg"
                              alt={`${comment?.author?.firstName} ${comment?.author?.lastName}`}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                            <div className="list-content">
                              <h6>
                                {comment?.author?.firstName}{" "}
                                {comment?.author?.lastName}
                              </h6>
                              <p className="mb-1">{comment?.comment}</p>
                              <small className="text-muted">
                                {new Date(comment?.createdAt).toLocaleString()}
                              </small>
                            </div>
                          </div>
                          <Button
                            color="danger"
                            style={{ fontSize: "12px" }}
                            onClick={() => handleDeleteComment(comment?.id)}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </Button>
                        </div>
                      </LI>
                    ))}
                  </UL>
                ) : (
                  <p>No comments available for this post.</p>
                )}
              </div>
              <Form onSubmit={(e) => e.preventDefault()}>
                <InputGroup className="mt-3">
                  <Input
                    type="text"
                    value={commentContent}
                    placeholder="Add a comment..."
                    onChange={(e) => setCommentContent(e.target.value)}
                  />
                  <Button color="primary" onClick={onSubmit}>
                    Send
                  </Button>
                </InputGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Container>
    </div>
  );
}

export default EmployeeNewsFeed;
