import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { LI, UL } from "../../../../../AbstractElements";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../../Components/Tables/DataTables/Common/FilterComponent";
import { useForm } from "react-hook-form";
import NurseryAPi from "../../../../../services/NurseryApi";
import Swal from "sweetalert2";

interface RolesPermission {
  id: string;
  title: string;
  permissions: string;
  nurseryId: string;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const RolesPermissionList = (props: any) => {
  const navigate = useNavigate();
  const [rolesPermission, setRolesPermission] = useState<RolesPermission[]>([]);
  const [filterText, setFilterText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const handleEditRolesPermission = (rolesPermission: RolesPermission) => {
    props.setAction({ id: rolesPermission.id, action: "edit" });
  };

  // Fetch Roles & Permission from the API
  const fetchRolesPermissions = async () => {
    try {
      const response = await NurseryAPi.getRolesPermissions({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.data)) {
        setRolesPermission(response.data);
      } else {
        throw new Error("Failed to fetch Roles & Permission");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Roles & Permission",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRolesPermissions();
  }, [props.nurseryId]);

  const filteredItems = rolesPermission.filter((rolesPermission) =>
    rolesPermission.title.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns: TableColumn<RolesPermission>[] = [
    {
      name: <CustomHeader title="Title" />,
      selector: (row) => row.title,
      sortable: true,
      style: {
        minWidth: "85%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "85%", // Set consistent width
      maxWidth: "85%",
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="#"
              onClick={() => handleEditRolesPermission(row)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <div className="page-body mt-0">
      <Container fluid>
        <Row className="pt-0">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="flex-grow-1 text-left"
                  title="Roles & Permission"
                  subTitle={[]}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => props.setAction({ id: "", action: "add" })}
                >
                  <i className="fa fa-plus me-2" /> Add Roles & Permissions
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RolesPermissionList;
