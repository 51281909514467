import api from "./api"; // Use the Axios instance with interceptor

export default class RotaAPi {
    static async addUpdate(data: any) {
        try {
            const response = await api.post("/rota/add-update", data); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(JSON.stringify(errorMessage));
        }
    }

    static async getRotas(data: any) {
        try {
            const response = await api.get("/rota", { params: data }); // Changed to /faq/
            console.log(response.data); // Log the fetched data

            return response.data;
        } catch (error: any) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.response?.data?.error ||
                error.message ||
                "An unknown error occurred";
            throw new Error(JSON.stringify(errorMessage));
        }
    }
}