import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";

import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  bytesToMB,
  commonKeysObject,
  dynamicImage,
  formatString,
  getUserActivityFormattedData,
  handleFileDownload,
} from "../../../../../Utils";
import moment from "moment";
import { employeeType } from "../../../../../Utils/Constants";
import { Btn, H1, LI, UL } from "../../../../../AbstractElements";
import DataTable, { TableColumn } from "react-data-table-component";
import DocumentAPi from "../../../../../services/DocumentApi";
import UploadAPi from "../../../../../services/UploadApi";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import { baseUrl } from "../../../../../Utils/Constants/config";
import { useForm } from "react-hook-form";
import FilterComponent from "../../../../../Components/Tables/DataTables/Common/FilterComponent";

const Document = (props: any) => {
  const { id } = useParams();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showHide, setShowHide] = useState<boolean>(false);
  const [filterText, setFilterText] = useState("");
  const [selectedFolder, setSelectedFolder] = useState<any>({});

  const [documentFolders, setDocumentFolders] = useState<any>([]);
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialState: any = {
    id: "",
    name: "",
    description: "",
    visibility: "SPECIFIC_EMPLOYEE",
    employeeIds: [id],
  };
  const [form, setForm] = useState<any>(initialState);

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<any>();

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  // Filter the custom status list based on title
  const filteredItems = documentFolders.filter((row: any) =>
    row.folderName.toLowerCase().includes(filterText.toLowerCase())
  );

  const [filterObj, setFilterObj] = useState<any>({
    page: 1,
    pageSize: 10000,
    visibility: "SPECIFIC_EMPLOYEE",
  });

  const fetchFolders = async () => {
    try {
      const response = await DocumentAPi.getFoldersByUserId(filterObj, id);
      if (
        response.success &&
        Object.keys(response.data).length > 0 &&
        response.data.list.length > 0
      ) {
        setDocumentFolders(response.data.list);
      } else {
        setDocumentFolders([]);
      }
    } catch (error: any) {
      toast.error("error: ", error);
    }
  };

  useEffect(() => {
    fetchFolders();
  }, [id, props.tabId]);

  const CustomHeader = ({ title }: { title: string }) => (
    <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
      {title}
    </span>
  );

  const handleViewFolder = (folder: any) => {
    setShowHide(true);
    setSelectedFolder(folder);
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Folder Name" />,
      selector: (row) => `${row.folderName}`,
      style: { maxWidth: "50%" },
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Action" />,
      style: { paddingLeft: "6px", paddingRight: "6px" },
      sortable: true,
      center: true,
      cell: (row) => (
        <td>
          {/* Edit Button */}

          <a
            className="icon"
            href="javascript:void(0);"
            onClick={() => handleEdit(row)}
          >
            <Btn color="primary" size="sm">
              <i className="fa-solid fa-pencil me-1"></i>
              {"Edit"}
            </Btn>
          </a>
          <a
            className="icon"
            href={`javascript:void(0);`}
            onClick={() => handleViewFolder(row)}
          >
            <Btn color="transparent" size="sm" className=" mr-2">
              <i className="fa-solid fa-upload me-1"></i>
              {"Upload documents"}
            </Btn>
          </a>
        </td>
        // <UL className="action simple-list flex-row">
        //   <LI className="view">
        //     <a
        //       href={`javascript:void(0);`}
        //       onClick={() => handleViewFolder(row)}
        //       className="btn btn-primary d-flex justify-content-center align-items-center"
        //       style={{
        //         borderRadius: "50%",
        //         width: "40px",
        //         height: "40px",
        //         marginRight: 6,
        //       }}
        //     >
        //       <i className="icon-eye text-white" />
        //     </a>
        //   </LI>
        //   <LI className="edit">
        //     <a
        //       href="javascript:void(0);"
        //       onClick={() => handleEdit(row)}
        //       className="btn btn-light d-flex justify-content-center align-items-center"
        //       style={{ borderRadius: "50%", width: "40px", height: "40px" }}
        //     >
        //       <i className="icon-pencil-alt text-dark" />
        //     </a>
        //   </LI>
        //   {/* <LI className="delete">
        //     <a
        //       href={""}
        //       className="btn btn-danger d-flex justify-content-center align-items-center"
        //       style={{ borderRadius: "50%", width: "40px", height: "40px" }}
        //     >
        //       <i className="icon-trash text-white" />
        //     </a>
        //   </LI> */}
        // </UL>
      ),
    },
  ];

  const downloadFile = (fileData: any) => {
    if (fileData.mediaUrl) {
      handleFileDownload(`${baseUrl}${fileData.mediaUrl}`, fileData.fileName);
    } else {
      toast.error("File download failed: Media URL not available");
    }
  };

  const columnsFiles: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => `${row.fileName}`,
      style: {
        minWidth: "420px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "420px", // Set consistent width
      maxWidth: "420px",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Size" />,
      selector: (row) => `${row.size}`,
      style: {
        minWidth: "100px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "100px", // Set consistent width
      maxWidth: "100px",
      sortable: true,
      center: false,
    },
    {
      name: <CustomHeader title="Action" />,
      sortable: true,
      style: {
        minWidth: "100px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "100px", // Set consistent width
      maxWidth: "100px",
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="view">
            <a
              href={`javascript:void(0);`}
              onClick={() => downloadFile(row)}
              className="btn btn-primary d-flex justify-content-center align-items-center"
              style={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                marginRight: 6,
              }}
            >
              <i className="icon-download text-white" />
            </a>
          </LI>
          {/* <LI className="delete">
            <a
              href={""}
              className="btn btn-danger d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-trash text-white" />
            </a>
          </LI> */}
        </UL>
      ),
    },
  ];

  const handleBack = () => {
    setShowHide(false);
    setSelectedFolder({});
  };

  const getFile = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const fetchFiles = async () => {
    try {
      const fileResp = await DocumentAPi.getFiles({
        docFolderId: selectedFolder?.id as string,
        page: 1,
        pageSize: 10000,
      });

      if (
        fileResp.success &&
        Object.keys(fileResp.data).length > 0 &&
        fileResp.data.list.length > 0
      ) {
        setDocumentFiles(fileResp.data.list);
      } else {
        setDocumentFiles([]);
      }
    } catch (error: any) {
      toast.error("error: ", error);
    }
  };

  useEffect(() => {
    if (selectedFolder?.id) {
      fetchFiles();
    }
  }, [selectedFolder]);

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    const files: any = e.target.files;

    try {
      const file = files[0];

      if (bytesToMB(file.size) > 5)
        return toast.error("Can't upload file above 5 MB");

      const formData: any = new FormData();
      formData.append("file", file);
      formData.append("folder", "document");
      const response = await UploadAPi.uploadFile(formData, "upload-document");

      const data = {
        docFolderId: selectedFolder?.id,
        fileName: file.name,
        mediaType: file.type.toUpperCase(),
        mediaUrl: response.filePath,
        size: `${bytesToMB(file.size)} mb`,
      };

      const documentAddedResp = await DocumentAPi.createFile(data);
      if (documentAddedResp.success) {
        Swal.fire({
          title: "Success!",
          text: "Document Uploaded successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Document Uploaded successfully`
            )
          );

          if (selectedFolder?.id) {
            await fetchFiles();
          }

          if (inputRef.current) {
            inputRef.current.value = ""; // Reset the file input
          }
        });
      }
    } catch (error: any) {
      if (typeof error === "string") {
        toast.error(error);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsUploading(false);
    }
  };

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      // setSelectedQualificationAndCertification({}); // Clear selected Qualification And Certification data
    } else {
      // If opening modal, reset with selected Qualification And Certification data for edit, otherwise with empty data for add
      reset(initialState);
      setForm(initialState);
    }
  };

  const onSubmit = async () => {
    try {
      if (form.id == "") {
        const createObj = JSON.parse(JSON.stringify(form));
        delete createObj.id;
        createObj.folderName = createObj.name;
        delete createObj.name;
        const addedResponse = await DocumentAPi.addFolder({
          ...createObj,
        });

        if (addedResponse.success) {
          Swal.fire({
            title: "Success!",
            text: "Folder created successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Employees",
                `Folder created successfully`
              )
            );

            toggleModal();

            await fetchFolders();
          });
        }
      } else {
        const createObj = JSON.parse(JSON.stringify(form));
        createObj.folderName = createObj.name;
        delete createObj.name;
        const addedResponse = await DocumentAPi.updateFolder({
          ...createObj,
        });

        if (addedResponse.success) {
          Swal.fire({
            title: "Success!",
            text: "Folder updated successfully",
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData(
                "Employees",
                `Folder updated successfully`
              )
            );

            toggleModal();
            await fetchFolders();
          });
        }
      }
    } catch (error: any) {
      const errorObj = JSON.parse(error.message);
      const errorMessage =
        typeof errorObj === "object" ? errorObj.message : errorObj;
      toast.error(errorMessage || "Facing some issues.");
    }
  };

  const handleEdit = (data: any) => {
    const updated: any = {
      id: data.id,
      name: data.folderName,
      description: data.description,
      visibility: "SPECIFIC_EMPLOYEE",
      employeeId: [id],
    };

    reset(updated);
    setForm(updated);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Col xl={12}>
      <Row className="pt-0">
        <Col sm={12}>
          <Card>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ borderBottom: "5px solid #eee" }}
            >
              <CommonCardHeader
                headClass="pb-0 card-no-border"
                title="Documents"
                subTitle={[]}
                titleClass="mb-0"
              />
              <Button
                color="primary"
                className="btn-md m-4"
                onClick={() => toggleModal()}
              >
                <i className="fa fa-plus me-2" /> Add Folder
              </Button>
            </div>
            <CardBody >
              {!showHide && (
                <Card
                // style={{
                //   border: "1px solid #eee",
                //   borderRadius: "0.8rem",
                //   padding: "1.5rem",
                // }}
                >
                  <Row className="justify-content-end">
                    <Col md={5}>
                      <FilterComponent
                        onFilter={(e) => setFilterText(e.target.value)}
                        filterText={filterText}
                      />
                    </Col>
                  </Row>

                  <CardBody className="p-0">
                    <div className="table-responsive">
                      <DataTable
                        data={filteredItems}
                        // data={documentFolders}
                        columns={columns}
                        pagination
                        className="display"
                        conditionalRowStyles={[
                          {
                            when: (row) => true, // This applies to all rows
                            style: {
                              "&:hover": {
                                backgroundColor: "rgba(48, 142, 135, 0.2)",
                                cursor: "pointer",
                              },
                            },
                          },
                        ]}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}

              {showHide && (
                <Card
                // style={{
                //   border: "1px solid #eee",
                //   borderRadius: "0.8rem",
                //   padding: "1.5rem",
                // }}
                >
                  <div>
                    <CommonCardHeader
                      headClass="custom-header-card d-flex justify-content-between"
                      titleClass="card-title"
                      title={`Manage Document ${
                        selectedFolder?.folderName
                          ? "> " + selectedFolder.folderName
                          : ""
                      }`}
                      noBorder={true}
                      showBackBtn
                      handleBackBtn={() => handleBack()}
                    />
                  </div>

                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: "1.6rem",
                      }}
                    >
                      {isUploading && (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: "1",
                          }}
                        >
                          <Spinner size="lg" color="primary">
                            Loading...
                          </Spinner>
                        </div>
                      )}
                      <img
                        src={dynamicImage(`file-manager/noDocument.png`)}
                        width="180px"
                        height="180px"
                        alt="No Document Icon"
                        style={{ opacity: isUploading ? "0.1" : "1" }}
                      />
                      <Btn color="primary" onClick={getFile}>
                        Upload File
                      </Btn>
                      <div
                        style={{
                          height: "0px",
                          width: "0px",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          id="upfile"
                          type="file"
                          ref={inputRef}
                          onChange={(event) => onFileChange(event)}
                        />
                      </div>
                    </div>

                    <div className="table-responsive">
                      <DataTable
                        data={documentFiles}
                        columns={columnsFiles}
                        pagination
                        className="display"
                        conditionalRowStyles={[
                          {
                            when: (row) => true, // This applies to all rows
                            style: {
                              "&:hover": {
                                backgroundColor: "rgba(48, 142, 135, 0.2)",
                                cursor: "pointer",
                              },
                            },
                          },
                        ]}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}

              {/* Modal */}
              <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                  {form.id ? "Edit Folder" : "Add Folder"}
                </ModalHeader>
                <ModalBody>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-4">
                      <Col md={12} className="position-relative">
                        <Label>
                          {`Name Of Folder`}{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <input
                          type="text"
                          className={`form-control`}
                          placeholder="Enter folder name"
                          {...register("name", {
                            required: "Please enter folder name",
                          })}
                          value={form.name}
                          onChange={(e) => saveDataForm("name", e.target.value)}
                        />
                        {errors.name && (
                          <FormFeedback tooltip style={{ display: "block" }}>
                            {"Please enter folder name"}
                          </FormFeedback>
                        )}
                      </Col>

                      <Col md={12} className="position-relative mt-3">
                        <Label>{"Description"}</Label>
                        <textarea
                          className="form-control"
                          placeholder="Add description"
                          value={form.description || ""}
                          onChange={(e) =>
                            saveDataForm("description", e.target.value)
                          }
                        ></textarea>
                      </Col>
                    </Row>

                    <ModalFooter>
                      <Button color="primary" type="submit">
                        {form.id ? "Update & Save" : "Save & Update"}
                      </Button>
                      <Button color="secondary" onClick={toggleModal}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Form>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default Document;
