import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { LI, UL } from "../../../../AbstractElements";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import { useForm } from "react-hook-form";
import NurseryAPi from "../../../../services/NurseryApi";
import Swal from "sweetalert2";
import RotaAPi from "../../../../services/RotaApi";
import moment from "moment";
import Status from "../../../../Components/Common/Status";
import { statusColors } from "../../../../Utils/Constants";

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const RotaManagementList = (props: any) => {
  const navigate = useNavigate();
  const [rota, setRota] = useState<any[]>([]);
  const [filterText, setFilterText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const handleEditRota = (rota: any) => {
    props.setAction({ id: rota.id, action: "Edit" });
  };

  // Fetch Rota from the API
  const fetchRotas = async () => {
    try {
      const response = await RotaAPi.getRotas({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.data)) {
        setRota(response.data);
      } else {
        throw new Error(JSON.stringify("Failed to fetch Rota"));
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Rota",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRotas();
  }, [props.nurseryId]);

  const filteredItems = rota.filter((rota) =>
    rota.rotaType.toLowerCase().includes(filterText.toLowerCase())
  );

  const STATUS: any = {
    0: "Publish",
    1: "Draft",
  };

  const handleStatusChange = async (row: any) => {
    const currentStatus = row?.rotaStatus === 0 ? 1 : 0;
    const id = row.id;

    try {
      // Call the API to update the status, passing the boolean value
      await RotaAPi.addUpdate({
        status: currentStatus,
        nurseryId: props.nurseryId,
        rotaType: row.rotaType,
        startDate: row.startDate,
        endDate: row.endDate,
        id,
      });
      // Update the local state to reflect the status change
      await fetchRotas();
    } catch (error: any) {
      Swal.fire("Error", error.message || "Failed to update status", "error");
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: <CustomHeader title="Rota Type" />,
      selector: (row) => row.rotaType,
      sortable: true,
      style: {
        minWidth: "30%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "30%", // Set consistent width
      maxWidth: "30%",
    },
    {
      name: <CustomHeader title="Start Date" />,
      selector: (row) => moment(row.startDate).format("DD MMM YYYY"),
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
    },
    {
      name: <CustomHeader title="End Date" />,
      selector: (row) => moment(row.endDate).format("DD MMM YYYY"),
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
    },
    {
      name: <CustomHeader title="Status" />,
      selector: (row) => STATUS[row.rotaStatus],
      sortable: true,
      style: {
        minWidth: "20%", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "20%", // Set consistent width
      maxWidth: "20%",
      cell: (row) => {
        return (
          <Status
            position={STATUS[row.rotaStatus]}
            color={
              row?.rotaStatus === 1
                ? {
                    text: statusColors.suspended.text,
                    background: statusColors.suspended.background,
                  }
                : {
                    text: statusColors.active.text,
                    background: statusColors.active.background,
                  }
            }
            id={row.id}
            row={row}
            handleStatusChange={handleStatusChange}
          />
        );
      },
    },

    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="#"
              onClick={() => handleEditRota(row)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <div className="page-body mt-0">
      <Container fluid>
        <Row className="pt-0">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="flex-grow-1 text-left"
                  title="Rota Management"
                  subTitle={[]}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={() => props.setAction({ id: "", action: "Add" })}
                >
                  <i className="fa fa-plus me-2" /> Add Rota Management
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RotaManagementList;
