export interface StatusCellInterFace {
  position: any;
  id: number | string;
  row: any;
  color: { text: string; background: string };
  handleStatusChange: (row: any) => void;
}

const Status = ({
  position,
  id,
  color,
  row,
  handleStatusChange,
}: StatusCellInterFace) => {
  return (
    <>
      <div
        style={{
          backgroundColor: color.background,
          width: "100px",
          borderRadius: "8px",
          textAlign: "center",
        }}
        onClick={() => handleStatusChange(row)}
      >
        <span
          style={{
            padding: "4px 0px",
            color: color.text,
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "10px",
          }}
        >
          {position}
        </span>
      </div>
    </>
  );
};

export default Status;
