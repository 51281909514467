import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { H6, Image, LI, P, UL } from "../../../../AbstractElements";
import { dynamicImage, getUserActivityFormattedData } from "../../../../Utils";
import { settingIconData } from "../../../../Data/Layout/RightHeader";
import SvgIcon from "../../../../Utils/CommonComponents/CommonSvgIcons";
import Swal from "sweetalert2";
import UserActivityAPi from "../../../../services/UserActivityApi";

export default function UserWrap(props: any) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const toggle = () => {
    setOpen(!open);
  };
  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserActivityAPi.addUserActivity(
          getUserActivityFormattedData(
            "Logout",
            `${props?.userName} is logout successfully.`
          )
        );
        navigate(`${process.env.PUBLIC_URL}/login`, {
          replace: true,
        });
        props.logout();
      }
    });
  };

  const loadProfileUrl = () => {
    if (
      props?.user?.avatarUrl &&
      !props?.user?.avatarUrl.includes("cloudflare-ipfs.com")
    ) {
    } else {
      return dynamicImage(`default-profile.jpg`);
    }
  };

  return (
    <li className="profile-nav custom-dropdown">
      <div className="user-wrap" onClick={toggle}>
        <div className="user-img">
          <Image src={loadProfileUrl()} alt="user" />
        </div>
        <div className="user-content">
          <H6>{props.userName}</H6>
          <P className="mb-0">
            {props?.user?.role.replace(/_/g, " ").toUpperCase()}
            <i className="fa-solid fa-chevron-down" />
          </P>
        </div>
      </div>
      <div className={`custom-menu overflow-hidden ${open ? "show" : ""}`}>
        <UL className="profile-body simple-list">
          {settingIconData.map((item) => (
            <LI className="d-flex" key={item.id}>
              <SvgIcon className="svg-color" iconId={item.icon} />
              <Link
                className="ms-2"
                to={`${process.env.PUBLIC_URL}/${item.link}`}
              >
                {item.text}
              </Link>
            </LI>
          ))}
          <LI className="d-flex" onClick={logout}>
            <SvgIcon className="svg-color" iconId="Login" />
            <div className="ms-2">{"Log Out"}</div>
          </LI>
        </UL>
      </div>
    </li>
  );
}
