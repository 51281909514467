import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { LI, UL } from "../../../../AbstractElements";
import Swal from "sweetalert2";
import CommonCardHeader from "../../../../Utils/CommonComponents/CommonCardHeader";
import FilterComponent from "../../../../Components/Tables/DataTables/Common/FilterComponent";
import RoomOverviewApi from "../../../../services/RoomOverviewApi";
import { useForm } from "react-hook-form";

interface RoomOverview {
  id: string;
  roomName: string;
  description: string;
  color: string;
  capacity: number;
  staffRatio: number;
}

const CustomHeader = ({ title }: { title: string }) => (
  <span style={{ fontWeight: "bold", textAlign: "center", fontSize: "14px" }}>
    {title}
  </span>
);

const RoomOverview = (props: any) => {
  const navigate = useNavigate();
  const [roomOverview, setRoomOverview] = useState<RoomOverview[]>([]);
  const [filterText, setFilterText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const handleEditRoomOverview = (roomOverview: RoomOverview) => {
    setSelectedRoomOverview(roomOverview);
    toggleModal();
  };

  const [selectedRoomOverview, setSelectedRoomOverview] = useState<
    Partial<RoomOverview>
  >({}); // For editing Room Overview

  // Using React Hook Form for form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RoomOverview>();

  // Toggle modal to open/close
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
    // When modal closes, reset the form
    if (isModalOpen) {
      reset({}); // Reset form to empty state when closing
      setSelectedRoomOverview({}); // Clear selected room data
    } else {
      // If opening modal, reset with selected room data for edit, otherwise with empty data for add
      reset(selectedRoomOverview);
    }
  };

  // Handle form submission for both create and update
  const onSubmit = async (data: RoomOverview) => {
    try {
      if (selectedRoomOverview.id) {
        // Update Room Overview
        await RoomOverviewApi.updateRoomOverview({
          ...data,
          id: selectedRoomOverview.id,
          nurseryId: props.nurseryId,
        });
        Swal.fire({
          title: "Success!",
          text: "Room Overview updated successfully",
          icon: "success",
        });
      } else {
        // Create new Room Overview
        await RoomOverviewApi.createRoomOverview({
          ...data,
          nurseryId: props.nurseryId,
        });
        Swal.fire({
          title: "Success!",
          text: "Room Overview created successfully",
          icon: "success",
        });
      }
      // Reload the data after successful operation
      fetchRoomOverviews();
      toggleModal();
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  // Fetch Room Overviews from the API
  const fetchRoomOverviews = async () => {
    try {
      const response = await RoomOverviewApi.getRoomOverview({
        nurseryId: props.nurseryId,
      });
      if (response.success && Array.isArray(response.data.list)) {
        setRoomOverview(response.data.list);
      } else {
        throw new Error("Failed to fetch Room Overviews");
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Failed to fetch Room Overviews",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoomOverviews();
  }, [props.nurseryId]);

  const filteredItems = roomOverview.filter((roomOverview) =>
    roomOverview.roomName.toLowerCase().includes(filterText.toLowerCase())
  );

  const columns: TableColumn<RoomOverview>[] = [
    {
      name: <CustomHeader title="Name" />,
      selector: (row) => row.roomName,
      sortable: true,
      style: {
        minWidth: "200px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "flex-start", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "200px", // Set consistent width
      maxWidth: "200px",
    },
    // {
    //   name: <CustomHeader title="Description" />,
    //   selector: (row) => row.description,
    //   sortable: true,
    // },
    {
      name: <CustomHeader title="Color" />,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <div
          style={{
            backgroundColor: row.color,
            width: "20px",
            height: "20px",
            borderRadius: "50%",
          }}
        ></div>
      ),
      sortable: false,
    },
    {
      name: <CustomHeader title="Capacity" />,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      selector: (row) => row.capacity.toString(),
      sortable: true,
    },
    {
      name: <CustomHeader title="Staff Ratio" />,
      style: {
        minWidth: "150px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "150px", // Set consistent width
      maxWidth: "150px",
      center: true, // Center-aligns content in both header and cells
      selector: (row) => row.staffRatio.toString(),
      sortable: true,
    },
    {
      name: <CustomHeader title="Action" />,
      style: {
        minWidth: "120px", // Set a minimum width
        textAlign: "center", // Center the content
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
      },
      minWidth: "120px", // Set consistent width
      maxWidth: "120px",
      center: true, // Center-aligns content in both header and cells
      cell: (row) => (
        <UL className="action simple-list flex-row">
          <LI className="edit">
            <a
              href="#"
              onClick={() => handleEditRoomOverview(row)}
              className="btn btn-light d-flex justify-content-center align-items-center"
              style={{ borderRadius: "50%", width: "40px", height: "40px" }}
            >
              <i className="icon-pencil-alt text-dark" />
            </a>
          </LI>
        </UL>
      ),
    },
  ];

  return (
    <div className="page-body mt-0">
      <Container fluid>
        <Row className="pt-0">
          <Col sm={12}>
            <Card>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderBottom: "5px solid #eee" }}
              >
                <CommonCardHeader
                  headClass="flex-grow-1 text-left"
                  title="Overview"
                  subTitle={[]}
                  titleClass="mb-0"
                />
                <Button
                  color="primary"
                  className="btn-md m-4"
                  onClick={toggleModal}
                >
                  <i className="fa fa-plus me-2" /> Add Room
                </Button>
              </div>
              <CardBody>
                <FilterComponent
                  onFilter={(e) => setFilterText(e.target.value)}
                  filterText={filterText}
                />
                <div className="table-responsive">
                  <DataTable
                    data={filteredItems}
                    columns={columns}
                    pagination
                    className="display"
                    conditionalRowStyles={[
                      {
                        when: (row) => true,
                        style: {
                          "&:hover": {
                            backgroundColor: "rgba(48, 142, 135, 0.2)",
                            cursor: "pointer",
                          },
                        },
                      },
                    ]}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Modal for Adding Room Overview */}
        <Modal isOpen={isModalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            {selectedRoomOverview.id ? "Edit Room Overview" : "Add New Room"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="name">
                  Room Name <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.roomName ? "is-invalid" : ""
                  }`}
                  id="roomName"
                  {...register("roomName", {
                    required: "Room name is required",
                  })}
                  defaultValue={selectedRoomOverview.roomName}
                />
                {errors.roomName && (
                  <FormFeedback>{errors.roomName.message}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="description">
                  Description <span className="text-danger">*</span>
                </Label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.description ? "is-invalid" : ""
                  }`}
                  id="description"
                  {...register("description", {
                    required: "Description is required",
                  })}
                  defaultValue={selectedRoomOverview.description}
                />
                {errors.description && (
                  <FormFeedback>{errors.description.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="color">
                  Color <span className="text-danger">*</span>
                </Label>
                <input
                  type="color"
                  className={`form-control ${errors.color ? "is-invalid" : ""}`}
                  id="color"
                  {...register("color", {
                    required: "Color is required",
                    validate: (value) =>
                      /^#[0-9A-Fa-f]{6}$/i.test(value) ||
                      "Please provide a valid color", // Regex validation
                  })}
                  defaultValue={selectedRoomOverview.color || "#ffffff"} // Default value if color is empty or undefined
                />
                {errors.color && (
                  <FormFeedback>{errors.color.message}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="capacity">
                  Capacity <span className="text-danger">*</span>
                </Label>
                <input
                  type="number"
                  className={`form-control ${
                    errors.capacity ? "is-invalid" : ""
                  }`}
                  id="capacity"
                  {...register("capacity", {
                    required: "Capacity is required",
                    min: { value: 1, message: "Capacity must be at least 1" },
                  })}
                  defaultValue={selectedRoomOverview.capacity}
                />
                {errors.capacity && (
                  <FormFeedback>{errors.capacity.message}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="staffRatio">
                  Staff Ratio <span className="text-danger">*</span>
                </Label>
                <input
                  type="number"
                  className={`form-control ${
                    errors.staffRatio ? "is-invalid" : ""
                  }`}
                  id="staffRatio"
                  {...register("staffRatio", {
                    required: "Staff ratio is required",
                    min: {
                      value: 1,
                      message: "Staff ratio must be at least 1",
                    },
                  })}
                  defaultValue={selectedRoomOverview.staffRatio}
                />
                {errors.staffRatio && (
                  <FormFeedback>{errors.staffRatio.message}</FormFeedback>
                )}
              </FormGroup>

              <ModalFooter>
                <Button color="primary" type="submit">
                  {selectedRoomOverview.id
                    ? "Update Room Overview"
                    : "Add Room Overview"}
                </Button>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default RoomOverview;
