import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn } from "../../../../../AbstractElements";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { countryCode } from "../../../../../Utils/CountryCode";
import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  commonKeysObject,
  getUserActivityFormattedData,
} from "../../../../../Utils";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const General = (props: any) => {
  const { id } = useParams();
  const initialState: any = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    gender: "",
    nationality: "United Kingdom",
    primaryAddress: "",
    country: "",
    city: "",
    postalCode: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactEmail: "",
    emergencyContactPhoneNo: "",
    emergencyContactRelation: "",
    joinDate: new Date(),
    employeeType: "",
    jobTitleId: "",
    monthlySalary: "",
    nurseryId: "",
    roleTitleId: "",
    rolePermissionId: "",
  };

  const [form, setForm] = useState<any>(initialState);

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const countryOptions = countryCode.map(({ country }) => ({
    label: country,
    value: country,
  }));

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await UserApi.getEmployeeById(id);
        if (response.success && Object.keys(response.data).length > 0) {
          const modifiedObj = commonKeysObject(response.data, form);

          setForm({
            ...modifiedObj,
            dob: new Date(modifiedObj.dob),
            joinDate: new Date(modifiedObj.joinDate),
          });

          reset({
            ...modifiedObj,
            dob: new Date(modifiedObj.dob),
            joinDate: new Date(modifiedObj.joinDate),
          });
        } else {
          setForm({ ...initialState });
        }
      } catch (error: any) {
        toast.error("error: ", error);
      }
    };
    fetchEmployees();
  }, [id, props.tabId]);

  const onEditSubmit = async () => {
    const createEmpObj = JSON.parse(JSON.stringify(form));

    try {
      const addedResponse = await UserApi.updateEmployee({
        ...createEmpObj,
        dob: form.dob.getTime(),
        joinDate: form.joinDate.getTime(),
      });

      if (addedResponse.success) {
        Swal.fire({
          title: "Success!",
          text: "Employee updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Employee updated successfully`
            )
          );
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onEditSubmit)}>
        <Col md={12} xl={12}>
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Personal Info"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={4}>
                  <FormGroup>
                    <Label>{"First Name"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      {...register("firstName", { required: true })}
                      value={form.firstName}
                      onChange={(e) =>
                        saveDataForm("firstName", e.target.value)
                      }
                    />
                    {errors.firstName && (
                      <span style={{ color: "red" }}>
                        {"First Name is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"Last Name"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      {...register("lastName", { required: true })}
                      value={form.lastName}
                      onChange={(e) => saveDataForm("lastName", e.target.value)}
                    />
                    {errors.lastName && (
                      <span style={{ color: "red" }}>
                        {"Last Name is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <Label>{"Date of Birth"}</Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="dob"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            className={`form-control flatpickr-input`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("dob", date);
                            }}
                            placeholderText="Select a date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />

                    {errors.dob && (
                      <span style={{ color: "red" }}>{"DOB is required"} </span>
                    )}
                  </InputGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <Label>{"Nationality"}</Label>
                  <Controller
                    name="nationality"
                    control={control}
                    rules={{
                      required: "Please select any one nationality",
                    }} // Validation rules
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="nationality"
                        className={``}
                        options={countryOptions}
                        value={countryOptions.find(
                          (option: any) => option.value === form.nationality
                        )} // Set current value
                        onChange={(selectedOption: any) => {
                          field.onChange(selectedOption?.value);
                          saveDataForm("nationality", selectedOption.value);
                        }} // Update the value using Controller's onChange
                      />
                    )}
                  />
                  {errors.nationality && (
                    <span style={{ color: "red" }}>
                      {"Nationality is required"}{" "}
                    </span>
                  )}
                </Col>

                <Col md={4} className="position-relative">
                  <Label>{"Email"}</Label>
                  <InputGroup>
                    <input
                      type="email"
                      className={`form-control`}
                      placeholder="Enter your email"
                      {...register("email", {
                        required: "Please enter your valid email",
                      })}
                      value={form.email}
                      onChange={(e) => saveDataForm("email", e.target.value)}
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-envelope text-primary" />
                    </InputGroupText>
                    {errors.email && (
                      <span style={{ color: "red" }}>
                        {"Email is required"}{" "}
                      </span>
                    )}
                  </InputGroup>
                </Col>
                <Col md={4} className="position-relative">
                  <Label>{"Mobile"}</Label>
                  <InputGroup>
                    <input
                      type="number"
                      className={`form-control`}
                      placeholder="Enter your phoneNo"
                      {...register("phoneNo", {
                        required: "Please enter your phone no",
                      })}
                      maxLength={11}
                      value={form.phoneNo}
                      onChange={(e) => saveDataForm("phoneNo", e.target.value)}
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-phone text-primary" />
                    </InputGroupText>
                    {errors.phoneNo && (
                      <span style={{ color: "red" }}>
                        {"Phone No is required"}{" "}
                      </span>
                    )}
                  </InputGroup>
                </Col>

                <Col md={4} className="position-relative mt-3">
                  <FormGroup>
                    <Label>{"Gender"}</Label>
                    <Controller
                      name="gender"
                      control={control}
                      rules={{
                        required: "Please select a gender",
                      }} // Validation rules
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="gender"
                          options={[
                            { value: "male", label: "Male" },
                            { value: "female", label: "Female" },
                            { value: "other", label: "Other" },
                          ]} // Gender options
                          value={
                            [
                              { value: "male", label: "Male" },
                              { value: "female", label: "Female" },
                              { value: "other", label: "Other" },
                            ].find((option) => option.value === form.gender) ||
                            null
                          } // Set current value, handle null
                          onChange={(selectedOption) => {
                            // Check if selectedOption is not null
                            if (selectedOption) {
                              field.onChange(selectedOption.value); // Update the field value
                              saveDataForm("gender", selectedOption.value); // Save the data
                            }
                          }} // Handle value change
                        />
                      )}
                    />

                    {errors.gender && (
                      <span style={{ color: "red" }}>
                        {"Gender is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-5">
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Address"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={8}>
                  <FormGroup>
                    <Label>{"Primary Address"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Primary Address"
                      {...register("primaryAddress", { required: false })}
                      value={form.primaryAddress}
                      onChange={(e) =>
                        saveDataForm("primaryAddress", e.target.value)
                      }
                    />
                    {errors.primaryAddress && (
                      <span style={{ color: "red" }}>
                        {"Primary Address is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"Country"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Country"
                      {...register("country", { required: false })}
                      value={form.country}
                      onChange={(e) => saveDataForm("country", e.target.value)}
                    />
                    {errors.country && (
                      <span style={{ color: "red" }}>
                        {"Country is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"City"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="City"
                      {...register("city", { required: false })}
                      value={form.city}
                      onChange={(e) => saveDataForm("city", e.target.value)}
                    />
                    {errors.city && (
                      <span style={{ color: "red" }}>
                        {"City is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"Post Code"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Post Code"
                      {...register("postalCode", { required: false })}
                      value={form.postalCode}
                      onChange={(e) =>
                        saveDataForm("postalCode", e.target.value)
                      }
                    />
                    {errors.postalCode && (
                      <span style={{ color: "red" }}>
                        {"Post Code is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12} xl={12} className="mt-5">
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Emergency Contact"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={4}>
                  <FormGroup>
                    <Label>{"First Name"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="First Name"
                      {...register("emergencyContactFirstName", {
                        required: true,
                      })}
                      value={form.emergencyContactFirstName}
                      onChange={(e) =>
                        saveDataForm(
                          "emergencyContactFirstName",
                          e.target.value
                        )
                      }
                    />
                    {errors.emergencyContactFirstName && (
                      <span style={{ color: "red" }}>
                        {"First Name is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label>{"Last Name"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Last Name"
                      {...register("emergencyContactLastName", {
                        required: true,
                      })}
                      value={form.emergencyContactLastName}
                      onChange={(e) =>
                        saveDataForm("emergencyContactLastName", e.target.value)
                      }
                    />
                    {errors.emergencyContactLastName && (
                      <span style={{ color: "red" }}>
                        {"Last Name is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <Label>{"Email"}</Label>
                  <InputGroup>
                    <input
                      type="email"
                      className={`form-control`}
                      placeholder="Enter your email"
                      {...register("emergencyContactEmail", {
                        required:
                          "Please enter your valid Emergency Contact Email",
                      })}
                      value={form.emergencyContactEmail}
                      onChange={(e) =>
                        saveDataForm("emergencyContactEmail", e.target.value)
                      }
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-envelope text-primary" />
                    </InputGroupText>
                    {errors.emergencyContactEmail && (
                      <span style={{ color: "red" }}>
                        {"Email is required"}{" "}
                      </span>
                    )}
                  </InputGroup>
                </Col>
                <Col md={4} className="position-relative">
                  <Label>{"Mobile"}</Label>
                  <InputGroup>
                    <input
                      type="number"
                      className={`form-control`}
                      placeholder="Enter your phoneNo"
                      {...register("emergencyContactPhoneNo", {
                        required: "Please enter your phone no",
                      })}
                      maxLength={11}
                      value={form.emergencyContactPhoneNo}
                      onChange={(e) =>
                        saveDataForm("emergencyContactPhoneNo", e.target.value)
                      }
                    />
                    <InputGroupText className="list-light-primary">
                      <i className="fa-solid fa-phone text-primary" />
                    </InputGroupText>
                    {errors.emergencyContactPhoneNo && (
                      <span style={{ color: "red" }}>
                        {"Phone No is required"}{" "}
                      </span>
                    )}
                  </InputGroup>
                </Col>

                <Col md={4} className="position-relative">
                  <FormGroup>
                    <Label>{"Relationship"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Emergency Contact Relation"
                      {...register("emergencyContactRelation", {
                        required: false,
                      })}
                      value={form.emergencyContactRelation}
                      onChange={(e) =>
                        saveDataForm("emergencyContactRelation", e.target.value)
                      }
                    />
                    {errors.emergencyContactRelation && (
                      <span style={{ color: "red" }}>
                        {"Relationship is required"}{" "}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit">
                {"Update"}
              </Btn>
            </CardFooter>
          </Card>
        </Col>
      </Form>
    </>
  );
};

export default General;
