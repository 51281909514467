import React from "react";
import { Card, CardBody } from "reactstrap";

const StaffHours: React.FC = () => {
  return (
    <Card>
      <CardBody>
        <h5>Coming Soon!</h5>
      </CardBody>
    </Card>
  );
};

export default StaffHours;
