import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Label } from "reactstrap";
import Swal from "sweetalert2";
import NurseryAPi from "../../../../../services/NurseryApi";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../../../Utils";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa";

const RolesPermissionForm = (props: any) => {
  const permissionsData = [
    {
      title: "Children",
      permissions: [
        "Access to child information",
        "View child's demographic and private information",
        "View and edit child healthcare information",
        "Child allergies and dietary considerations",
        "Move children between locations",
        "Sign children in and out",
        "Create children",
        "Manage linking of siblings",
        "View additional details about child contacts",
        "See and edit child contacts’ protected addresses",
      ],
    },
    {
      title: "Staff",
      permissions: [
        "Can access the app away from the setting",
        "Create staff",
        "Staff info",
        "Staff Leave",
        "Staff Hours",
      ],
    },
    {
      title: "Communication & Events",
      permissions: [
        "View nursery calendar",
        "Create calendar events for parents and staff",
        "View all news feed posts",
        "Send news feed posts to parents and staff",
        "Host Famly Live meetings via news feed",
        "View and write classroom messages to parents",
        "Send private messages to parents",
        "Start internal staff messages",
        "Send messages to employees in all settings",
        "Create and manage meal plans",
      ],
    },
    {
      title: "Safeguarding",
      permissions: [
        "Manage head counts",
        "Create head counts",
        "List head counts",
        "Medication forms",
        "Acknowledge medication forms",
        "Manage medication forms",
        "Safeguarding disclosures",
        "View safeguarding disclosures",
      ],
    },
    {
      title: "Learning",
      permissions: [
        "Manage observations",
        "View observations",
        "Review and send observations",
        "Manage activity library",
        "Manage Activity Plans",
        "Create, edit and delete child development curriculum",
      ],
    },
    {
      title: "Finances",
      permissions: [
        "Can set up and manage in-app payments",
        "Manage Financial and Occupancy Settings",
        "Manage plans for children",
        "View plans for children",
        "Manage purchases for children",
        "Override plan invoicing profile",
        "Manage invoices for children",
        "View invoices for children",
        "Update billing details for children",
        "Manage payments for bill payers",
        "Show Occupancy Reports",
        "Show Financial Reports",
      ],
    },
    {
      title: "Administration",
      permissions: [
        "Manage logins for staff",
        "Manage permissions for custom roles",
        "Manage logins for parents",
        "Create departments and rooms",
        "Edit opening hours, info, etc.",
        "Edit tags",
        "Manage enabling/disabling features",
        "Manage security features",
        "Manage reports of inappropriate content",
        "Manage bloom kidz subscription",
      ],
    },
  ];

  const initialData = () => {
    return permissionsData.reduce((acc: any, group: any) => {
      acc[group.title] = {
        all: false,
        isVisible: false,
        permissions: group.permissions.reduce(
          (permAcc: any, permission: any) => {
            permAcc[permission] = {
              all: false,
              actions: { edit: false, view: false, add: false, delete: false },
            };
            return permAcc;
          },
          {}
        ),
      };
      return acc;
    }, {});
  };

  // Initialize state
  const [isChanged, setIsChanged] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [error, setError] = useState({ title: "" });
  const [permissions, setPermissions] = useState(
    permissionsData.reduce((acc: any, group: any) => {
      acc[group.title] = {
        all: false,
        isVisible: false,
        permissions: group.permissions.reduce(
          (permAcc: any, permission: any) => {
            permAcc[permission] = {
              all: false,
              actions: { edit: false, view: false, add: false, delete: false },
            };
            return permAcc;
          },
          {}
        ),
      };
      return acc;
    }, {})
  );

  // Toggle visibility of a group
  const toggleGroupVisibility = (title: any) => {
    setPermissions((prev: any) => ({
      ...prev,
      [title]: {
        ...prev[title],
        isVisible: !prev[title].isVisible,
      },
    }));
  };

  // Handle "Select All" for the entire group
  const handleSelectAllGroup = (group: any) => {
    const allChecked = !permissions[group].all;
    setPermissions((prev: any) => ({
      ...prev,
      [group]: {
        ...prev[group],
        all: allChecked,
        permissions: Object.keys(prev[group].permissions).reduce(
          (permAcc: any, permission: any) => {
            permAcc[permission] = {
              all: allChecked,
              actions: {
                edit: allChecked,
                view: allChecked,
                add: allChecked,
                delete: allChecked,
              },
            };
            return permAcc;
          },
          {}
        ),
      },
    }));
  };

  // Handle "Select All" for a specific subtitle
  const handleSelectAllSubtitle = (group: any, permission: any) => {
    const allChecked = !permissions[group].permissions[permission].all;
    setPermissions((prev: any) => ({
      ...prev,
      [group]: {
        ...prev[group],
        permissions: {
          ...prev[group].permissions,
          [permission]: {
            all: allChecked,
            actions: {
              edit: allChecked,
              view: allChecked,
              add: allChecked,
              delete: allChecked,
            },
          },
        },
      },
    }));
  };

  // Handle individual action change
  const handleActionChange = (group: any, permission: any, action: any) => {
    setPermissions((prev: any) => ({
      ...prev,
      [group]: {
        ...prev[group],
        permissions: {
          ...prev[group].permissions,
          [permission]: {
            ...prev[group].permissions[permission],
            actions: {
              ...prev[group].permissions[permission].actions,
              [action]: !prev[group].permissions[permission].actions[action],
            },
            // Update "Select All" for subtitle based on actions
            all: Object.values({
              ...prev[group].permissions[permission].actions,
              [action]: !prev[group].permissions[permission].actions[action],
            }).every((val) => val === true),
          },
        },
      },
    }));
  };

  const getRolesPermissions = async () => {
    try {
      const response = await NurseryAPi.getRolesPermissions({
        nurseryId: props.nurseryId,
      });

      if (response.success && Array.isArray(response.data)) {
        const filteredData = response?.data.filter(
          (row: any) => row.id === props.id
        );
        if (filteredData.length > 0) {
          const formData = {
            id: filteredData?.[0]?.id,
            title: filteredData?.[0]?.title,
            permissions: JSON.parse(filteredData?.[0]?.permissions),
          };

          setId(formData.id);
          setTitle(formData.title);
          setPermissions(formData.permissions);
        }
      } else {
        setTitle("");
        setPermissions(initialData());
      }
    } catch (error: any) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    props.id != "" && getRolesPermissions();
  }, [props.id]);

  useEffect(() => {
    if (isChanged === "") {
      setIsChanged(props.nurseryId);
    } else if (isChanged !== props.nurseryId) {
      props.setAction({ id: "", action: "list" });
    }
  }, [props.nurseryId]);

  // Save handler
  const handleSave = async () => {
    if (title === "") {
      setError({ title: "Title is required" });
    } else {
      setError({ title: "" });

      try {
        // Format the data before sending it to the API
        let formattedData: any = {};
        formattedData.permissions = `${JSON.stringify(permissions)}`;
        formattedData["id"] = id;
        formattedData["title"] = title;
        formattedData["nurseryId"] = props.nurseryId;
        const response = await NurseryAPi.addRolesPermissions(formattedData);
        Swal.fire({
          title: "Success!",
          text: `${response.message}`,
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData("Settings", `${response.message}`)
          );
          props.setAction({ id: "", action: "list" });
        });
      } catch (error: any) {
        // Display an error message if something goes wrong
        Swal.fire({
          title: "Error!",
          text: error.message || "Something went wrong",
          icon: "error",
        });
      }
    }
  };

  return (
    <Card className="mb-0">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ borderBottom: "5px solid #eee" }}
      >
        <CommonCardHeader
          headClass="pb-0 card-no-border"
          title={`Add Roles & Permission`}
          titleClass="mb-3"
        />
        <Button
          color="dark"
          className="btn-md m-4 d-flex align-items-center"
          onClick={() => props.setAction({ id: "", action: "list" })}
        >
          <i
            className="iconly-Arrow-Left icli"
            style={{ marginRight: "0.5rem" }}
          ></i>{" "}
          Back
        </Button>
      </div>
      <CardBody>
        <div className="pb-3">
          <Label for="title">
            Title <span className="text-danger">*</span>
          </Label>
          <input
            type="text"
            className={`form-control`}
            placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {error.title && <span style={{ color: "red" }}>{error.title}</span>}
        </div>
        <div>
          <h1 style={styles.heading}>Permissions</h1>
          {permissionsData.map((group) => (
            <div key={group.title} style={styles.groupCard} onClick={() => toggleGroupVisibility(group.title)}>
              {/* Group Header */}
              <div style={styles.groupHeader}>
                <div
                  style={styles.groupTitle}
                >
                  <span style={styles.toggleIcon}>
                    {permissions[group.title].isVisible ?  <FaChevronDown /> : <FaChevronRight />}
                  </span>
                  <span style={{marginLeft:"10px"}}>{group.title}</span>
                </div>
                <label style={styles.checkboxLabel}  
                onClick={(e) => e.stopPropagation()} // Prevent accordion toggle when checkbox is clicked
                >
                  <input
                    type="checkbox"
                    checked={permissions[group.title].all}
                    onChange={(e) => {
                      handleSelectAllGroup(group.title);
                    }}
                  />
                </label>
              </div>

              {/* Group Content */}
              {permissions[group.title].isVisible && (
                <div style={styles.groupContent}>
                  {group.permissions.map((permission) => (
                    <div key={permission} style={styles.permissionRow}>
                      {/* Subtitle and Select All */}
                      <div style={styles.subtitleRow}>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleSelectAllSubtitle(group.title, permission)
                          }
                        >
                          {permission}
                        </span>
                        <label key={group.title} style={styles.checkboxLabel1}>
                          <input
                            type="checkbox"
                            checked={
                              permissions[group.title].permissions[permission]
                                .all
                            }
                            onChange={() =>
                              handleSelectAllSubtitle(group.title, permission)
                            }
                          />
                        </label>
                      </div>

                      {/* Action Checkboxes */}
                      <div style={styles.actionsRow}>
                        {["edit", "view", "add", "delete"].map((action) => (
                          <label key={action} style={styles.actionLabel}>
                            {action.charAt(0).toUpperCase() + action.slice(1)}
                            <input
                              type="checkbox"
                              checked={
                                permissions[group.title].permissions[permission]
                                  .actions[action]
                              }
                              onChange={() =>
                                handleActionChange(
                                  group.title,
                                  permission,
                                  action
                                )
                              }
                            />
                          </label>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="d-flex mt-4 justify-content-end">
          <button className="btn btn-primary" style={{ marginRight: "0.5rem" }} onClick={() => handleSave()}>
            Save & Update
          </button>
          <Button
            color="dark"
            className="btn-md m-0 d-flex align-items-center"
            onClick={() => props.setAction({ id: "", action: "list" })}
          >
            Cancel
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

const styles: any = {
  heading: {
    fontSize: "18px",
    marginBottom: "20px",
    color: "#333",
  },
  groupCard: {
    backgroundColor: "#fff",
    marginBottom: "15px",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s",
  },
  groupHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    padding: "10px 10px",
    backgroundColor: "#ffffff",
    color: "#000",
    borderBottom: "1px solid #ddd",
  },
  groupTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "600",
    color: "#308e87"
  },
  toggleIcon: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  groupContent: {
    padding: "10px 20px",
    backgroundColor: "#ffffff",
  },
  permissionRow: {
    marginBottom: "15px",
    borderBottom: "3px solid #ddd",
    paddingBottom: "10px",
  },
  subtitleRow: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "10px", 
    marginLeft: "20px",
  },
  actionsRow: {
    display: "flex",
    gap: "8px",
    flexDirection: "column",
    marginLeft: "20px",
  },
  actionLabel: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#333",
  },
  checkboxLabel: {
    fontSize: "14px",
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  checkboxLabel1: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  saveButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "background-color 0.3s",
  },
  saveButtonHover: {
    backgroundColor: "#218838",
  },
};

export default RolesPermissionForm;
