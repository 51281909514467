import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Form, Nav, NavItem, Row } from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { MyProfileTitle, statusColors } from "../../../../../Utils/Constants";
import {
  Btn,
  H3,
  H4,
  H5,
  Image,
  P,
  Ribbon,
} from "../../../../../AbstractElements";
import {
  bytesToMB,
  commonKeysObject,
  dynamicImage,
  fetchImage,
  formatString,
  getUserActivityFormattedData,
} from "../../../../../Utils";
import { viewTaskNav } from "../../../../../Data/Applications/Tasks";
import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import moment from "moment";
import { camelCase } from "lodash";
import { toast } from "react-toastify";
import UploadAPi from "../../../../../services/UploadApi";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import Swal from "sweetalert2";
import { baseUrl } from "../../../../../Utils/Constants/config";
import {
  Briefcase,
  CheckCircle,
  DollarSign,
  FilePlus,
  File,
  Calendar,
  Award,
  Clock,
} from "react-feather";

export default function Profile(props: any) {
  const sections = [
    {
      id: 1,
      title: "General",
      icon: <FilePlus />,
    },
    {
      id: 2,
      title: "Employment", // Corrected spelling
      icon: <Briefcase />,
    },
    {
      id: 3,
      title: "Qualification",
      icon: <Award />, // Changed icon for better representation (you can revert to <DollarSign /> if needed)
    },
    {
      id: 4,
      title: "Documents",
      icon: <File />,
    },
    {
      id: 5,
      title: "Leave",
      icon: <Clock />, // Added Leave tab with a relevant icon
    },
    {
      id: 6,
      title: "Shifts",
      icon: <Calendar />,
    },
  ];

  const { id } = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialState: any = {
    id: "",
    firstName: "",
    lastName: "",
    role: "",
    phoneNo: "",
    email: "",
    nursery: "",
    employeeStatus: "",
    avatarUrl: "",
    employeeRooms: [],
  };

  const toTitleCase = (str: any) => {
    return str
      .toLowerCase() // Convert everything to lowercase
      .split(/[-\s]/) // Split on hyphens or spaces
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join("-"); // Join back with the original delimiter
  };

  const [form, setForm] = useState<any>(initialState);

  const fetchEmployees = async () => {
    try {
      const response = await UserApi.getEmployeeById(id);
      if (response.success && Object.keys(response.data).length > 0) {
        const modifiedObj = commonKeysObject(response.data, form);
        let avatarUrlData = "";
        if (!!modifiedObj.avatarUrl) {
          avatarUrlData = await fetchImage(baseUrl + modifiedObj.avatarUrl);
        }
        setForm({
          ...modifiedObj,
          nursery: modifiedObj.nursery.name,
          avatarUrl: avatarUrlData,
          employeeRooms: modifiedObj.employeeRooms.map((row: any) => {
            return {
              room: `${toTitleCase(row.rooms[0].roomName)} - ${toTitleCase(
                row.assignedAs.replace("_", "-")
              )}`,
              color: row.rooms[0].color,
            };
          }),
        });
      } else {
        setForm({ ...initialState });
      }
    } catch (error: any) {
    } finally {
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [id]);

  const getFile = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // setIsUploading(true);
    const files: any = e.target.files;
    try {
      const file = files[0];
      if (bytesToMB(file.size) > 5)
        return toast.error("Can't upload file above 5 MB");
      const formData: any = new FormData();
      formData.append("file", file);
      formData.append("folder", "avatar");
      const response = await UploadAPi.uploadFile(formData, "upload-avatar");
      const documentAddedResp = await UserApi.updateAvatar({
        id: id,
        avatarUrl: response.filePath,
      });
      if (documentAddedResp.success) {
        Swal.fire({
          title: "Success!",
          text: "Profile Picture updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Profile Picture updated successfully`
            )
          );
          await fetchEmployees();
          if (inputRef.current) {
            inputRef.current.value = ""; // Reset the file input
          }
        });
      }
    } catch (error: any) {
      if (typeof error === "string") {
        toast.error(error);
      } else {
        toast.error(error.message);
      }
    } finally {
      // setIsUploading(false);
    }
  };

  return (
    <Col xl={3} className="box-col-4e">
      <Card>
        <CommonCardHeader titleClass="card-title" title={MyProfileTitle} />
        <CardBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row className="mb-2">
              <div className="profile-title">
                <div className="d-flex gap-3">
                  <div className="position-relative">
                    <Image
                      className="img-70 rounded-circle"
                      alt=""
                      src={
                        !!form.avatarUrl
                          ? form.avatarUrl
                          : dynamicImage(`default-profile.jpg`)
                      }
                    />
                    <div
                      className="icon-wrapper bg-primary"
                      style={{
                        padding: "5px",
                        borderRadius: "50px",
                        position: "absolute",
                        top: "50px",
                        right: "5px",
                      }}
                      onClick={getFile}
                    >
                      <i className="iconly-Edit icli text-white"></i>

                      <div
                        style={{
                          height: "0px",
                          width: "0px",
                          overflow: "hidden",
                        }}
                      >
                        <input
                          id="upfile"
                          type="file"
                          ref={inputRef}
                          onChange={(event) => onFileChange(event)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <H5 className="mb-1">{`${form?.firstName} ${form?.lastName}`}</H5>
                    <P
                      className="mb-0 font-weight-bold"
                      style={{ fontWeight: "bold" }}
                    >
                      {form.role}
                    </P>

                    <span
                      className="badge-light-primary mt-2"
                      style={{
                        padding: "0 1.6rem",
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        height: "29px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100px",
                        backgroundColor: `${
                          statusColors[camelCase(form?.employeeStatus)]
                            ?.background
                        }`,
                        color: `${
                          statusColors[camelCase(form?.employeeStatus)]?.text
                        }`,
                      }}
                    >
                      {form.employeeStatus}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #eee",
                  paddingBottom: "1rem",
                }}
              >
                <ul className="d-flex flex-column gap-1 align-items-start">
                  <li>
                    <strong>
                      {" "}
                      <i className="fa-solid fa-envelope text-primary" />
                    </strong>{" "}
                    {`${form?.email}`}
                  </li>
                  <li>
                    <strong>
                      {" "}
                      <i className="fa-solid fa-phone text-primary" />
                    </strong>{" "}
                    {`${form?.phoneNo}`}
                  </li>
                </ul>
              </div>
              <div
                className="md-sidebar mt-3"
                style={{
                  borderBottom: "1px solid #eee",
                  paddingBottom: "1rem",
                }}
              >
                <Btn
                  color="primary"
                  className="md-sidebar-toggle"
                  onClick={() => {}}
                >
                  {""}
                </Btn>
                <div
                  className={`md-sidebar-aside job-left-aside custom-scrollbar open`}
                >
                  <div className="email-left-aside">
                    <div
                      className="email-app-sidebar left-bookmark task-sidebar"
                      style={{
                        height: "100%",
                      }}
                    >
                      <Nav className="main-menu">
                        {sections.map((nav) => (
                          <NavItem key={nav.id}>
                            <a
                              style={{ padding: "10px" }}
                              href="javascript:void(0);"
                              className={
                                props.activeTab === nav.id
                                  ? "active badge-light-primary"
                                  : ""
                              }
                              onClick={() => props.activeToggle(nav.id)}
                            >
                              {/* Render the dynamic Font Awesome icon */}

                              <span
                                className="iconbg badge-light-primary p-2 fs-10 lh-0"
                                style={{
                                  borderRadius: "100px",
                                  lineHeight: "0px",
                                }}
                              >
                                {nav.icon}
                              </span>

                              <span className="title ms-2">{nav.title}</span>
                            </a>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderBottom: "1px solid #eee",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <ul className="d-flex flex-column gap-1 align-items-start">
                  <li>
                    <strong>Nursery:</strong> {`${form.nursery}`}
                  </li>
                  <li>
                    <strong>Classroom:</strong>
                    <div className="d-flex flex-column gap-2 align-items-start mt-1">
                      {form.employeeRooms.map((item: any) => {
                        // Function to calculate the luminance of a color
                        const getLuminance = (hex: string) => {
                          let r: number = 0;
                          let g: number = 0;
                          let b: number = 0;

                          // 3-digit hex code (e.g., #03f)
                          if (hex.length === 4) {
                            r = parseInt(hex[1] + hex[1], 16);
                            g = parseInt(hex[2] + hex[2], 16);
                            b = parseInt(hex[3] + hex[3], 16);
                          }
                          // 6-digit hex code (e.g., #ff0033)
                          else if (hex.length === 7) {
                            r = parseInt(hex[1] + hex[2], 16);
                            g = parseInt(hex[3] + hex[4], 16);
                            b = parseInt(hex[5] + hex[6], 16);
                          }
                          // Apply the luminance formula
                          r /= 255;
                          g /= 255;
                          b /= 255;
                          const a = [r, g, b].map(function (v) {
                            return v <= 0.03928
                              ? v / 12.92
                              : Math.pow((v + 0.055) / 1.055, 2.4);
                          });
                          const luminance =
                            0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
                          return luminance;
                        };

                        // Default to red if no color
                        const bgColor = item.color || "#FF0000"; // Fallback color (red)

                        // Get luminance and choose appropriate text color
                        const luminance = getLuminance(bgColor);
                        const textColor = luminance > 0.5 ? "black" : "white"; // Dark background => white text, light background => black text

                        return (
                          <span
                            style={{
                              backgroundColor: item.color,
                              display: "block",
                              padding: "3px 24px",
                              color: textColor,
                            }}
                          >
                            {item.room}
                          </span>
                        );
                      })}
                    </div>
                  </li>
                </ul>
              </div>

              <div
                style={{
                  paddingBottom: "0rem",
                  paddingTop: "1rem",
                }}
              >
                <div className="d-flex">
                  <Btn color="primary" outline>
                    Message Teacher
                  </Btn>
                </div>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
}
