import api from "./api"; // Axios instance with interceptors

export default class CommentApi {
  // Fetch comments for a specific post
  static async getComments(postId: string, params: any) {
    try {
      const response = await api.get(`/comment/${postId}`, {
        params,
      });
      console.log("Fetched Comments:", response.data); // Log fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Create a new comment
  static async createComment(data: { newsFeedId: string; comment: string; }) {
    console.log("WWW: ",data);
    try {
      const response = await api.post("/comment/create", data);
      console.log("Created Comment:", response.data); // Log created comment
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateComment(
    id: string,
    data: {
      postId?: string;
      content?: string;
      createdBy?: string;      
      nurseryId?: string;
      // updatedBy: string;
    }
  ) {
    try {
      const response = await api.patch(`/comment/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  // Delete a specific comment
  static async deleteComment(commentId: string) {
    try {
      const response = await api.delete(`/comment/${commentId}`);
      console.log("Deleted Comment:", response.data); // Log the response
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
