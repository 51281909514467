import { SidebarMenuItem, SidebarMenus } from "../../Types/Layout.type";
import SvgIcon from "../../Utils/CommonComponents/CommonSvgIcons";

const NotificationIcon = () => <i className="iconly-Notification icli"></i>;
export const roleWiseMenuList: SidebarMenus[] = [
  {
    SUPER_ADMIN: {
      title: "General",
      lanClass: "lan-1",
      items: [
        {
          title: "Dashboards",
          id: 1,
          icon: "Home-dashboard",
          badge: true,
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard`,
        },
        {
          icon: "Profile",
          id: 2,
          path: `${process.env.PUBLIC_URL}/dashboard/admin`,
          title: "Super Admins",
          type: "link",
        },
        {
          icon: "Profile",
          id: 3,
          path: `${process.env.PUBLIC_URL}/dashboard/users`,
          title: "Users",
          type: "link",
        },
        {
          icon: "Bookmark",
          id: 4,
          title: "Booking",
          type: "link",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/bookings`,
              title: "Demo",
              type: "link",
            },
          ],
        },
        {
          icon: "Calendar",
          id: 5,
          path: `${process.env.PUBLIC_URL}/dashboard/calendar`,
          title: "Calendar",
          type: "link",
        },
        {
          icon: "Shield",
          id: 6,
          path: `${process.env.PUBLIC_URL}/dashboard/plans`,
          title: "Plans",
          type: "link",
        },
        {
          icon: "Document",
          id: 7,
          title: "Reports",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/all-users`,
              title: "Users Report",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/revenue`,
              title: "Revenue Report",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/landing-page-visitors`,
              title: "Visitors Report",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/user-activity`,
              title: "User Activity",
              type: "link",
            },
          ],
        },
        {
          icon: "Folder",
          id: 8,
          path: `${process.env.PUBLIC_URL}/dashboard/eyfs`,
          title: "EYFS",
          type: "link",
        },
        {
          icon: "icon-clipboard",
          id: 9,
          skipSvg: true,
          title: "Help Center",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/help-center`,
              title: "Help Center",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/help-center/faq`,
              title: "FAQ's",
              type: "link",
            },
          ],
        },
        // {
        //   icon: "Setting",
        //   id: 10,
        //   path: `${process.env.PUBLIC_URL}/users`,
        //   title: "Settings",
        //   type: "link",
        // }
        {
          icon: "Password",
          id: 10,
          path: `${process.env.PUBLIC_URL}/dashboard/change-password`,
          title: "Change Password",
          type: "link",
        },
        {
          icon: "notification",
          id: 11,
          path: `${process.env.PUBLIC_URL}/dashboard/notofication-setting`,
          title: "Notification",
          type: "link",
        },
        {
          icon: "Login",
          id: 12,
          title: "Logout",
        },
      ],
    },
  },
  {
    ADMIN: {
      title: "General",
      lanClass: "lan-1",
      items: [
        {
          title: "Dashboard",
          id: 1,
          icon: "Home-dashboard",
          badge: true,
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard`,
        },
        {
          icon: "icon-world",
          skipSvg: true,
          id: 2,
          path: `${process.env.PUBLIC_URL}/dashboard/news-feed`,
          title: "Newsfeed",
          type: "link",
        },
        {
          icon: "iconly-User2 icli",
          id: 3,
          skipSvg: true,
          title: "Users",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/users/employees`,
              title: "Employees",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/users/parents`,
              title: "Parents",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/users/children`,
              title: "Children",
              type: "link",
            },
          ],
        },
        {
          icon: "Calendar",
          id: 4,
          title: "Attendance",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/attendance/children-attendance`,
              title: "Children",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/attendance/employee-attendance`,
              title: "Employee",
              type: "link",
            },
          ],
        },
        {
          icon: "icon-credit-card",
          skipSvg: true,
          id: 5,
          title: "Finance",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/finance/employee-payroll`,
              title: "Employee Payroll",
              type: "link",
            },
          ],
        },
        {
          icon: "Document",
          id: 6,
          title: "Documents",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/documents/all`,
              title: "All Documents",
              type: "link",
            },
          ],
        },
        {
          icon: "icon-timer",
          skipSvg: true,
          id: 7,
          title: "Calendar",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar`,
              title: "My Calendar",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/calendar/team-time-off`,
              title: "Team Time Off",
              type: "link",
            },
          ],
        },
        {
          icon: "Shield",
          id: 8,
          title: "Plans",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/plans/active-plans`,
              title: "Active Plans",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/plans/pricing-bands`,
              title: "Pricing Bands",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/plans/session-requests`,
              title: "Session Requests",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/plans/manage-users`,
              title: "Manage Users",
              type: "link",
            },
          ],
        },
        {
          icon: "Document",
          id: 9,
          title: "Reports",
          active: false,
          children: [
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/head-count`,
              title: "Headcount",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/employee-data`,
              title: "Employee Data",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/parent-data`,
              title: "Parent Data",
              type: "link",
            },
            {
              path: `${process.env.PUBLIC_URL}/dashboard/reports/children-data`,
              title: "Children Data",
              type: "link",
            },
          ],
        },
        {
          icon: "icon-clipboard",
          skipSvg: true,
          id: 10,
          path: `${process.env.PUBLIC_URL}/dashboard/announcements`,
          title: "Announcements",
          type: "link",
        },
        {
          icon: "Info-circle",
          id: 11,
          path: `${process.env.PUBLIC_URL}/users`,
          title: "Help Center",
          type: "link",
        },
        {
          icon: "Setting",
          id: 12,
          path: `${process.env.PUBLIC_URL}/dashboard/settings`,
          title: "Settings",
          type: "link",
        },
        {
          icon: "Login",
          id: 13,
          title: "Logout",
        },
      ],
    },
  },
  {
    EMPLOYEE: {
      title: "General",
      lanClass: "lan-1",
      items: [
        {
          title: "Dashboard",
          id: 1,
          icon: "Home-dashboard",
          badge: true,
          type: "link",
          path: `${process.env.PUBLIC_URL}/dashboard`,
        },
        {
          icon: "icon-world",
          skipSvg: true,
          id: 2,
          path: `${process.env.PUBLIC_URL}/dashboard/news-feed`,
          title: "Newsfeed",
          type: "link",
        },
        {
          icon: "Calendar",
          id: 3,
          path: `${process.env.PUBLIC_URL}/dashboard/attendance`,
          title: "Attendance",
          type: "link",
        },
        // {
        //   icon: "iconly-User2 icli",
        //   id: 3,
        //   skipSvg: true,
        //   title: "Users",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/users/employees`,
        //       title: "Employees",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/users/parents`,
        //       title: "Parents",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/users/children`,
        //       title: "Children",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "Calendar",
        //   id: 4,
        //   title: "Attendance",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/attendance/children-attendance`,
        //       title: "Children",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/attendance/employee-attendance`,
        //       title: "Employee",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "icon-credit-card",
        //   skipSvg: true,
        //   id: 5,
        //   title: "Finance",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/finance/employee-payroll`,
        //       title: "Employee Payroll",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "Document",
        //   id: 6,
        //   title: "Documents",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/documents/all`,
        //       title: "All Documents",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "icon-timer",
        //   skipSvg: true,
        //   id: 7,
        //   title: "Calendar",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/calendar/my-calendar`,
        //       title: "My Calendar",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/calendar/team-time-off`,
        //       title: "Team Time Off",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "Shield",
        //   id: 8,
        //   title: "Plans",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/plans/active-plans`,
        //       title: "Active Plans",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/plans/pricing-bands`,
        //       title: "Pricing Bands",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/plans/session-requests`,
        //       title: "Session Requests",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/plans/manage-users`,
        //       title: "Manage Users",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "Document",
        //   id: 9,
        //   title: "Reports",
        //   active: false,
        //   children: [
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/reports/head-count`,
        //       title: "Headcount",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/reports/employee-data`,
        //       title: "Employee Data",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/reports/parent-data`,
        //       title: "Parent Data",
        //       type: "link",
        //     },
        //     {
        //       path: `${process.env.PUBLIC_URL}/dashboard/reports/children-data`,
        //       title: "Children Data",
        //       type: "link",
        //     },
        //   ],
        // },
        // {
        //   icon: "icon-clipboard",
        //   skipSvg: true,
        //   id: 10,
        //   path: `${process.env.PUBLIC_URL}/dashboard/announcements`,
        //   title: "Announcements",
        //   type: "link",
        // },
        // {
        //   icon: "Info-circle",
        //   id: 11,
        //   path: `${process.env.PUBLIC_URL}/users`,
        //   title: "Help Center",
        //   type: "link",
        // },
        // {
        //   icon: "Setting",
        //   id: 12,
        //   path: `${process.env.PUBLIC_URL}/dashboard/settings`,
        //   title: "Settings",
        //   type: "link",
        // },
        {
          icon: "Login",
          id: 13,
          title: "Logout",
        },
      ],
    },
  },
];
