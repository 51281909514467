import { BrowserRouter, Routes } from "react-router-dom";
import CustomRoutes from "./CustomRoutes";

export default function Routers(props: any) {
  return (
    <BrowserRouter basename={"/"}>
      <CustomRoutes
        auth={props.auth}
        getUserInfo={props.getUserInfo}
        setUserIP={props.setUserIP}
        setClockIn={props.setClockIn}
      />
    </BrowserRouter>
  );
}
