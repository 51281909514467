import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from "reactstrap";
import { Btn, Image } from "../../AbstractElements";
import { dynamicImage } from "../../Utils";
import { navLinks } from "../../Utils/Constants";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div>
      <Navbar color="light" light expand="md" container>
        <NavbarBrand href="/">
          <Image
            className="light-logo img-fluid"
            src={dynamicImage(`landing/kidzbloom.png`)}
            height={70}
            width={153}
            alt="logo"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {navLinks?.map((nav: any) => {
              let link = "";
              if (nav?.id) link = `/#${nav.id}`;
              if (nav?.url) link = nav.url;

              return (
                <NavItem>
                  <NavLink href={link}>{nav.title}</NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <div
            onClick={() => {
              navigate(`${process.env.PUBLIC_URL}/login`, { replace: true });
              window.location.reload();
            }}
          >
            <Btn color="primary">Login</Btn>
          </div>
          <Link to={`${process.env.PUBLIC_URL}/request-demo`}>
            <Btn color="primary" outline>
              Request Demo
            </Btn>
          </Link>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
