import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import classnames from "classnames";
import SocialMedia from "./SocialMedia";
import Information from "./Information";
import OpeningHours from "./OpeningHours";
import NurseryAPi from "../../../../services/NurseryApi";
import UserActivityAPi from "../../../../services/UserActivityApi";
import { getUserActivityFormattedData } from "../../../../Utils";

const General = (props: any) => {
  const [activeGeneralTab, setActiveGeneralTab] = useState("1");

  const initialState = {
    id: "",
    name: "",
    description: "",
    address: "",
    phoneNo: "",
    email: "",
    contactPersonName: "",
    ofstedNo: "",
    vatNo: "",
    link: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    linkedinLink: "",
    youtubeLink: "",
  };

  const errorInitialState = {
    name: false,
    description: false,
    address: false,
    phoneNo: false,
    email: false,
    ofstedNo: false,
    facebookLink: false,
    instagramLink: false,
  };

  const [form, setForm] = useState({ ...initialState });
  const [error, setError] = useState({ ...errorInitialState });

  const saveDataForm = (name: string, value: any) => {
    setForm({ ...form, [name]: value });
  };

  const toggleGeneralTab = (tab: string) => {
    if (activeGeneralTab === "1" && tab === "2") {
      if (validateForm(activeGeneralTab)) {
        setActiveGeneralTab(tab);
      }
    } else {
      setActiveGeneralTab(tab);
    }
  };

  // Function to move to the next tab
  const goToNextTab = () => {
    if (validateForm(activeGeneralTab)) {
      const nextTab = (parseInt(activeGeneralTab) + 1).toString();
      setActiveGeneralTab(nextTab);
    }
  };

  function validatePhoneNumber(phoneNumber: any) {
    if (phoneNumber === "") return true;
    // Define the RegEx pattern for validation
    const phonePattern = /^[0-9]{10}$/; // Adjust pattern based on your requirements
    return phonePattern.test(phoneNumber);
  }

  const validateEmail = (email: string) => {
    if (email === "") return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = (tabNo: any) => {
    if (tabNo === "1") {
      const errorState = {
        name: form.name === "" ? true : false,
        description: form.description === "" ? true : false,
        address: form.address === "" ? true : false,
        phoneNo: !validatePhoneNumber(form.phoneNo) ? true : false,
        email: !validateEmail(form.email) ? true : false,
        ofstedNo: form.ofstedNo === "" ? true : false,
      };

      setError({ ...error, ...errorState });
      const allValuesFalse = Object.values(errorState).every(
        (value) => value === false
      );
      return allValuesFalse; // true if all values are false, otherwise false
    }

    if (tabNo === "2") {
      const errorState = {
        facebookLink: form.facebookLink === "" ? true : false,
        instagramLink: form.instagramLink === "" ? true : false,
      };
      setError({ ...error, ...errorState });
      const allValuesFalse = Object.values(errorState).every(
        (value) => value === false
      );
      return allValuesFalse; // true if all values are false, otherwise false
    }
  };

  // Function to move to the previous tab
  const goToPrevTab = () => {
    const prevTab = (parseInt(activeGeneralTab) - 1).toString();
    setActiveGeneralTab(prevTab);
  };

  const getGeneralInformation = async () => {
    try {
      const response = await NurseryAPi.getGeneralInformation({
        nurseryId: props.nurseryId,
      });

      if (response.success && Object.keys(response?.data ?? {})?.length > 0) {
        const filteredData = response?.data;
        if (Object.keys(filteredData)?.length > 0) {
          const formData = {
            id: filteredData.id,
            name: filteredData.name,
            description: filteredData.description,
            address: filteredData.address,
            phoneNo: filteredData.phoneNo,
            email: filteredData.email,
            contactPersonName: filteredData.contactPersonName,
            ofstedNo: filteredData.ofstedNo,
            vatNo: filteredData.vatNo,
            link: filteredData.link,
            facebookLink: filteredData.facebookLink,
            instagramLink: filteredData.instagramLink,
            twitterLink: filteredData.twitterLink,
            linkedinLink: filteredData.linkedinLink,
            youtubeLink: filteredData.youtubeLink,
          };

          setForm(formData);
          setError(errorInitialState);
        }
      } else {
        setForm(initialState);
        setError(errorInitialState);
      }
    } catch (error: any) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    getGeneralInformation();
  }, [props.nurseryId]);

  useEffect(() => {
    getGeneralInformation();
  }, []);

  // Function to handle final form submission on Opening Hours tab
  const handleFinalSubmit = async () => {
    try {
      // Simulate a successful form submission (dummy submit)
      if (
        form.name === "" ||
        form.description === "" ||
        form.address === "" ||
        form.phoneNo === "" ||
        form.email === "" ||
        form.ofstedNo === "" ||
        form.facebookLink === "" ||
        form.instagramLink === ""
      ) {
        validateForm(activeGeneralTab);
      } else {
        try {
          // Format the data before sending it to the API
          let formattedData = JSON.parse(JSON.stringify(form));
          formattedData.openingHours = `${JSON.stringify(
            formattedData.openingHours
          )}`;
          formattedData["nurseryId"] = props.nurseryId;
          const response = await NurseryAPi.addUpdateGeneralInformation(
            formattedData
          );
          validateForm(activeGeneralTab);
          Swal.fire({
            title: "Success!",
            text: `${response.message}`,
            icon: "success",
          }).then(async () => {
            await UserActivityAPi.addUserActivity(
              getUserActivityFormattedData("Settings", `${response.message}`)
            );
            await getGeneralInformation();

            setActiveGeneralTab("1");
          });
        } catch (error: any) {
          // Display an error message if something goes wrong
          Swal.fire({
            title: "Error!",
            text: error.message || "Something went wrong",
            icon: "error",
          });
        }
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong",
        icon: "error",
      });
    }
  };

  // const handleHoursSubmit = async () => {
  //   try {
  //     // Format the data before sending it to the API
  //     let formattedData: any = {};
  //     formattedData["id"] = form.id;
  //     formattedData["openingHours"] = `${JSON.stringify(form.openingHours)}`;
  //     formattedData["nurseryId"] = props.nurseryId;
  //     const response = await NurseryAPi.addUpdateGeneralInformation(
  //       formattedData
  //     );
  //     Swal.fire({
  //       title: "Success!",
  //       text: `Opening hours is updated successfully.`,
  //       icon: "success",
  //     }).then(async () => {
  //       await UserActivityAPi.addUserActivity(
  //         getUserActivityFormattedData(
  //           "Settings",
  //           `Opening hours is updated successfully.`
  //         )
  //       );
  //       await getGeneralInformation();

  //       setActiveGeneralTab("3");
  //     });
  //   } catch (error: any) {
  //     // Display an error message if something goes wrong
  //     Swal.fire({
  //       title: "Error!",
  //       text: error.message || "Something went wrong",
  //       icon: "error",
  //     });
  //   }
  // };

  return (
    <Container fluid>
      <Row className="pt-3">
        <Col sm={12}>
          <Card>
            <CardBody>
              {/* General Navigation Tabs */}
              <Nav tabs className="mb-4">
                {["1", "2", "3"].map((tab) => (
                  <NavItem key={tab}>
                    <NavLink
                      className={classnames({
                        active: activeGeneralTab === tab,
                        "bg-primary": activeGeneralTab === tab,
                        "text-white": activeGeneralTab === tab,
                        "text-dark": activeGeneralTab !== tab,
                      })}
                      onClick={() => toggleGeneralTab(tab)}
                      style={{
                        fontSize: "16px",
                        borderRadius: "5px",
                        padding: "12px 20px",
                        marginBottom: "5px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                    >
                      {tab === "1"
                        ? "Information"
                        : tab === "2"
                        ? "Social Media"
                        : ""}
                    </NavLink>
                  </NavItem>
                ))}
                {/* Closure Day Tab (Display Only, No Navigation) */}
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeGeneralTab === "4",
                      "bg-primary": activeGeneralTab === "4",
                      "text-white": activeGeneralTab === "4",
                      "text-dark": activeGeneralTab !== "4",
                    })}
                    onClick={() => toggleGeneralTab("4")}
                    style={{
                      fontSize: "16px",
                      borderRadius: "5px",
                      padding: "12px 20px",
                      marginBottom: "5px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                  >
                    Closure Day
                  </NavLink>
                </NavItem> */}
              </Nav>

              {/* Tab Content with Next/Previous and Submit Buttons */}
              <TabContent activeTab={activeGeneralTab}>
                <TabPane tabId="1">
                  <Information
                    saveDataForm={saveDataForm}
                    form={form}
                    error={error}
                  />
                  <div className="text-end mt-3">
                    <Button color="primary" onClick={goToNextTab}>
                      Next
                    </Button>
                  </div>
                </TabPane>

                <TabPane tabId="2">
                  <SocialMedia
                    saveDataForm={saveDataForm}
                    form={form}
                    error={error}
                  />
                  <div className="d-flex justify-content-between mt-3">
                    <Button color="secondary" onClick={goToPrevTab}>
                      Previous
                    </Button>
                    <Button color="success" onClick={handleFinalSubmit}>
                      Submit
                    </Button>
                  </div>
                </TabPane>

                {/* <TabPane tabId="3">
                  <OpeningHours saveDataForm={saveDataForm} form={form} />
                  <div className="d-flex justify-content-between mt-3">
                    <Button color="success" onClick={handleHoursSubmit}>
                      Submit
                    </Button>
                  </div>
                </TabPane> */}

                {/* Closure Day Tab (Display Only, No Navigation) */}
                {/* <TabPane tabId="4">
                  <ClosureDay nurseryId={props.nurseryId} />
                </TabPane> */}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default General;
