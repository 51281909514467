import { forEach, isArray, isObject } from "lodash";
import store from "../ReduxToolkit/Store";
import { roleWiseMenuList } from "../Data/Layout/SidebarMenuList";
import { toast } from "react-toastify";
interface AnyObject {
  [key: string]: any;
}

//dynamic image
const images = require.context(`/public/assets/images`, true);

export const dynamicImage = (image: string | undefined) => {
  return images(`./${image}`);
};

//dynamic Number
export function dynamicNumber(totalLength: number) {
  return Array.from({ length: totalLength }, (_, index) => index + 1);
}

export const getFullName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
) => {
  return `${firstName || ""} ${lastName || ""}`;
};

export const flattenObject = (
  obj: any,
  parent = "",
  res: any = {},
  visited: any = new Set()
) => {
  if (visited.has(obj)) {
    // Avoid circular reference
    res[parent] = "[Circular]";
    return res;
  }

  visited.add(obj); // Add current object to visited

  forEach(obj, (value, key) => {
    const newKey = parent ? `${parent}.${key}` : key;

    if (isObject(value) && !isArray(value) && value !== null) {
      flattenObject(value, newKey, res, visited);
    } else if (isArray(value)) {
      forEach(value, (item, index) => {
        flattenObject(item, `${newKey}[${index}]`, res, visited);
      });
    } else {
      res[newKey] = value;
    }
  });

  visited.delete(obj); // Remove object from visited after processing
  return res;
};

export const handleErrors = (errors: any) => {
  return Object.fromEntries(Object.keys(errors).map((key) => [key, ""]));
};

export function removeKeys(obj: AnyObject, keysToRemove: string[]): AnyObject {
  // Create a new object to store the result
  const result: AnyObject = { ...obj };

  // Remove specified keys from the result object
  keysToRemove.forEach((key) => delete result[key]);

  return result;
}

export const delay = (seconds: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000); // Convert seconds to milliseconds
  });
};

export const getDataFromArray = (array: any, field: string, id: any) => {
  const filteredData = array.filter((row: any) => row.id === id);
  if (filteredData.length > 0) {
    return filteredData[0][field];
  }
  return "";
};

// Function to convert data to CSV format
export const convertToCSV = (data: any) => {
  const csvRows = [];

  // Get headers (keys of the object)
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(",")); // Push headers row

  // Loop through data and push each row
  for (const row of data) {
    const values = headers.map((header) => row[header]);
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

// Helper function to search recursively through items and children
const findOperationInItems = (
  items: any[],
  operationName: string,
  mainMenuTitle = ""
): any => {
  for (const item of items) {
    if (item.title === operationName) {
      // If found at the top level, set mainMenuTitle to item.title
      return { mainMenuTitle: mainMenuTitle || item.title, matchedItem: item };
    }
    if (item.children) {
      // If children exist, pass the current item title as the main menu
      const foundInChildren: any = findOperationInItems(
        item.children,
        operationName,
        item.title
      );
      if (foundInChildren) return foundInChildren; // Return if found in children
    }
  }
  return null;
};

export const getUserActivityFormattedData = (
  operationName: string,
  description: string,
  operationId: any = ""
) => {
  const state = store.getState();
  const userID = state.auth.user?.id;
  const userIP = state.auth.userIp;
  const role: any = state.auth.user?.role.toUpperCase() || "";
  const sidebarData: any = roleWiseMenuList?.filter((obj) => {
    return Object.keys(obj)[0] === (role ?? "SUPER_ADMIN");
  });

  if (operationName == "Login") {
    operationId = "0";
  }

  if (sidebarData.length > 0) {
    const roleItems = sidebarData[0][role ?? "SUPER_ADMIN"].items;
    const result = findOperationInItems(roleItems, operationName);

    if (result && result.matchedItem) {
      const { mainMenuTitle, matchedItem } = result;
      operationId = `${matchedItem.id}`;

      if (operationName !== "Login" && operationName !== "Logout") {
        // * Check if the item has children (i.e., sub-menu items)
        if (matchedItem.children) {
          // * Include both main menu and sub-menu in the description
          description = `Role: ${role}, Main Menu: ${mainMenuTitle}, Sub Menu: ${matchedItem.title}, Message: ${description}`;
        } else {
          // * Only include the main menu in the description for top-level items without children
          description = `Role: ${role}, Main Menu: ${mainMenuTitle}, Message: ${description}`;
        }
      }
    } else {
      operationId = "21";
    }
  } else {
    operationId = "21";
  }

  return {
    operationId: operationId,
    operationName: operationName,
    description: description,
    createdBy: userID,
    createdFrom: userIP,
  };
};

export const getLoggedInUserId = () => {
  const state = store.getState();
  return state.auth.user?.id;
};

export const fetchImage = async (url: any) => {
  let imageObjectUrl = "";
  try {
    // Fetch the image as a Blob
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }

    // Convert the response to a Blob
    const imageBlob = await response.blob();

    // Create a URL for the image Blob
    imageObjectUrl = URL.createObjectURL(imageBlob);
    return imageObjectUrl;
    // Set the image URL to displa
  } catch (error) {
    console.error("Error fetching image:", error);
    return imageObjectUrl;
  }
};

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const checkUserRole = (user: any | null) => {
  const obj = {
    isAdmin: false,
    isParent: false,
    isEmployee: false,
  };

  if (!user) return obj;
  if (user.role === "admin") obj["isAdmin"] = true;
  if (user.role === "parent") obj["isParent"] = true;
  if (user.role === "employee") obj["isEmployee"] = true;
  return obj;
};

export function commonKeysObject(objA: AnyObject, objB: AnyObject): AnyObject {
  const result: AnyObject = {};

  for (const key in objB) {
    if (
      Object.prototype.hasOwnProperty.call(objB, key) &&
      Object.prototype.hasOwnProperty.call(objA, key)
    ) {
      result[key] = objA[key];
    }
  }

  return result;
}

export const calculateJoinDateToCurrentDateDuration = (
  startDate: number,
  endDate: number
): string => {
  // Convert timestamps to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate years and months
  const startYear = start.getFullYear();
  const startMonth = start.getMonth();
  const endYear = end.getFullYear();
  const endMonth = end.getMonth();

  const years = endYear - startYear;
  const months =
    endMonth >= startMonth ? endMonth - startMonth : 12 + endMonth - startMonth;

  const adjustedYears = endMonth >= startMonth ? years : years - 1;
  const adjustedMonths = endMonth >= startMonth ? months : 12 + months;

  return `${adjustedYears} years ${adjustedMonths} months`;
};

export const formatString = (input: any) => {
  // Define mappings for known patterns
  const patterns = [
    { regex: /fulltime/i, replacement: "Full-Time" },
    { regex: /parttime/i, replacement: "Part-Time" },
    // Add more patterns here if needed
  ];

  // Apply all patterns to the input
  let formatted = input.toLowerCase();
  patterns.forEach(({ regex, replacement }) => {
    formatted = formatted.replace(regex, replacement);
  });

  return formatted;
};

export const bytesToMB = (bytes: number) => {
  const megabytes = bytes / (1024 * 1024);
  return parseFloat(megabytes.toFixed(2));
};

export const handleFileDownload = async (fileUrl: string, fileName: string) => {
  try {
    const response = await fetch(fileUrl);

    if (response.ok) {
      const blob = await response.blob();

      const link = document.createElement("a");

      const blobUrl = window.URL.createObjectURL(blob);
      link.href = blobUrl;

      link.download = fileName || "downloadedFile";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
    } else {
      toast.error("File download failed");
    }
  } catch (error) {
    toast.error("Error during file download");
  }
};

export const totalDuration = (timeStrings: string[]) => {
  let totalMinutes = 0;

  timeStrings.forEach((timeStr: string) => {
    if ((timeStr ?? "")?.trim() === "") {
      return;
    }

    const [hours, minutes] = timeStr
      .split(" ")
      .map((part: string) => part.replace(/[hm]/g, ""))
      .map(Number);
    totalMinutes += hours * 60 + minutes;
  });

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return `${isNaN(totalHours) ? 0 : totalHours}h ${
    isNaN(remainingMinutes) ? 0 : remainingMinutes
  }m`;
};

export const shiftsData = (data: any) => ({
  title: "Work Schedule",
  details: [
    {
      label: "Standard working hours/day",
      value: data?.workingHours,
      fullWidth: true,
    },
    {
      label: "Schedule type",
      value:
        data?.scheduleType === "DURATION_BASED"
          ? "Duration-based"
          : "Clock-based",
      fullWidth: true,
    },
    {
      label: "Total working hours/week",
      value: totalDuration([
        data?.mondayHour,
        data?.tuesdayHour,
        data?.wednesdayHour,
        data?.thursdayHour,
        data?.fridayHour,
        data?.saturdayHour,
        data?.sundayHour,
      ]),
      fullWidth: true,
    },
    {
      label: "Daily working hours",
      value: [
        { day: "Monday", time: data?.mondayHour || "" },
        { day: "Tuesday", time: data?.tuesdayHour || "" },
        { day: "Wednesday", time: data?.wednesdayHour || "" },
        { day: "Thursday", time: data?.thursdayHour || "" },
        { day: "Friday", time: data?.fridayHour || "" },
        { day: "Saturday", time: data?.saturdayHour || "" },
        { day: "Sunday", time: data?.sundayHour || "" },
      ],
      fullWidth: true,
      type: "time",
    },
    {
      label: "Leave Balance",
      value: [
        { day: "Annual Leave", time: data?.annualLeaveHour || "" },
        { day: "Sick Leave", time: data?.sickLeaveHour || "" },
        { day: "Emergency Leave", time: data?.emergencyLeaveHour || "" },
        { day: "Unpaid Leave", time: data?.unpaidLeaveHour || "" },
      ],
      fullWidth: true,
      type: "time",
    },
  ],
});

export function toCamelCase(str: any) {
  // Split based on consecutive uppercase letters
  const words = str
    .toUpperCase() // Ensure consistency in case
    .replace(
      /([A-Z])([A-Z]+)/g,
      (_: any, first: any, rest: any) => `${first}${rest.toLowerCase()} `
    ) // Split the string into words
    .trim()
    .split(" "); // Split into an array of words

  return words
    .map(
      (word: any, index: any) =>
        index === 0
          ? word.toLowerCase() // First word is all lowercase
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize subsequent words
    )
    .join(""); // Combine into camel case
}

export const isTimeFormatValid = (input: string): boolean => {
  const timeFormatRegex = /^$|^([0-1]?[0-9]|2[0-3])h\s([0-5]?[0-9])m$/;
  return timeFormatRegex.test(input);
};

export const getLeaveFormattedData = (
  leaveData: any,
  isBalanceData: boolean = false
) => {
  const formattedData = {
    id: leaveData?.id || "",
    annualLeave: leaveData?.annualLeave || "",
    sickLeave: leaveData?.sickLeave || "",
    maternityLeave: leaveData?.maternityLeave || "",
    paternityLeave: leaveData?.paternityLeave || "",
    bereavementLeave: leaveData?.bereavementLeave || "",
    compassionateLeave: leaveData?.compassionateLeave || "",
    unPaidLeave: leaveData?.unPaidLeave || "",
    publicHolidayLeave: leaveData?.publicHolidayLeave || "",
    educationalLeave: leaveData?.educationalLeave || "",
    sabbaticalLeave: leaveData?.sabbaticalLeave || "",
    culturalLeave: leaveData?.culturalLeave || "",
    militaryLeave: leaveData?.militaryLeave || "",
    medicalLeave: leaveData?.medicalLeave || "",
    adoptionLeave: leaveData?.adoptionLeave || "",
    juryDutyLeave: leaveData?.juryDutyLeave || "",
    toilLeave: leaveData?.toilLeave || "",
  };
  if (isBalanceData) {
    Object.assign(formattedData, {
      annualLeaveBalance: leaveData?.annualLeaveBalance || "",
      sickLeaveBalance: leaveData?.sickLeaveBalance || "",
      maternityLeaveBalance: leaveData?.maternityLeaveBalance || "",
      paternityLeaveBalance: leaveData?.paternityLeaveBalance || "",
      bereavementLeaveBalance: leaveData?.bereavementLeaveBalance || "",
      compassionateLeaveBalance: leaveData?.compassionateLeaveBalance || "",
      unPaidLeaveBalance: leaveData?.unPaidLeaveBalance || "",
      publicHolidayLeaveBalance: leaveData?.publicHolidayLeaveBalance || "",
      educationalLeaveBalance: leaveData?.educationalLeaveBalance || "",
      sabbaticalLeaveBalance: leaveData?.sabbaticalLeaveBalance || "",
      culturalLeaveBalance: leaveData?.culturalLeaveBalance || "",
      militaryLeaveBalance: leaveData?.militaryLeaveBalance || "",
      medicalLeaveBalance: leaveData?.medicalLeaveBalance || "",
      adoptionLeaveBalance: leaveData?.adoptionLeaveBalance || "",
      juryDutyLeaveBalance: leaveData?.juryDutyLeaveBalance || "",
      toilLeaveBalance: leaveData?.toilLeaveBalance || "",
    });
  }
  return formattedData;
};

export const getLeaveFormattedDataInNumbers = (
  leaveData: any,
  isBalanceData: boolean = false
) => {
  const formattedData = {
    id: leaveData?.id || "",
    annualLeave: Number(leaveData?.annualLeave) || 0,
    sickLeave: Number(leaveData?.sickLeave) || 0,
    maternityLeave: Number(leaveData?.maternityLeave) || 0,
    paternityLeave: Number(leaveData?.paternityLeave) || 0,
    bereavementLeave: Number(leaveData?.bereavementLeave) || 0,
    compassionateLeave: Number(leaveData?.compassionateLeave) || 0,
    unPaidLeave: Number(leaveData?.unPaidLeave) || 0,
    publicHolidayLeave: Number(leaveData?.publicHolidayLeave) || 0,
    educationalLeave: Number(leaveData?.educationalLeave) || 0,
    sabbaticalLeave: Number(leaveData?.sabbaticalLeave) || 0,
    culturalLeave: Number(leaveData?.culturalLeave) || 0,
    militaryLeave: Number(leaveData?.militaryLeave) || 0,
    medicalLeave: Number(leaveData?.medicalLeave) || 0,
    adoptionLeave: Number(leaveData?.adoptionLeave) || 0,
    juryDutyLeave: Number(leaveData?.juryDutyLeave) || 0,
    toilLeave: Number(leaveData?.toilLeave) || 0,
  };
  if (isBalanceData) {
    Object.assign(formattedData, {
      annualLeaveBalance: Number(leaveData?.annualLeaveBalance) || 0,
      sickLeaveBalance: Number(leaveData?.sickLeaveBalance) || 0,
      maternityLeaveBalance: Number(leaveData?.maternityLeaveBalance) || 0,
      paternityLeaveBalance: Number(leaveData?.paternityLeaveBalance) || 0,
      bereavementLeaveBalance: Number(leaveData?.bereavementLeaveBalance) || 0,
      compassionateLeaveBalance:
        Number(leaveData?.compassionateLeaveBalance) || 0,
      unPaidLeaveBalance: Number(leaveData?.unPaidLeaveBalance) || 0,
      publicHolidayLeaveBalance:
        Number(leaveData?.publicHolidayLeaveBalance) || 0,
      educationalLeaveBalance: Number(leaveData?.educationalLeaveBalance) || 0,
      sabbaticalLeaveBalance: Number(leaveData?.sabbaticalLeaveBalance) || 0,
      culturalLeaveBalance: Number(leaveData?.culturalLeaveBalance) || 0,
      militaryLeaveBalance: Number(leaveData?.militaryLeaveBalance) || 0,
      medicalLeaveBalance: Number(leaveData?.medicalLeaveBalance) || 0,
      adoptionLeaveBalance: Number(leaveData?.adoptionLeaveBalance) || 0,
      juryDutyLeaveBalance: Number(leaveData?.juryDutyLeaveBalance) || 0,
      toilLeaveBalance: Number(leaveData?.toilLeaveBalance) || 0,
    });
  }
  return formattedData;
};

export const calculateTotalHours = ({
  startTime,
  endTime,
  addBreakTime = false,
  breakTime = 0, // Break time in minutes
}: any) => {
  if (!startTime || !endTime) {
    return { totalHours: 0, error: "Start time and end time are required." };
  }

  // Convert start and end times to Date objects
  const start: any = new Date(`1970-01-01T${startTime}:00`);
  const end: any = new Date(`1970-01-01T${endTime}:00`);

  // Handle cases where the end time is past midnight (e.g., night shift)
  if (end < start) {
    end.setDate(end.getDate() + 1);
  }

  // Calculate the total duration in milliseconds
  const durationMs = end - start;

  if (durationMs <= 0) {
    return { totalHours: 0, error: "Invalid time range." };
  }

  // Convert duration to hours
  let totalHours = durationMs / (1000 * 60 * 60); // Milliseconds to hours

  // Subtract break time if the flag is true
  if (addBreakTime) {
    const breakHours = breakTime / 60; // Break time in hours
    totalHours -= breakHours;
  }

  // Ensure total hours are not negative
  totalHours = Math.max(totalHours, 0);

  const formatted = totalHours.toFixed(2); // "2.01" (as a string)
  const numberValue = parseFloat(formatted); // 2.01 (as a number)

  return { totalHours: numberValue, error: null };
};

export const convertTimeToDateTime = (date: any, time: any) => {
  const existingDate = date; // Existing date object
  const [hours, minutes] = time.split(":").map(Number); // Parse the time string

  // Update the hours and minutes of the existing Date object
  return existingDate.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds
};

export const convertDateTimeToTime = (date: any) => {
  if (!date) {
    return date;
  }

  const existingDate = new Date(date); // Example date object

  // Extract hours and minutes
  const hours = existingDate.getHours();
  const minutes = existingDate.getMinutes();

  // Format the time as "HH:mm" (always 2 digits)
  const timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  return timeString;
};

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const showNames = (data: any) => {
  if (data.length <= 0) {
    return "";
  }
  const names = data.map((row: any) => row.leaveType);
  return names.join(", ");
};
