import api from "./api"; // Use the Axios instance with interceptor

export default class ClosureDayApi {
  static async getClosureDay(params: any) {
    try {
      const response = await api.get("/closureDay/all", {
        params,
      });
      console.log(response); // Log the fetched data
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async createClosureDay(data: {
    startDate: string;
    endDate: string;
    reason: string;
    typeOfClosureDay: string;
    nurseryId?: string;
  }) {
    try {
      const response = await api.post("/closureDay/", data);
      console.log(response.data); // Log the fetched data

      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async updateClosureDay(
    id: string,
    data: {
      startDate?: string;
      endDate?: string;
      reason?: string;
      typeOfClosureDay?: string;
      nurseryId?: string;
    }
  ) {
    try {
      const response = await api.patch(`/closureDay/${id}`, data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }

  static async deleteClosureDay(id: string) {
    try {
      const response = await api.delete(`/closureDay/${id}`);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error ||
        error.message ||
        "An unknown error occurred";
      throw new Error(errorMessage);
    }
  }
}
