import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import CommonCardHeader from "../../../../../Utils/CommonComponents/CommonCardHeader";
import { Btn, H4 } from "../../../../../AbstractElements";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { countryCode } from "../../../../../Utils/CountryCode";
import { useParams } from "react-router-dom";
import UserApi from "../../../../../services/UserApi";
import {
  calculateJoinDateToCurrentDateDuration,
  commonKeysObject,
  getLeaveFormattedData,
  getUserActivityFormattedData,
} from "../../../../../Utils";
import UserActivityAPi from "../../../../../services/UserActivityApi";
import Swal from "sweetalert2";
import CommonTouchspin from "../../../../../Components/Forms/FormWidgets/Touchspin/Common/CommonTouchspin";
import { defaultTouchspinData } from "../../../../../Data/Forms/FormWidgets";
import TimeInput from "../../../../../Components/Common/Time";
import { minutesInYear } from "date-fns/constants";
import moment from "moment";
import { toast } from "react-toastify";

const Job = (props: any) => {
  const { id } = useParams();
  const initialState: any = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    dob: new Date(new Date().setFullYear(new Date().getFullYear() - 10)),
    gender: "",
    nationality: "United Kingdom",
    primaryAddress: "",
    country: "",
    city: "",
    postalCode: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactEmail: "",
    emergencyContactPhoneNo: "",
    emergencyContactRelation: "",
    joinDate: new Date(),
    employeeType: "",
    jobTitle: "",
    jobTitleId: "",
    rolePermissionId: "",
    monthlySalary: "",
    nurseryId: "",
    roleTitleId: "",
    employeeId: "",
    employeeStatus: "",
    contractedHours: "",
    inductionDate: new Date(),
    leave: {
      annualLeave: "",
      sickLeave: "",
      maternityLeave: "",
      paternityLeave: "",
      bereavementLeave: "",
      compassionateLeave: "",
      unPaidLeave: "",
      publicHolidayLeave: "",
      educationalLeave: "",
      sabbaticalLeave: "",
      culturalLeave: "",
      militaryLeave: "",
      medicalLeave: "",
      adoptionLeave: "",
      juryDutyLeave: "",
      toilLeave: "",
    },
    leaveTitle: "",
    leaveStartDate: "",
    leaveEndDate: "",
  };

  const [form, setForm] = useState<any>(initialState);
  const [serviceYear, setServiceYear] = useState<any>("");
  const [contractedHours, setContractedHours] = useState({
    hour: 1,
    minutes: 1,
  });

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const countryOptions = countryCode.map(({ country }) => ({
    label: country,
    value: country,
  }));

  const saveDataForm = (name: string, value: any, type?: any) => {
    if (type === "leave") {
      setForm({
        ...form,
        leave: {
          ...form.leave,
          [name]: value,
        },
      });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await UserApi.getEmployeeById(id);
      if (response.success && Object.keys(response.data).length > 0) {
        const modifiedObj = commonKeysObject(response.data, form);
        setServiceYear(
          calculateJoinDateToCurrentDateDuration(
            new Date(modifiedObj.joinDate).getTime(),
            new Date().getTime()
          )
        );
        setForm({
          ...modifiedObj,
          dob: new Date(modifiedObj.dob),
          inductionDate: new Date(modifiedObj.inductionDate),
          joinDate: new Date(modifiedObj.joinDate),
          jobTitle: modifiedObj?.jobTitle?.jobTitle || "",
          leave: getLeaveFormattedData(response.data.leaveEmployees[0]),
          leaveTitle: response.data.leaveEmployees?.[0]?.leave?.title || "",
          leaveStartDate: moment(
            response.data.leaveEmployees?.[0]?.leave?.startDate
          ).format("DD MMM YYYY"),
          leaveEndDate: moment(
            response.data.leaveEmployees?.[0]?.leave?.endDate
          ).format("DD MMM YYYY"),
        });
        reset({
          ...modifiedObj,
          dob: new Date(modifiedObj.dob),
          joinDate: new Date(modifiedObj.joinDate),
          jobTitle: modifiedObj?.jobTitle?.jobTitle || "",
          leave: getLeaveFormattedData(response.data.leaveEmployees[0]),
        });

        setContractedHours(JSON.parse(response.data.contractedHours));
      } else {
        setForm({ ...initialState });
      }
    } catch (error: any) {
      toast.error("error: ", error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [id, props.tabId]);

  useEffect(() => {
    setServiceYear(
      calculateJoinDateToCurrentDateDuration(
        new Date(form.joinDate).getTime(),
        new Date().getTime()
      )
    );
  }, [form.joinDate]);

  const onEditSubmit = async () => {
    const createEmpObj = JSON.parse(JSON.stringify(form));
    delete createEmpObj.jobTitle;
    delete createEmpObj.leave.leave;
    delete createEmpObj.leaveEndDate;
    delete createEmpObj.leaveStartDate;
    delete createEmpObj.leaveTitle;
    try {
      const addedResponse = await UserApi.updateEmployee({
        ...createEmpObj,
        dob: form.dob.getTime(),
        joinDate: form.joinDate.getTime(),
        contractedHours: JSON.stringify(contractedHours),
        inductionDate: form.inductionDate.getTime(),
      });

      if (addedResponse.success) {
        Swal.fire({
          title: "Success!",
          text: "Employee updated successfully",
          icon: "success",
        }).then(async () => {
          await UserActivityAPi.addUserActivity(
            getUserActivityFormattedData(
              "Employees",
              `Employee updated successfully`
            )
          );

          await fetchEmployees();
        });
      }
    } catch (error: any) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
      });
    }
  };

  const setHoursData = (value: any, key: string, type: string) => {    
    setContractedHours({ ...contractedHours, [key]: value });    
  };

  return (
    <Col xl={12}>
      <Form onSubmit={handleSubmit(onEditSubmit)}>
        <Col md={12} xl={12}>
          <Card className="card-absolute">
            <CommonCardHeader
              headClass="bg-primary"
              titleClass="text-light"
              title={"Employment History"}
            />
            <CardBody>
              <Row className="mt-3">
                <Col md={4}>
                  <FormGroup>
                    <Label>{"Employee Id"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Employee Id"
                      value={form.employeeId}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                {/* Employee Status */}
                <Col md={4}>
                  <FormGroup>
                  
                    <Label>{"Employee Status"}</Label>
                    <Controller
                      name="employeeStatus"
                      control={control}
                      rules={{
                        required: "Please select a employee status",
                      }} // Validation rules
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="employeeStatus"
                          options={[
                            { label: "Active", value: "ACTIVE" },
                            { label: "On Boarding", value: "ONBOARDING" },
                            { label: "Probation", value: "PROBATION" },
                            { label: "On Leave", value: "ONLEAVE" },
                            { label: "Suspended", value: "SUSPENDED" },
                            { label: "Inactive", value: "INACTIVE" },
                          ]} // Gender options
                          value={
                            [
                              { label: "Active", value: "ACTIVE" },
                              { label: "On Boarding", value: "ONBOARDING" },
                              { label: "Probation", value: "PROBATION" },
                              { label: "On Leave", value: "ONLEAVE" },
                              { label: "Suspended", value: "SUSPENDED" },
                              { label: "Inactive", value: "INACTIVE" },
                            ].find(
                              (option) => option.value === form.employeeStatus
                            ) || null
                          } // Set current value, handle null
                          onChange={(selectedOption) => {
                            // Check if selectedOption is not null
                            if (selectedOption) {
                              field.onChange(selectedOption.value); // Update the field value
                              saveDataForm(
                                "employeeStatus",
                                selectedOption.value
                              ); // Save the data
                            }
                          }} // Handle value change
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                {/* Payroll */}
                {/* <Col md={4}>
                  <FormGroup>
                    <Label>{"Payroll"}</Label>
                    <div className="d-flex gap-3 justify-content-start">
                      <CommonTouchspin
                        color="primary"
                        outline={true}
                        btnClass="spin-border-primary"
                        value={payrollHours.hour}
                        setValue={(value) =>
                          setHoursData(value, "hour", "payroll")
                        }
                        maxLength={12}
                      />
                      <CommonTouchspin
                        color="primary"
                        outline={true}
                        btnClass="spin-border-primary"
                        value={payrollHours.minutes}
                        setValue={(value) =>
                          setHoursData(value, "minutes", "payroll")
                        }
                        maxLength={60}
                      />
                    </div>
                  </FormGroup>
                </Col> */}
                {/* Contracted Hours */}
                <Col md={4}>
                  <FormGroup>
                    <Label>{"Contracted Hours"}</Label>
                    <div className="d-flex gap-3 justify-content-start">
                      <CommonTouchspin
                        color="primary"
                        outline={true}
                        btnClass="spin-border-primary"
                        value={contractedHours.hour}
                        setValue={(value) =>
                          setHoursData(value, "hour", "contracted")
                        }
                        maxLength={12}
                      />
                      <CommonTouchspin
                        color="primary"
                        outline={true}
                        btnClass="spin-border-primary"
                        value={contractedHours.minutes}
                        setValue={(value) =>
                          setHoursData(value, "minutes", "contracted")
                        }
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md={4} className="position-relative">
                  <Label>{"Join Date / Job Date"}</Label>
                  <InputGroup className="flatpicker-calender">
                    <Controller
                      control={control}
                      name="joinDate"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            className={`form-control flatpickr-input`}
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date);
                              saveDataForm("joinDate", date);
                            }}
                            placeholderText="Select a date"
                            dateFormat="MMMM dd,yyyy"
                          />
                        );
                      }}
                    />

                    {errors.joinDate && (
                      <span style={{ color: "red" }}>
                        {"JOIN DATE is required"}{" "}
                      </span>
                    )}
                  </InputGroup>
                </Col>
                {/* Employee Type */}
                <Col md={4}>
                  <FormGroup>
                    <Label>{"Employee Type"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      disabled={true}
                      placeholder="Employee Type"
                      value={form.employeeType || ""}
                    />
                  </FormGroup>
                </Col>
                {/* Induction Date */}
                <Col md={4}>
                  <FormGroup>
                    <Label>{"Induction Date"}</Label>
                    <Controller
                      control={control}
                      name="inductionDate"
                      render={({ field }) => (
                        <DatePicker
                          className="form-control"
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            saveDataForm("inductionDate", date);
                          }}
                          placeholderText="Select Induction Date"
                          dateFormat="MMMM dd,yyyy"
                        />
                      )}
                    />
                  </FormGroup>
                </Col>
                {/* Job Title */}
                <Col md={4}>
                  <FormGroup>
                    <Label>{"Job Title"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Job Title"
                      disabled={true}
                      value={form.jobTitle || ""}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label>{"Service Year"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Service Year"
                      value={serviceYear}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12} xl={12} className="mt-5">
          <Card className="card-absolute">
            <CardBody>
              <CommonCardHeader
                headClass="bg-primary"
                titleClass="text-light"
                title={"Leave Detail"}
              />{" "}
              <Row className="mt-3">
                <Col md={6}>
                  <FormGroup>
                    <Label>Start Date</Label>
                    <Input
                      type="text"
                      disabled={true}
                      value={form?.leaveStartDate || ""}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>End Date</Label>
                    <Input
                      type="text"
                      disabled={true}
                      value={form?.leaveEndDate || ""}
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                {/* Annual Leave */}
                <Col md={6}>
                  <FormGroup>
                    <Label>Annual Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Annual Leave Days"
                      value={form?.leave?.annualLeave || ""}
                      onChange={(e) =>
                        saveDataForm("annualLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                {/* Sick Leave */}
                <Col md={6}>
                  <FormGroup>
                    <Label>Sick Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Sick Leave Days"
                      value={form?.leave?.sickLeave || ""}
                      onChange={(e) =>
                        saveDataForm("sickLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                {/* Maternity Leave */}
                <Col md={6}>
                  <FormGroup>
                    <Label>Maternity Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Maternity Leave Days"
                      value={form?.leave?.maternityLeave || ""}
                      onChange={(e) =>
                        saveDataForm("maternityLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                {/* Paternity Leave */}
                <Col md={6}>
                  <FormGroup>
                    <Label>Paternity Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Paternity Leave Days"
                      value={form?.leave?.paternityLeave || ""}
                      onChange={(e) =>
                        saveDataForm("paternityLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                {/* Bereavement Leave */}
                <Col md={6}>
                  <FormGroup>
                    <Label>Bereavement Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Bereavement Leave Days"
                      value={form?.leave?.bereavementLeave || ""}
                      onChange={(e) =>
                        saveDataForm(
                          "bereavementLeave",
                          e.target.value,
                          "leave"
                        )
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Compassionate Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Compassionate Leave Days"
                      value={form?.leave?.compassionateLeave || ""}
                      onChange={(e) =>
                        saveDataForm(
                          "compassionateLeave",
                          e.target.value,
                          "leave"
                        )
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Unpaid Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Unpaid Leave Days"
                      value={form?.leave?.unPaidLeave || ""}
                      onChange={(e) =>
                        saveDataForm("unPaidLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Public Holiday Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Public Holiday Leave Days"
                      value={form?.leave?.publicHolidayLeave || ""}
                      onChange={(e) =>
                        saveDataForm(
                          "publicHolidayLeave",
                          e.target.value,
                          "leave"
                        )
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label> Study or Educational Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Study Leave Days"
                      value={form?.leave?.educationalLeave || ""}
                      onChange={(e) =>
                        saveDataForm(
                          "educationalLeave",
                          e.target.value,
                          "leave"
                        )
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Sabbatical Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Sabbatical Leave Days"
                      value={form?.leave?.sabbaticalLeave || ""}
                      onChange={(e) =>
                        saveDataForm("sabbaticalLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Religious or Cultural Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Religious Leave Days"
                      value={form?.leave?.culturalLeave || ""}
                      onChange={(e) =>
                        saveDataForm("culturalLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Military Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Military Leave Days"
                      value={form?.leave?.militaryLeave || ""}
                      onChange={(e) =>
                        saveDataForm("militaryLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Family and Medical Leave (FMLA in the U.S.)</Label>
                    <Input
                      type="number"
                      placeholder="Enter Family and Medical Leave Days"
                      value={form?.leave?.medicalLeave || ""}
                      onChange={(e) =>
                        saveDataForm("medicalLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Adoption Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Adoption Leave Days"
                      value={form?.leave?.adoptionLeave || ""}
                      onChange={(e) =>
                        saveDataForm("adoptionLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Jury Duty Leave</Label>
                    <Input
                      type="number"
                      placeholder="Enter Jury Duty Leave Days"
                      value={form?.leave?.juryDutyLeave || ""}
                      onChange={(e) =>
                        saveDataForm("juryDutyLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label>Time Off in Lieu (TOIL)</Label>
                    <Input
                      type="number"
                      placeholder="Enter TOIL Days"
                      value={form?.leave?.toilLeave || ""}
                      onChange={(e) =>
                        saveDataForm("toilLeave", e.target.value, "leave")
                      }
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              {" "}
              <Btn color="primary" type="submit">
                {"Update"}
              </Btn>
            </CardFooter>
          </Card>
        </Col>
      </Form>
    </Col>
  );
};

export default Job;
